<template>
    <footer class="footer">
        <div class="container">
            <div class="footer__wrapper">
                <navigation-bottom :data="footerNavData"/>
            </div>
        </div>
    </footer>
</template>

<script setup>
import NavigationBottom from '@/components/NavigationBottom'
import {useGetter} from '@/composable/useGetter'

const {app} = useGetter(['app'])
const footerNavData = app.value.components.navigation.footer || []
</script>

<style lang="scss">
.footer {
    border-top: 1px solid $token-colors-black-5;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        @include padding-level(top, M);
        @include padding-level(bottom, M);

        @include breakpoint(v-tablet) {
            flex-wrap: wrap;
        }
    }
}
</style>
