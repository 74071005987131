<template>
    <div
        class="navigation-top"
        role="navigation"
        aria-label="Top navigation"
        :class="{'navigation-top--index': isIndex}"
    >
        <ul class="navigation-top__list">
            <li
                class="navigation-top__item"
                v-for="(item, index) in data"
                :key="index"
                :class="{'is-active': item.active}"
            >
                <z-link
                    :href="checkLink(item)"
                    :title="item.name"
                    size="m"
                    v-html="item.name"
                    :underlined="false"
                    theme="dark"
                />
            </li>
        </ul>
    </div>
</template>

<script setup>
import {useGetter} from '@/composable/useGetter'

defineProps({
    isIndex: {
        type: Boolean
    }
})

const { app } = useGetter(['app'])
const data = app.value.components.navigation.header

const checkLink = (item) => {
    return item.pageParams.redirect ? item.pageParams.redirect : item.link
}
</script>

<style lang="scss" src="./index.scss"></style>
