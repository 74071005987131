<template>
    <div
        class="blog"
        v-if="data">
        <div class="blog__filters">
            <z-select
                :placeholder="theme"
                is-filter
                v-model="params.category"
                :options="categories"
                @change="filterHandler" />
            <z-date-picker
                ref="datepicker"
                is-filter
                :placeholder="date"
                v-model="dateRange"
                range
                @change="dateHandler"
                :lang="lang" />
        </div>
        <div class="blog__content">
            <template v-if="items.length">
                <template
                    v-for="(item, index) in items"
                    :key="`event-${index}`">
                    <news-card
                        v-if="item.detailPageUrl"
                        class="blog__card"
                        tag="a"
                        :href="item.detailPageUrl"
                        :title="item.name"
                        :tags="item.category.value && [item.category.value]"
                        :image="getImage(item)"
                        :date="item.activeFrom"
                        :date-filter="dateFilter"
                        :previewText="item.previewText"
                    />
                </template>
                <z-preloader v-if="isLoading" />
                <z-pagination
                    v-if="adaptedNav"
                    :data="adaptedNav"
                    @change-page="paginationHandler" />
            </template>
            <z-not-found v-else />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useGetData } from '@/composable/useGetData'
import { useGetter } from '@/composable/useGetter'
import { useBlogHandlers } from './composable/useBlogHandlers.js'
import { getBlog } from '@/api/blog'
import { useBlogComputedData } from './composable/useBlogComputedData.js'
import { localize } from '@/utils/i18n'

const dateRange = ref([])

const params = ref({
    category: '',
    from: '',
    to: '',
    page: 1
})

const dateFilter = 'DD.MM.YYYY'
const lang = localize({
    ru: 'ru',
    en: 'en'
})
const theme = localize({
    ru: 'Категория',
    en: 'Category'
})

const date = localize({
    ru: 'Выберите дату',
    en: 'Choose a date'
})
const { app } = useGetter(['app'])
const appData = app.value.components?.blog
const isMore = ref(false)

const { isLoading, data, fetcher } = useGetData(getBlog, params, appData)

const { paginationHandler, filterHandler, dateHandler } = useBlogHandlers(
    params,
    fetcher,
    isMore,
    dateRange
)
const { categories, items, adaptedNav } = useBlogComputedData(data, isMore)

const getImage = (item) => {
    if (item.previewPicture.link) return item.previewPicture.link
    if (!item.category.xmlId) {
        return '/images/blog/other.jpg'
    }
    return `/images/blog/${item.category.xmlId.toLowerCase()}.jpg`
}

onMounted(() => {
    const searchParams = new URL(document.location).searchParams
    searchParams.get('category') && (params.value.category = searchParams.get('category'))
    searchParams.get('from') && (params.value.from = searchParams.get('from'))
    searchParams.get('to') && (params.value.to = searchParams.get('to'))
    searchParams.get('to') &&
        searchParams.get('from') &&
        (dateRange.value = [searchParams.get('from'), searchParams.get('to')])
})
</script>

<style lang="scss">
.blog {
    &__filters {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: $token-spacers-s;
        margin-bottom: $token-spacers-l;

        @include breakpoint(v-tablet) {
            margin-bottom: 36px;
            grid-template-columns: 1fr;
        }

        .z-select__container,
        .mx-input {
            border-bottom-color: $token-colors-button-bordered-default;
        }
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 48px;
        grid-row-gap: 48px;

        @include breakpoint(tablet) {
            grid-row-gap: 40px;
        }

        @include breakpoint(v-tablet) {
            grid-template-columns: 1fr;
        }
        @include breakpoint(mobile) {
            grid-row-gap: 32px;
        }
    }

    .z-pagination {
        grid-column: 1 / -1;
        @include margin-level(top, XS);
    }

    .z-preloader {
        grid-column: 1 / -1;
    }

    .z-not-found {
        grid-column: 1 / -1;
    }

    &__card {
        .news-card__title-wrapper {
            @include padding-level(top, XS);
            @include margin-level(bottom, 2XS);
        }

        .news-card__title {
            @include typo-level(M);
        }

        .news-card__description {
            @include typo-level(S);
            margin-bottom: 0;

            @include breakpoint(v-tablet) {
                margin-top: 4px;
            }
        }

        .news-card__image-wrapper {
            padding-top: 41.5%;

            @include breakpoint(v-tablet) {
                padding-top: 42%;
            }
            @include breakpoint(mobile) {
                padding-top: 48%;
            }
        }

        .news-card__tags {
            right: 14px;
            left: unset;
        }
    }
}
</style>
