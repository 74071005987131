import {queryString, clearEmptyParams} from '@/utils/filterUtils'

export const useMediaHandlers = (params, fetcher, isMore, dateRange, firstPage) => {
    const updateQueryParams = () => {
        queryString(clearEmptyParams({
            from: params.value.from,
            to: params.value.to,
            category: params.value.category
        }))
    }

    const filterHandler = () => {
        firstPage.value = true
        params.value.page = 1
        updateQueryParams()
        fetcher()
    }

    const dateHandler = () => {
        firstPage.value = true
        if (dateRange.value.length === 2 && dateRange.value[0]) {
            params.value.from = dateRange.value[0]
            params.value.to = dateRange.value[1]
        } else {
            params.value.from = ''
            params.value.to = ''
        }
        filterHandler()
    }

    const paginationHandler = (payload) => {
        if (payload.type === 'load-more') {
            isMore.value = true
        } else {
            firstPage.value = payload.page === 1
        }
        params.value.page = payload.page
        updateQueryParams()
        fetcher()
    }

    return {filterHandler, paginationHandler, dateHandler}
}
