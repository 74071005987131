<template>
    <div
        class="z-checkbox"
        :class="[
            `z-checkbox--size-${size}`,
            {
                'is-errored': error,
                'is-disabled': data.disabled
            }
        ]"
        v-if="data"
    >
        <label class="z-checkbox__label">
            <input
                class="z-checkbox__input"
                type="checkbox"
                v-model="value"
                @change="onChange"
                :value="data.id"
            >
            <div class="z-checkbox__box"></div>
            <div class="z-checkbox__text">
                <vue-raw :raw="data.text" />
            </div>
        </label>
        <span
            :class="['z-checkbox__error', errorClass]"
            v-if="error"
            v-html="error"
        ></span>
    </div>
</template>

<script setup>
// example data
// {
//     text: 'текст чекбокса',
//     value: '1',
//     disabled: false
// }
import { ref, computed, onMounted } from 'vue'
import { localize } from '@/utils/i18n'

const props = defineProps({
    required: {
        type: Boolean,
        default: false
    },
    size: {
        type: String,
        default: 'm',
        validator: prop => ['s', 'm', 'l'].includes(prop)
    },
    data: {
        type: Object
    },
    modelValue: {
        type: Boolean,
        default: undefined
    },
    errorClass: {
        type: String
    },
    customErrorText: {
        type: String
    }
})

// emits
const emit = defineEmits([
    'update:modelValue',
    'change',
    'input'
])

// static data
const errorText = props.customErrorText ? props.customErrorText : localize({
    ru: 'Поле обязательно для заполнения',
    en: 'Required field',
    cn: '填项目'
})

// refs
let error = ref('')
let localValue = ref(false)

//computed
let value = computed({
    get() {
        if (props.modelValue !== undefined) return props.modelValue // для работы без v-model
        return localValue.value
    },
    set(value) {
        localValue.value = value
        emit('update:modelValue', value)
    }
})

//events
const onChange = () => {
    validate()
    emit('change', value.value)
}

// methods
const validate = () => {
    if (props.required) {
        if (!value.value) {
            error.value = errorText
            return
        }
    }
    error.value = ''
}

onMounted(() => {
    // это нужно только чекбоксов без привязки v-model
    if (props.data.selected) localValue.value = true
})

defineExpose({
    validate,
    error
})
</script>

<style lang="scss">
$boxSpacerTop: 0em; // может менять и зависит от шрифта
$boxSpacerRight: 8px;
$checkbox: '.z-checkbox';

.z-checkbox {
    position: relative;

    &__label {
        display: flex;
        cursor: pointer;
        width: fit-content;

        &:hover {
            #{$checkbox}__box {
                border-color: var(--formBorderColorHover);
            }
        }
    }

    &__box {
        display: block;
        flex-shrink: 0;
        transition: background-color 0.2s ease-in, background-image 0.2s ease-in, box-shadow 0.2s ease-in;
        border: var(--formBorderWidth) solid var(--formBorderColorDefault);
        border-radius: var(--borderRadiusCheckbox);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-color: var(--formBgColor);
        background-size: 80%;
        margin-top: $boxSpacerTop;
        margin-right: $boxSpacerRight;
        transition: border 0.2s ease-in, background-image 0.2s ease-in;
    }

    &__input {
        display: none;

        &:checked {
            + #{$checkbox}__box {
                border-color: var(--formBorderColorFilled);
                background-image: svg-load('general/check.svg', fill=$checkColor);
            }
        }
    }

    &__text {
        font-size: 1em;
        line-height: 1.2;
    }

    &__error {
        font-size: var(--errorTextSize);
        color: var(--errorTextColor);
        position: relative;
    }

    &.is-errored {
        #{$checkbox}__box {
            border-color: var(--formBorderColorError);
        }
    }

    &.is-disabled {
        pointer-events: none;

        #{$checkbox}__box {
            border-color: var(--formBorderColorDisabled);
        }

        #{$checkbox}__text {
            color: var(--formTextColorDisabled);
        }
    }
}

.z-checkbox--size-s {
    font-size: var(--boxFontSizeS);

    #{$checkbox}__box {
        width: var(--boxSizeS);
        height: var(--boxSizeS);
    }
}

.z-checkbox--size-m {
    font-size: var(--boxFontSizeM);

    #{$checkbox}__box {
        width: var(--boxSizeM);
        height: var(--boxSizeM);
    }
}

.z-checkbox--size-l {
    font-size: var(--boxFontSizeL);

    #{$checkbox}__box {
        width: var(--boxSizeL);
        height: var(--boxSizeL);
    }
}

</style>
