<template>
    <li :class="[
        'z-list-item',
        { 'z-list-item--centered' : centered && iconName },
        { 'z-list-item--with-icon' : iconName }
    ]">
        <z-icon
            v-if="iconName"
            class="z-list-item__icon"
            :name="iconName"
            :width="iconSize"
            :height="iconSize"
        />
        <div class="z-list-item__content">
            <slot/>
        </div>
    </li>
</template>

<script setup>
import { useGetter } from '@/composable/useGetter'
import { computed, inject } from 'vue'

const props = defineProps({
    centered: {
        type: Boolean,
        default: false
    },
    icon: {
        type: String,
        default: ''
    }
})

// data
const { device } = useGetter(['device'])
const parentIconName = inject('iconName')
const tag = inject('tag')
const size = inject('size')

// computed
const iconName = computed(() => {
    let iconName = ''
    
    if (tag === 'ol') return iconName

    props.icon ? iconName = props.icon : iconName = parentIconName
    if (device.value === 'desktop' && size === 'l' && iconName) iconName = `${iconName}-big`

    return iconName
})
const iconSize = computed(() => {
    if (device.value === 'desktop' && size === 'l') return 48
    return 24
})
</script>

<style lang="scss">
.z-list-item {
    position: relative;
    font-size: 1em;
    display: flex;
    align-items: flex-start;
    line-height: 1.4;

    &--centered {
        align-items: center;
    }

    &__content {
        flex-grow: 1;
        flex-shrink: 1;
    }

    &__icon {
        margin-right: 16px;
    }

    &--with-icon {
        &:before {
            display: none !important;
        }
    }
}
</style>
