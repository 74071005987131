<template>
    <label class="z-switch" :for="id">
        <span class="z-switch__label z-switch__label--left" v-if="$slots.left">
            <slot name="left"/>
        </span>
        <span class="z-switch__wrapper">
            <input
                class="z-switch__input"
                type="checkbox"
                :name="id"
                :id="id"
                :checked="changed"
                :disabled="disabled"
                @change="onChange($event.target.checked, id)"
            />
            <span class="z-switch__slide"/>
        </span>
        <span class="z-switch__label z-switch__label--right" v-if="$slots.right">
            <slot name="right"/>
        </span>
    </label>
</template>

<script setup>
import {ref} from 'vue'

const props = defineProps({
    check: {
        type: Boolean,
        default: true
    },
    disabled: {
        type: Boolean,
        default: false
    },
    id: {
        type: String,
        required: true
    }
})

const changed = ref(props.check)

const emit = defineEmits(['change'])

const onChange = (checked, id) => {
    emit('change', {
        value: checked,
        id: id
    })
    changed.value = checked
}
</script>

<style lang="scss" src="./index.scss"></style>
