<template>
    <div class="reports-results" v-if="data">
        <z-table class="reports-results__table text-size-" :colgroup="['50%', '10%', '10%']">
            <template #head>
                <z-table-col is-th>
                    <span v-html="text.name"></span>
                </z-table-col>
                <z-table-col is-th>
                    <span v-html="text.pressRelease"></span>
                </z-table-col>
                <z-table-col is-th>
                    <span v-html="text.report"></span>
                </z-table-col>
            </template>
            <z-table-row v-for="(row, index) in data" :key="index">
                <z-table-col>
                    <span class="text-size-l" v-html="row.name"></span>
                </z-table-col>
                <z-table-col>
                    <z-tooltip theme="light-mode" v-if="row.pressRelease?.detailPageUrl">
                        <template v-slot:content>
                            {{ text.tooltipPressRelease }}
                        </template>
                        <template v-slot:trigger>
                            <a class="reports-results__link" :href="row.pressRelease.detailPageUrl">
                                <z-icon name="tools/press-release-big" width="40" height="40" />
                            </a>
                        </template>
                    </z-tooltip>
                </z-table-col>
                <z-table-col>
                    <z-tooltip theme="light-mode" v-if="row.reports?.file?.link">
                        <template v-slot:content>
                            {{ text.tooltipDownload }} <br> {{ row.reports.file.type }}, {{ row.reports.file.size }}
                        </template>
                        <template v-slot:trigger>
                            <a target="_blank" class="reports-results__link" :href="row.reports.file.link">
                                <z-icon name="tools/press-release-big" width="40" height="40" />
                            </a>
                        </template>
                    </z-tooltip>
                </z-table-col>
            </z-table-row>
            
        </z-table>
    </div>
</template>

<script setup>
import { localize } from '@/utils/i18n'

defineProps({
    data: {
        type: Object
    }
})

const text = {
    name: localize({
        en: 'Name',
        ru: 'Название'
    }),
    pressRelease: localize({
        en: 'Press Release',
        ru: 'Пресс-релиз'
    }),
    report: localize({
        en: 'Report',
        ru: 'Отчет'
    }),
    tooltipDownload: localize({
        en: 'Download',
        ru: 'Скачать'
    }),
    tooltipPressRelease: localize({
        en: 'Read Press Release',
        ru: 'Читать пресс-релиз'
    })
}
</script>

<style lang="scss">
.reports-results {
    &__link {
        display: block;
        color: $token-colors-link-default;

        &:hover {
            color: $token-colors-link-hover;
        }

        &:active {
            color: $token-colors-link-active;
        }

        .z-icon path {
            fill: currentColor;
            transition: fill $transition;
        }
    }

    &__table {
        th,
        td {
            vertical-align: middle;
            text-align: center;

            &:first-child {
                min-width: 200px;
                text-align: left;
            }
        }
    }
}
</style>