<template>
    <div class="news-filter" v-if="data">
        <div class="row">
            <div class="col-default-7  col-v-tablet-12">
                <z-input
                    :placeholder="text.q"
                    v-model="params.q"
                    @input="onInput"
                    is-filter
                    icon="tools/magnifier"
                    icon-position="right"
                />
            </div>
            <div class="col-default-5 col-v-tablet-12">
                <div class="datepickers-range">
                    <z-date-picker
                        ref="datepicker"
                        :placeholder="text.date"
                        range
                        is-filter
                        @change="onDateChange"
                        v-model="period"
                    />
                </div>
            </div>
        </div>
        <div class="news-filter__clear-wrapper" v-if="isFilterActive">
            <z-link
                href="javascript:void(0);"
                icon-position="right"
                icon="tools/close-small"
                @click="clear"
                size="s"
                :underlined="false"
            >
                <span v-html="text.reset"></span>
            </z-link>
        </div>
    </div>
</template>

<script setup>
import { debounce } from 'throttle-debounce'
import { localize } from '@/utils/i18n'
import { computed, ref, onBeforeMount } from 'vue'

import {
    queryString,
    clearEmptyParams,
    getGetParams
} from '@/utils/filterUtils'

defineProps({
    data: {
        type: Object
    }
})

const emit = defineEmits([
    'change',
    'update-params'
])

let params = ref({
    q: '',
    from: '',
    to: '',
    type: '',
    subject: []
})

const period = ref([])

const text = {
    date: localize({
        ru: 'Дата',
        en: 'Date'
    }),
    q: localize({
        ru: 'Поиск по названию',
        en: 'Search'
    }),
    reset: localize({
        ru: 'Сбросить фильтр',
        en: 'Reset'
    })
}

const isFilterActive = computed(() => {
    let active = false

    for (const key in params.value) {
        if (Array.isArray(params.value[key])) {
            if (params.value[key].length) {
                active = true
                break
            }
        } else {
            if (params.value[key]) {
                active = true
                break
            }
        }
    }

    return active
})

const onInput = debounce(500, function () {
    onFilterChange()
})

const onFilterChange = () => {
    emit('change', params.value)
    queryString(clearEmptyParams(params.value))
}

const getParams = () => {
    params.value = { ...params.value, ...getGetParams() }

    if (params.value.from & params.value.to) {
        period.value = [params.value.from, params.value.to]
    }

    emit('update-params', params.value)
}

const onDateChange = data => {
    params.value.from = data?.[0] || ''
    params.value.to = data?.[1] || ''

    onFilterChange()
}

const clear = () => {
    params.value = {
        q: '',
        from: '',
        to: '',
        type: '',
        subject: []
    }
    period.value = []
    onFilterChange()
}

onBeforeMount (() => {
    getParams()
})
</script>

<style lang="scss">
.datepickers-range {
    display: flex;
    align-items: center;
    width: 100%;
    
    &__range {
        display: block;
        width: 12px;
        height: 2px;
        background: $token-colors-black-30;
        margin: 0 8px;
        flex-grow: 0;
    }

    .z-date-picker {
        flex-grow: 1;
    }
}

.news-filter {
    .row {
        @include margin-level(top, XS, true);

        > [class*="col-"] {
            @include margin-level(top, XS);
        }
    }

    &__clear-wrapper {
        display: flex;
        justify-content: flex-end;
    }
}
</style>