import { app } from './app'

const requireComponent = require.context(
    '../components',
    true,
    /Z[\w-]+\/index\.js$/
)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    let componentName = fileName.split('/')[1]

    for (let key in componentConfig) {
        if (componentConfig.hasOwnProperty(key)) {
            if (key !== 'default') componentName = key
            app.component(componentName, componentConfig[key])
        }
    }
})
