<template>
    <detail-page
        class="news-detail"
        v-if="data"
        :date="data.activeFrom"
        :detail="data.detailText"
        :nav="nav"
        :name="data.name"
    >
        <template v-slot:footer>
            <div class="row" v-if="data.pdf.link">
                <div class="col-default-10 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                    <z-filelist class="u-top-margin--l">
                        <z-filelist-item size="l" icon-size="big" :icon="data.pdf.type" :file-size="data.pdf.size">
                            <z-link
                                size="s"
                                :underlined="false"
                                :href="data.pdf.link"
                                target="_blank"
                            >
                                <span v-html="text.file"></span>
                            </z-link>
                        </z-filelist-item>
                    </z-filelist>
                </div>
            </div>
        </template>
    </detail-page>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'news-detail',
    data () {
        return {
            data: this.$root.app.components['news-detail']?.item,
            nav: this.$root.app.components['news-detail']?.nav,
            text: {
                file: localize({
                    ru: 'Скачать полную версию пресс-релиза',
                    en: 'Download press release'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.news-detail .html-content table:has(tbody) {
    overflow-x: auto;
    display: block;


    &::-webkit-scrollbar {
        height: .5em;
        border-radius: .2em;
    }

    &::-webkit-scrollbar-thumb {
        background: $token-colors-black-30;
    }
}
</style>
