import { computed, ref, onBeforeMount } from 'vue'
import moment from 'moment'

export const useRegNewsComputedData = (data) => {
    const getYear = (timestamp) => moment.unix(timestamp).format('YYYY')

    const tabs = computed(() => data.reduce((acc, el) => {
        const year = getYear(el.date)
        const yearObj = {
            id: year,
            text: year
        }

        if (!acc.some((y) => y.id === yearObj.id)) {
            acc.push(yearObj)
        }
        return acc
    }, []).sort((a, b) => b.id - a.id)
    )

    const initialTab = ref('')

    onBeforeMount(() => {
        if (tabs.value.length) {
            initialTab.value = tabs.value[0]?.id
        }
    })

    const filteredItems = (id) => {
        if (!initialTab.value) return data || []

        return data.filter((el) => getYear(el.date) === id).sort((a, b) => moment.unix(b.date) - moment.unix(a.date))
    }


    return { tabs, filteredItems, initialTab }
}
