<template>
    <article class="detail-page" itemscope itemtype="https://schema.org/Article">
        <meta
            v-if="date"
            itemprop="datePublished"
            :content="formatDate(date, dateFormatPattern)"
        >

        <slot name="header" />

        <div class="detail-page__date-wrapper" v-if="date">
            <span v-html="text.date" class="u-block u-medium"></span>
            <time
                class="detail-page__date"
                v-html="formatDate(date, displayDateFormatPattern)"
                :datetime="formatDate(date, dateFormatPattern)"
            ></time>
        </div>

        <div class="detail-page__content html-content" itemprop="articleBody">
            <div class="row">
                <div class="col-default-10 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                    <vue-raw :raw="detail" />
                </div>
            </div>
        </div>

        <slot name="footer" />

        <z-page-nav
            v-if="nav"
            :data="nav"
            class="u-top-margin--m"
        />
    </article>
</template>

<script>
import { momentFilter } from '@/utils/filter'
import { mapActions } from 'vuex'
import { localize } from '@/utils/i18n'

export default {
    name: 'detail-page',
    props: {
        name: String,
        date: [String, Number],
        detail: String,
        nav: Object,
        dateFormatPattern: {
            type: String,
            default: 'YYYY-MM-DD'
        },
        displayDateFormatPattern: {
            type: String
        }
    },
    data () {
        return {
            text: {
                date: localize({
                    ru: 'Дата:',
                    en: 'Date:'
                })
            }
        }
    },
    methods: {
        formatDate (date, pattern = 'DD MMMM YYYY') {
            return date ? `${momentFilter(date, pattern)}` : date
        },
        ...mapActions([
            'SetHeroTitle'
        ])
    },
    created () {
        if (this.name) this.SetHeroTitle(this.name)
    }
}
</script>
<style lang="scss">
.detail-page {
    &__date-wrapper {
        @include margin-level(bottom, M);
        display: flex;
        @include typo-level(XS);
    }

    &__date {
        margin-left: $token-spacers-2-xs;
        color: $token-colors-black-60;
    }
}
</style>
