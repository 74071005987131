import { computed } from 'vue'
import moment from 'moment'

export const useRegFilingsHandlers = (data, selectedYear, filterType) => {
    const filteredData = computed(() => {
        if (!selectedYear.value) return data.value

        const filteredArr = data.value.filter((el) => {
            let year 
            switch(filterType) {
            case 'year':
                year = el.data[ 2 ]
                break
            case 'quarter':
                year = el.data[ 2 ].substr(0, 4)
                break
            case 'timestamp':
                year = moment.unix(el.date).format('YYYY')
                break
            default: ''
            }
            return year === selectedYear.value
        })
    
        return filteredArr
    })

    const filters = computed(() =>
        data.value.reduce((acc, el) => {
            let year
            switch (filterType) {
            case 'year':
                year = {
                    text: el.data[ 2 ],
                    id: el.data[ 2 ]
                }
                break
            case 'quarter':
                year = {
                    text: el.data[ 2 ].substr(0, 4),
                    id: el.data[ 2 ].substr(0, 4)
                }
                break
            case 'timestamp':
                year = {
                    text: moment.unix(el.date).format('YYYY'),
                    id: moment.unix(el.date).format('YYYY')
                }
                break
            default: ''
            }

            if (!acc.some((y) => y.id === year.id)) {
                acc.push(year)
            }
            return acc
        }, [])
    )
    return { filters, filteredData }
}