<template>
    <a
        class="z-tabs-label"
        :class="classObject"
        :href="`#${itemId}`"
        @click="toggleItem($event)"
    >
        <slot />
    </a>
</template>

<script setup>
import {inject, computed, onMounted} from 'vue'

const props = defineProps({
    id: {
        type: [String, Number],
        required: true
    },
    rightSide: {
        type: Boolean,
        default: false
    }
})

// state
const itemId = `tabs-${props.id}`

const HASH = window.location.hash ? window.location.hash.split('#')[1] : null

// inject
const {openedTab, selectTab, isFilter} = inject('tabs-provider')

// methods
function toggleItem (evt) {
    if (isFilter) evt.preventDefault()
    selectTab(itemId)
}

function checkHash () {
    if (HASH === itemId) {
        toggleItem()
    }
}

// computed
const classObject = computed(() => {
    return [{'z-tabs-label--opened': openedTab.value === itemId}, {'z-tabs-label--right': props.rightSide}]
})

// mounted
onMounted(() => {
    checkHash()
})
</script>
