<script>
import { AppDeviceEnquire } from '@/utils/mixin'
import { localize } from '@/utils/i18n'
import '@/styles/app.scss'
import '@/styles/print.scss'

const Emitter = require('tiny-emitter')
const emitter = new Emitter()

export default {
    name: 'App',
    mixins: [AppDeviceEnquire],
    data () {
        return {
            lang: window.App.page.lang,
            app: window.App,
            emitter: emitter
        }
    },
    methods: {
        localize: localize
    },
    mounted () {
        // что-то на костыльном, надо подумать
        const container = document.querySelector('#app')
        container.classList.add('is-loaded')
        this.$tippy('[data-tippy-content]')
    }
}
</script>
