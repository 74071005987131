import {app} from './config/app'
import VueRaw from './components/VueRaw'
import CalendarFilters from './components/CalendarFilters'
import CardListItem from './components/CardListItem'
import SiteSelector from './components/SiteSelector'
import NavigationTop from './components/NavigationTop'
import Social from './components/Social'
import NavigationLeft from './components/NavigationLeft'
import {Slider, Slide, SlideContent} from './components/Slider'
import LastUpdates from './components/LastUpdates'
import CookiePopup from './components/CookiePopup'
import { ContactPerson } from './components/Contacts'
import NewsCard from './components/NewsCard'

app.component('vue-raw', VueRaw)
app.component('calendar-filters', CalendarFilters)
app.component('card-list-item', CardListItem)
app.component('site-selector', SiteSelector)
app.component('navigation-top', NavigationTop)
app.component('navigation-left', NavigationLeft)
app.component('social', Social)
app.component('slider', Slider)
app.component('slide', Slide)
app.component('slide-content', SlideContent)
app.component('last-updates', LastUpdates)
app.component('cookie-popup', CookiePopup)
app.component('contact-person', ContactPerson)
app.component('news-card', NewsCard)
