<template>
    <figure class="z-figure">
        <div
            v-if="!video"
            :class="[
                'z-figure__image',
                {'z-figure--border-radius': borderRadius}
            ]"
            :style="`background-image: url(${this.image}); padding-top: ${this.paddingTop}`"
            
        ></div>

        <div
            :class="[
                { 'z-figure__video' : video },
                { 'z-figure--border-radius': borderRadius }
            ]"
            v-if="video"
        >
            <z-video
                :src="video"
                :poster="image"
                :autoplay="false"
            ></z-video>
        </div>
        
        <figcaption
            class="z-figure__caption"
            v-if="caption"
            v-html="caption"
        ></figcaption>
    </figure>
</template>

<script>
export default {
    name: 'z-figure',
    props: {
        image: {
            type: String
        },
        caption: {
            type: String
        },
        paddingTop: String,
        borderRadius: {
            type: Boolean,
            default: true
        },
        video: [String, Array],
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
