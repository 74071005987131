<template>
    <div class="z-blockquote">
        <div
            class="z-blockquote__quote"
            v-if="quote"
            
        ></div>
        <div class="z-blockquote__container">
            <blockquote class="z-blockquote__text">
                <slot></slot>
            </blockquote>

            <div class="z-blockquote__footer">
                <contact-person
                    v-if="author && author.name"
                    size="s"
                    :name="author.name"
                    :description="author.description"
                    :image="author.image"
                ></contact-person>

                <div>
                    <div class="z-blockquote__source" v-if="source && source.name">
                        <span
                            class="z-blockquote__source-title"
                            v-html="locales.sourceTitle"
                        ></span>
                        <component
                            :is="source.link ? 'z-link' : 'span'"
                            class="z-blockquote__source-name"
                            :href="source.link || false"
                        >
                            <span v-html="source.name"></span>
                        </component>
                    </div>
                    <span
                        v-if="formatedDate"
                        class="z-blockquote__date"
                        v-html="formatedDate"
                    ></span>
                </div>
                
            </div>
           
        </div>
    </div>
</template>

<script setup>
import { localize } from '@/utils/i18n'
import { momentFilter } from '@/utils/filter'
import { computed } from 'vue'

const props = defineProps({
    quote: {
        type: Boolean,
        default: true
    },
    author: {
        type: Object,
        default: {
            name: String,
            description: String,
            image: String
        }
    },
    source: {
        type: Object,
        default: {
            name: String,
            link: String
        }
    },
    date: {
        type: [Number, String]
    }
})

const locales = {
    sourceTitle: localize({
        ru: 'Источник:',
        en: 'Source:'
    })
}

// computed
const formatedDate = computed(() => {
    return typeof (props.date) === 'number' ? `${momentFilter(props.date, 'DD MMMM YYYY')}` : props.date
})

</script>

<style lang="scss">
$blockquoteFontSize: M;

.z-blockquote {
    display: flex;

    @include breakpoint (mobile) {
        display: block;
    }
    
    &__quote {
        flex-shrink: 0;
        background-image: svg-load('quotation.svg', fill=$token-colors-primary);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        width: 24px;
        height: 24px;
        margin-right: $token-spacers-2-xs;
        margin-bottom: $token-spacers-3-xs;
    }

    &__text {
        @include typo-level($blockquoteFontSize);
        margin-bottom: $token-spacers-xs;
    }

    &__footer {
        display: flex;
        justify-content: space-between;

        @include breakpoint (mobile) {
            display: block;
        }
    }

    &__source-title {
        color: $token-colors-black-50;
        @include typo-level(XS);
        margin-bottom: $token-spacers-2-xs;
        display: block;
    }

    &__source-name {
        @include typo-level(XS);
    }

    &__source {
        margin-bottom: $token-spacers-3-xs;

        @include breakpoint (mobile) {
            margin-top: $token-spacers-xs;
        }
    }

    &__date {
        color: $token-colors-black-70;
        @include typo-level(XS);
    }
}
</style>