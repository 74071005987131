import Velocity from 'velocity-animate'

export const useMenuAnimation = () => {
    const DURATION = 200

    const appearAnimationBefore = (el) => {
        el.style.opacity = 0
    }

    const animationEnter = (el, done) => {
        Velocity(el, { opacity: 1 }, { duration: DURATION }, { complete: done })
    }

    const animationLeave = (el, done) => {
        Velocity(el, { opacity: 0 }, { duration: DURATION })
        Velocity(el, { display: 'none' }, { complete: done })
    }

    return { appearAnimationBefore, animationEnter, animationLeave }
}