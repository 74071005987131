<template>
    <tr
        class="z-table-row z-table-row--body"
        :class="buildClass"
        @click="check()"
    >
        <slot />
    </tr>
    <slot name="row" v-if="opened" /> <!-- для z-table-row с элементами раскрытия-->
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'z-table-row',
    mixins: [mixinDevice],
    props: {
        name: {
            type: String,
            default: ''
        },
        isClickable: Boolean,
        isExpandable: Boolean // пропс для добавления z-table-row стилей раскрытия
    },
    data () {
        return {
            opened: false,
            clicked: false
        }
    },
    computed: {
        buildClass () {
            return {
                'is-opened': this.clicked,
                'z-table-row--clickable': this.isClickable,
                'z-table-row--expandable': this.isExpandable
            }
        }
    },
    methods: {
        check () {
            if (this.isClickable) {
                this.clicked = !this.clicked
                this.opened = !this.opened
            }
        }
    }
}
</script>
