<template src="./index.html"></template>

<script>
import { bb } from 'billboard.js/dist/billboard.pkgd'

export default {
    name: 'z-chart',
    props: {
        options: {
            type: Object,
            required: true
        },
        media: {
            type: Object
        },
        legend: {
            type: Object,
            default: () => {
                return {
                    position: 'left-bottom'
                }
            }
        },
        theme: String
    },
    data () {
        return {
            $chart: null,
            themeChart: {
                grid: {
                    y: {
                        show: true
                    }
                },
                axis: {
                    x: {
                        tick: {
                            show: false
                        },
                        label: {
                            color: '#000000'
                        }
                    },
                    y: {
                        tick: {
                            show: false
                        }
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 30
                },
                color: {
                    pattern: [
                        '#006CFD',
                        '#A8D5EE',
                        '#729ACF',
                        '#204E8C',
                        '#EAC0EA',
                        '#EFC1A8',
                        '#CBCAFF',
                        '#204E8C'
                    ]
                },
                legend: {
                    contents: {
                        template: `<span class="z-chart__legend-item">
                                <span class="z-chart__legend-round" style='background-color:{=COLOR}'"></span>
                                <span class="z-chart__legend-title">{=TITLE}</span>
                            </span>`
                    }
                },
                bar: {
                    width: {
                        ratio: 0.7,
                        max: 50
                    }
                },
                labels: {
                    format: value => this.localizeValues(value)
                },
                tooltip: {
                    format: {
                        value: value => this.localizeValues(value)
                    }
                }
            }
        }
    },
    mounted () {
        this.$chart = this.generateChart()
        this.$emit('mounted', this.$chart)

        window.addEventListener('resize', () => {
            this.resizeChart()
        })
    },
    unmounted () {
        this.destroyChart()
    },
    watch: {
        'options.data': {
            deep: true,
            handler (newData) {
                this.loadDataChart(newData)
            }
        }
    },
    methods: {
        resizeChart () {
            if (this.media && window.innerWidth < this.media.point) {
                this.$chart.internal.config = Object.assign(this.$chart.internal.config, this.media.options)
            }
        },
        generateChart () {
            /**
             * @function generateChart
             *
             * @description
             * generates the charts bases on the options this
             *
             * @returns {Object} the generated chart this
             */
            const options = Object.assign({
                ...this.themeChart,
                bindto: this.$refs.chart
            }, this.options)

            if (options.legend.contents) {
                options.legend.contents.bindto = this.$refs.legend
            }

            return bb.generate(options)
        },
        destroyChart () {
            /**
             * @function destroyChart
             *
             * @description
             * destroys the chart and sets ref to null
             */
            return () => {
                try {
                    this.$chart.destroy()
                    this.$chart = null
                } catch (error) {
                    console.error('Internal Billboard.js error', error)
                }
            }
        },
        loadDataChart () {
            /**
             * @function loadDataChart
             *
             * @description
             * Updates the chart with the new data
             *
             * @param {object} options from this to update the chart with
             */
            return data => {
                if (!this.$chart) {
                    this.$chart = this.generateChart(this.options)
                }
                this.$chart.load(data)
            }
        },
        localizeValues (value) {
            return this.$root.lang === 'ru' ? value.toString().replace('.', ',') : value
        }
    },
    computed: {
        classObject () {
            let arrClass = [
                'z-chart'
            ]

            if (this.legend.position) {
                arrClass.push(`z-chart--legend-position-${this.legend.position}`)
            }

            if (this.theme) {
                arrClass.push(`z-chart--theme-${this.theme}`)
            }

            return arrClass
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
