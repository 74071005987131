<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :viewBox="currentIcon.viewBox"
        :aria-labelledby="name"
        role="presentation"
        class="z-icon"
        :class="`z-icon--${dir}`"
    >
        <slot>
            <g v-html="currentIcon.data"></g>
        </slot>
    </svg>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: 'box'
        },
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        },
        dir: {
            type: String,
            default: 'up',
            validator: prop => ['down', 'up', 'right', 'left'].includes(prop)
        }
    },
    computed: {
        currentIcon () {
            try {
                return require(`./icons/${this.name}`).default
            } catch {
                console.error(`icon "${this.name}" is not found`)
                return {}
            }
        }
    }
}
</script>

<style lang="scss">
.z-icon {
    display: inline-block;
    flex-shrink: 0;
    color: inherit;
    vertical-align: middle;
    fill: none;
    // stroke: currentColor;

    &--up {
        /* default */
        transform: rotate(0deg);
    }

    &--right {
        transform: rotate(90deg);
    }

    &--down {
        transform: rotate(180deg);
    }

    &--left {
        transform: rotate(-90deg);
    }
}
</style>
