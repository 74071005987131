/* import Global */
import {app} from './config/app'
import store from './store/'
import Tippy from './utils/tippy'
// import 'vue-svgicon/dist/polyfill'
import '@/utils/modernizr-custom' // global
import '@/config/directives'
import '@/config/ZComponents'
import '@/components'
import '@/views'

app.use(store)
app.use(Tippy)

app.mount('#app')
