<template>
    <z-tabs initial-id="1">
        <template #label>
            <z-tab-label id="1">{{locale.text.stock}}</z-tab-label>
            <z-tab-label id="2">{{locale.text.history}}</z-tab-label>
            <z-tab-label id="3">{{locale.text.calculator}}</z-tab-label>
        </template>
        <template #content>
            <z-tab-content id="1">
                <iframe
                    id="cbonds-iframe-1" :src="locale.links.stock"
                    onload="iFrameResize({}, '#cbonds-iframe-1');"
                    width="100%" height="1250px" border="0" frameborder="0"
                    allowtransparency="true" framespacing="0" scrolling="no"
                    style="overflow: hidden; height: 675px;"
                    allow="clipboard-write"
                />
            </z-tab-content>
            <z-tab-content id="2">
                <iframe
                    id="cbonds-iframe-2" :src="locale.links.history"
                    onload="iFrameResize({}, '#cbonds-iframe-2');"
                    width="100%" height="1250px" border="0" frameborder="0"
                    allowtransparency="true" framespacing="0" scrolling="no"
                    style="overflow: hidden; height: 675px;"
                    allow="clipboard-write"
                />
            </z-tab-content>
            <z-tab-content id="3">
                <iframe
                    id="cbonds-iframe-3" :src="locale.links.calculator"
                    onload="iFrameResize({}, '#cbonds-iframe-3');"
                    width="100%" height="1250px" border="0" frameborder="0"
                    allowtransparency="true" framespacing="0" scrolling="no"
                    style="overflow: hidden; height: 675px;"
                    allow="clipboard-write"
                />
            </z-tab-content>
        </template>
    </z-tabs>
</template>

<script setup>
import {reactive} from 'vue'
import {localize} from '@/utils/i18n'

const locale = reactive({
    links: {
        stock: localize({
            ru: 'https://widgets.cbonds.ru/stock_tradings/641da1bebcec5/?theme=cian',
            en: 'https://widgets.cbonds.com/stock_tradings/641da1bebcec5/?theme=cian'
        }),
        history: localize({
            ru: 'https://widgets.cbonds.ru/historical_quotes/641da25a8cc51/?theme=cian',
            en: 'https://widgets.cbonds.com/historical_quotes/641da25a8cc51/?theme=cian'
        }),
        calculator: localize({
            ru: 'https://widgets.cbonds.ru/investor_calculator/641da2a4a30b2/?theme=cian',
            en: 'https://widgets.cbonds.com/investor_calculator/641da2a4a30b2/?theme=cian'
        })
    },
    text: {
        stock: localize({
            ru: 'График торгов',
            en: 'Stock Chart'
        }),
        history: localize({
            ru: 'Исторические котировки',
            en: 'Historical Lookup'
        }),
        calculator: localize({
            ru: 'Калькулятор инвестора',
            en: 'Investment Calculator'
        })
    }
})
</script>

<style lang="scss">
</style>
