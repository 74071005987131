<template>
    <section :class="accPanelClasses">
        <slot/>
    </section>
</template>

<script setup>
import {computed} from 'vue'

const props = defineProps({
    verticalPadding: {
        type: Boolean,
        default: false
    },
    theme: {
        type: String,
        validator: prop => ['alternate'].includes(prop)
    },
    hideBorder: {
        type: Boolean,
        default: false
    }
})

const accPanelClasses = computed(() => {
    return [
        'z-accordion-panel',
        {
            'z-accordion-panel--vertical-padding': props.verticalPadding,
            'z-accordion-panel--hide-border': props.hideBorder
        },
        `z-accordion-panel--theme-${props.theme}`
    ]
})
</script>

<style lang="scss">
</style>
