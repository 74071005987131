<template>
    <div class="search-page">
        <z-input class="u-bottom-margin--xl" v-model="params.q" name="search" :placeholder="PLACEHOLDER.input" is-filter @input="modifiedFilterHandler"/>
        <template v-if="searchPerformed">
            <template v-if="data && items && items.length">
                <search-content :items="items"/>
                <z-preloader v-if="isLoading"/>
                <z-pagination :data="nav" @change-page="modifiedPaginationHandler"/>
            </template>
            <z-not-found v-else-if="!isLoading && !items?.length">
                <template #description>
                    <z-caption class="u-bottom-margin--xs-important" size="3xl" weight="500" v-html="NOT_FOUND.title"/>
                    <p class="text-size-s u-medium" v-html="NOT_FOUND.text"/>
                </template>
            </z-not-found>
        </template>
    </div>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import SearchContent from './components/SearchContent.vue'
import {getSearch} from '@/api/search'
import {useGetData} from '@/composable/useGetData'
import {useGetter} from '@/composable/useGetter'
import {useSearchHandlers} from '@/views/SearchPage/composable/useSearchHandlers'
import {useSearchComputedData} from '@/views/SearchPage/composable/useSearchComputedData'
import {useSearchLocalizedText} from '@/views/SearchPage/composable/useSearchLocalizedText'

const params = ref({
    q: '',
    page: 1
})
const isMore = ref(false)
const searchPerformed = ref(false)

const { app } = useGetter(['app'])
const appData = app.value.components?.search

const {NOT_FOUND, PLACEHOLDER} = useSearchLocalizedText()
const {fetcher, data, isLoading} = useGetData(getSearch, params, appData)
const {filterHandler, paginationHandler} = useSearchHandlers(fetcher, params, isMore)
const {items, nav} = useSearchComputedData(data, isMore)

const modifiedFilterHandler = (payload) => {
    searchPerformed.value = true
    filterHandler(payload)
}
const modifiedPaginationHandler = (payload) => {
    searchPerformed.value = true
    paginationHandler(payload)
}

onMounted(() => {
    const searchParams = new URL(document.location).searchParams
    searchParams.get('q') && (params.value.q = searchParams.get('q'))
    searchParams.get('currentPage') && (params.value.page = Number(searchParams.get('currentPage')))
    
    if (params.value.q) {
        searchPerformed.value = true
    }
})
</script>

<style lang="scss">
.search-page {
    &__input-container {
        display: flex;
    }

    .z-input {
        &__container {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 8px;
                transform: translateY(-50%);
                display: block;
                width: 24px;
                height: 24px;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 10.875a6.875 6.875 0 1 1 13.75 0 6.875 6.875 0 0 1-13.75 0zM10.875 2a8.875 8.875 0 1 0 5.528 15.818l3.89 3.89a1 1 0 0 0 1.413-1.415l-3.889-3.889A8.875 8.875 0 0 0 10.874 2z' fill='%23006CFD'/%3E%3C/svg%3E");
            }
        }
        &__input {
            padding-left: $token-spacers-m;
        }

        &__placeholder {
            left: $token-spacers-m;
        }
    }
}
</style>
