<template>
    <div
        class="z-tabs-content"
        v-show="openedTab === itemId"
    >
        <slot />
    </div>
</template>

<script setup>
import {inject, provide} from 'vue'

const props = defineProps({
    id: {
        type: [String, Number],
        required: true
    }
})

// state
const itemId = `tabs-${props.id}`

const {openedTab, selectTab} = inject('tabs-provider')

// methods
function handler () {
    selectTab(itemId)
}

// provide
provide('tabs-nested', handler)
</script>
