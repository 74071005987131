<template>
    <div class="sitemap">
        <ul class="sitemap__list">
            <li class="sitemap__item" v-for="(menuItem,index) in data" :key="index">
                <z-link size="xl" :href="menuItem.link" class="sitemap__item-title">
                    {{ menuItem.name }}
                </z-link>

                <ul v-if="menuItem.items && menuItem.items.length">
                    <li class="sitemap__subitem" v-for="(subMenuItem,index) in menuItem.items" :key="index">
                        <z-link :href="subMenuItem.link" :target="subMenuItem.params && subMenuItem.params.BLANK ? '_blank' : false">
                            <span v-html="subMenuItem.name"></span>
                            <z-icon v-if="subMenuItem.params.EXTERNAL" name="external" />
                        </z-link>

                        <ul v-if="subMenuItem.items && subMenuItem.items.length" class="sitemap__sublist-third">
                            <li class="sitemap__subitem" v-for="(subMenuItemThird,index) in subMenuItem.items" :key="index">
                                <z-link :href="subMenuItemThird.link" :target="subMenuItemThird.params && subMenuItemThird.params.BLANK ? '_blank' : false">
                                    <span v-html="subMenuItemThird.name"></span>
                                    <z-icon v-if="subMenuItemThird.params.EXTERNAL" name="external" />
                                </z-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script setup>
import {ref, onMounted} from 'vue'

const data = ref([])

onMounted(() => {
    data.value = window.App.components.sitemap
})
</script>

<style lang="scss">
.sitemap {
    &__title{
        color: $token-colors-link-default !important;
        margin-bottom: $token-spacers-l !important;
    }

    &__list {
        columns: 2 auto;

        @include breakpoint(mobile) {
            columns: 1 auto;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        vertical-align: top;
        margin-bottom: $token-spacers-xl;
        break-inside: avoid;
    }

    &__item-title {
        font-weight: 700;
        margin-bottom: 20px;
        display: block !important;
    }

    &__subitem {
        &:not(:last-child) {
            margin-bottom: 18px;
        }
    }

    &__sublist-third {
        margin-top: 18px;
        margin-left: 22px;
    }
}
</style>
