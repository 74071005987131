// import Vue from 'vue'
import { createStore } from 'vuex'

import app from './modules/app'
import getters from './getters'

// Vue.use(Vuex)

const store = createStore({
    modules: {
        app
    },
    state: {

    },
    mutations: {

    },
    actions: {

    },
    getters
})

export default store
