<template>
    <div class="z-checkbox-group" v-if="data.length">

        <div class="z-checkbox-group__container">
            <div
                class="z-checkbox-group__group"
                v-for="(group, index) in groupedItems"
                :key="index"
            >
                <span
                    class="z-checkbox-group__group-title"
                    v-html="group.name"
                    v-if="group.name"
                ></span>
                <z-checkbox
                    :data="{
                        text: group['all-text'] || selectAllText,
                        id: `all-checkbox-${group.id}`,
                        selected: false
                    }"
                    :size="size"
                    @change="onClickAll(group.id)"
                    :model-value="isAllSelected(group.id)"
                    v-if="showAllCheckbox"
                    class="z-checkbox-group__all"
                    :class="{'z-checkbox-group__all--partly' : isPartlySelected(group.id) }"
                />
                <div class="z-checkbox-group__list">
                    <div
                        class="z-checkbox-group__item"
                        v-for="(item, index) in group.items"
                        :key="index"
                    >
                        <z-checkbox
                            :class="{ 'is-errored' : error }"
                            :data="item"
                            :size="size"
                            :key="`${item.id}-${item.selected}`"
                            @change="onChange($event, item)"
                            :model-value="selected.includes(item.id)"
                        />
                    </div>
                </div>
            </div>

        </div>
        <span
            :class="['z-checkbox-group__error', errorClass]"
            v-if="error"
            v-html="error"
        ></span>
    </div>
</template>

<script setup>
import { ref, computed, onBeforeMount, nextTick } from 'vue'
import { localize } from '@/utils/i18n'

const props = defineProps({
    data: {
        type: Array
    },
    modelValue: {
        type: Array
    },
    required: {
        type: Boolean,
        default: false
    },
    errorClass: {
        type: String
    },
    size: {
        type: String,
        default: 'm',
        validator: prop => ['s', 'm', 'l'].includes(prop)
    },
    showAllCheckbox: {
        type: Boolean,
        default: false
    },
    selectAllText: {
        type: String,
        default: localize({
            ru: 'Выбрать все',
            en: 'Choose all',
            cn: 'Choose all'
        })
    }
})

const emit = defineEmits([
    'update:modelValue',
    'change'
])

// static data
const errorText = localize({
    ru: 'Поле обязательно для заполнения',
    en: 'Required field',
    cn: '填项目'
})

// refs
let localValue = ref([])
let error = ref('')

// computed
const selected = computed({
    get() {
        if (props.modelValue !== undefined) return props.modelValue // для работы без v-model
        return localValue.value
    },
    set(value) {
        localValue.value = value
        emit('update:modelValue', value)
    }
})

const groupedItems = computed(() => {
    let result = {}
    props.data.forEach(item => {
        if (item.group) {
            if (!result[item.group.id]) result[item.group.id] = {}
            if (!result[item.group.id].items) result[item.group.id].items = []
            result[item.group.id] = { ...result[item.group.id], ...item.group }
            result[item.group.id].items.push(item)
        } else {
            if (!result.nogroup) result.nogroup = {}
            if (!result.nogroup.items) result.nogroup.items = []
            result.nogroup['all-text'] = ''
            result.nogroup.id = ''
            result.nogroup.name = ''
            result.nogroup.items.push(item)
        }
    })

    return result
})

const isAllSelected = id => {
    const groupItems = props.data.filter(item => item.group?.id === id).map(item => item.id)

    return JSON.stringify(localValue.value.filter(x => groupItems.includes(x)).sort()) === JSON.stringify(groupItems.sort())
}

const isPartlySelected = id => {
    const groupItems = props.data.filter(item => item.group?.id === id).map(item => item.id)

    return selected.value.filter(x => groupItems.indexOf(x) !== -1)?.length && !isAllSelected(id)
}

// methods
const validate = () => {
    if (props.required) {
        if (!(selected.value && selected.value.length)) {
            error.value = errorText
            return
        }
    }
    error.value = ''
}

// events
const onChange = (state, item) => {
    const index = selected.value.indexOf(item.id)
    if (!state.value && index !== -1) {
        localValue.value.splice(index, 1)
    } else {
        localValue.value.push(item.id)
    }

    emit('update:modelValue', localValue.value)
    emit('change', Array.from(selected.value))
    validate()
}

const onClickAll = (id) => {
    const groupItems = props.data.filter(item => item.group?.id === id).map(item => item.id)
    localValue.value = isAllSelected(id) ? localValue.value.filter(x => !groupItems.includes(x)) : [...new Set([...localValue.value, ...groupItems])]

    emit('update:modelValue', localValue.value)
    emit('change', Array.from(selected.value))

    nextTick(validate)
}

onBeforeMount (() => {
    props.data.forEach(item => {
        if (item.selected) {
            localValue.value.push(item.id)
        }
    })
    emit('update:modelValue', localValue.value)
})

defineExpose({
    validate,
    error
})
</script>

<style lang="scss">
.z-checkbox-group {
    &__group {
        & + & {
            @include margin-level(top, M);
        }
    }

    &__list {
        display: flex;
        gap: $token-spacers-xs;
        flex-wrap: wrap;

        @include breakpoint (mobile) {
            gap: $token-spacers-2-xs;
        }
    }

    &__error {
        font-size: var(--errorTextSize);
        color: var(--errorTextColor);
        position: relative;
    }

    &__all {
        @include margin-level(bottom, S);

        &--partly {
            .z-checkbox__box {
                position: relative;

                &:after {
                    content: '';
                    width: 50%;
                    height: 45%;
                    background: var(--formBorderColorFilledAccent);
                    opacity: 0.85;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: calc(var(--borderRadiusCheckbox) / 2);
                }
            }
        }
    }

    &__group-title {
        @include typo-level(L);
        @include margin-level(bottom, S);
        font-weight: 600;
        display: block;
        width: 100%;
    }
}
</style>
