<template>
    <div class="card card--not-img card--list">
        <span class="card__info">
            <span class="card__top">
                <span class="card__tags" v-if="data.tags">
                    <span
                        class="card__tag"
                        v-for="(tag, index) in data.tags"
                        :key="index"
                    >
                        {{ tag }}
                    </span>
                </span>
                <span
                    class="card__date"
                    v-if="data.date"
                    v-html="buildDate(data.date)"
                ></span>
            </span>
            <a
                class="card__detail"
                :href="data.link"
                :target="external ? '_blank' : false"
                :class="{'card__detail--pdf': checkPDF(data.link)}"
                v-html="data.name"
            ></a>
            <span
                v-if="data.preview"
                v-html="data.preview"
                class="card__text"
            ></span>
            <z-link
                v-if="data.pdf && data.pdf !== null"
                :download="data.name"
                :href="data.pdf"
                theme="text"
                size="xs"
            >
                <z-icon name="download" dir="top" width="20" height="20"></z-icon>
                {{ text.download }}
            </z-link>
            <span
                v-if="data.source"
                class="card__source"
            >{{ text.source }}  &#171;{{ data.source }}&#187;</span>
        </span>
    </div>
</template>

<script>
import moment from 'moment'

/*
Образец структуры data
{
    name: 'name',
    link: '/link.pdf',
    preview: 'Hello text',
    tags: ['tag', 'tag2'],
    date: 1673299080,
    source: 'Source',
    pdf: null
}
 */

export default {
    name: 'card-list-item',
    props: {
        data: Object,
        external: Boolean,
        timeShow: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            text: {
                source: this.$root.lang === 'ru' ? 'Источник:' : 'Source:',
                download: this.$root.lang === 'ru' ? 'Скачать pdf' : 'Download pdf'
            }
        }
    },
    methods: {
        buildDate (date) {
            moment.locale(`${this.$root.lang}`)
            const momentDate = moment.unix(date)
            let dateStr = ''

            if (momentDate.hours() !== 0 && momentDate.minutes() !== 0 && this.timeShow) {
                dateStr = momentDate.format('DD MMMM YYYY, HH:mm')
            } else {
                dateStr = momentDate.format('DD MMMM YYYY')
            }

            return dateStr
        },
        checkPDF (link) {
            if (link.match(/\.pdf$/g) !== null) {
                return link.match(/\.pdf$/g).length
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
