<template>
    <div>
        <news-filter
            @change="onFilterChange"
            @update-params="onUpdateParams"
            :data="data.filter"
        />

        <results class="u-top-margin--l" :data="items" v-if="items.length"/>

        <z-not-found class="u-top-margin--m" v-else></z-not-found>

        <z-preloader v-if="isLoading" />
        <z-pagination
            :data="paginationData"
            @change-page="onPageChange"
            class="u-top-margin--xl-important"
        >
        </z-pagination>
    </div>
</template>

<script setup>
import NewsFilter from './components/Filter.vue'
import Results from './components/Results.vue'
import { getNews } from '@/api/news'
import { computed, ref, watch } from 'vue'
import { useGetData } from '@/composable/useGetData'
import { useGetter } from '@/composable/useGetter'
import { useHandlers } from '@/views/News/composable/useHandlers'

const params = ref({
    page: 1,
    q: '',
    from: '',
    to: ''
})
const { app } = useGetter(['app'])
const isMore = ref(false)
const appData = app.value.components?.news
const { isLoading, data, fetcher } = useGetData(getNews, params, appData)

const { onPageChange, onUpdateParams, onFilterChange } = useHandlers(params, isMore, fetcher)

const paginationData = computed(() => {
    return ({
        currentPage: data.value.nav?.current || 0,
        totalItems: data.value.nav?.count || 0,
        totalPages: data.value.nav?.total || 0,
        pageRange: 1
    })
})

const items = ref(data.value.items || [])

watch(data, () => {
    if (!data.value.items) {
        items.value = []
        return
    }

    items.value = isMore.value ? [...items.value, ...data.value.items] : items.value = data.value.items
})
</script>

<style lang="scss">

</style>
