<template>
    <nav class="z-breadcrumbs">
        <ul
            class="z-breadcrumbs__list"
            itemscope=""
            itemtype="http://schema.org/BreadcrumbList"
        >
            <li
                class="z-breadcrumbs__item"
                v-for="(item, index) in data"
                :key="index"
                itemprop="itemListElement"
                itemscope=""
                itemtype="http://schema.org/ListItem"
            >
                <component
                    :is="isCurrent(item.url) ? 'span' : 'z-link'"
                    class="text-size-2xs"
                    :underlined="false"
                    :theme="inverted ? 'light' : 'dark'"
                    :href="item.url"
                    :title="item.name"
                    itemscope=""
                    itemtype="http://schema.org/Thing"
                    itemprop="item"
                >
                    <span v-html="item.name"></span>
                </component>
            </li>
        </ul>
    </nav>
</template>

<script setup>
// props
defineProps({
    inverted: {
        type: Boolean,
        default: false
    }
})

// data
const pathname = window.location.pathname
const data = window.App.components.breadcrumbs

// methods
function isCurrent (link) {
    return pathname === link
}
</script>

<style lang="scss" src="./index.scss"></style>
