import {localize} from '@/utils/i18n'

export default {
    text: {
        title: localize({
            ru: 'Использование файлов cookie',
            en: 'This website uses cookies'
        }),
        description: localize({
            ru: 'Этот веб-сайт использует файлы cookie. Некоторые обеспечивают работу сайта, а&nbsp;другие предназначены для повышения удобства пользователей. Нажмите &laquo;Принять&raquo;, чтобы принять наши файлы cookie. Вы&nbsp;можете изменить настройки файлов cookie, нажав &laquo;Изменить настройки&raquo;, или на&nbsp;странице <z-link href="/ru/cookies-policy/">&laquo;Политика использования файлов cookie&raquo;</z-link>.',
            en: 'This website uses cookies. Some make the site work, while others are designed to&nbsp;improve the user experience. Click "I&nbsp;agree" to&nbsp;accept our cookies. You can change your cookie settings by&nbsp;clicking "Change Settings" or&nbsp;on&nbsp;the <z-link href="/en/cookies-policy/">"Cookie Policy" page</z-link>.'
        }),
        button: {
            agree: localize({
                ru: 'Принять',
                en: 'I agree'
            }),
            more: localize({
                ru: 'Изменить настройки',
                en: 'Change settings'
            }),
            hide: localize({
                ru: 'Скрыть настройки',
                en: 'Hide settings'
            }),
            reject: localize({
                ru: 'Отказаться от всего',
                en: 'Reject all'
            })
        }
    }
}
