import {computed, ref, watch} from 'vue'

export const useSearchComputedData = (data, isMore) => {
    const items = ref(data.value.items || [])


    watch(data, () => {
        if (!data.value) {
            items.value = []
            return
        }
        if (isMore.value) {
            isMore.value = false
            items.value = [...items.value, ...data.value.items]
        } else {
            items.value = data.value.items
        }
    })

    const nav = computed(() => {
        return {
            currentPage: data.value.nav?.current || 0,
            totalItems: data.value.nav?.count || 0,
            totalPages: data.value.nav?.total || 0,
            pageRange: 1
        }
    })

    return {items, nav}
}
