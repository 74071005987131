<template>
    <div class="z-filelist-item" :class="buildClass">
        <div class="z-filelist-item__body">
            <div class="z-filelist-item__icon" v-if="icon || this.$slots['icon']">
                <z-icon
                    v-if="icon"
                    :name="theme === 'dark' ? `file/${iconSize}/dark/${icon}` : `file/${iconSize}/${icon}`"
                    :width="size === 'l' ? iconSizeSmall : iconSizeBig"
                    :height="size === 'l' ? iconSizeSmall : iconSizeBig"
                />
                <slot v-else name="icon"/>
            </div>
            <div class="z-filelist-item__slot">
                <div class="z-filelist-item__wrapper" v-if="lang || date">
                    <span v-if="date" class="z-filelist-item__text" v-html="formattedDate"></span>
                </div>
                <slot/>
                <div class="z-filelist-item__wrapper z-filelist-item__wrapper--bottom" v-if="fileType || fileSize">
                    <span v-if="fileType" v-html="fileType" class="z-filelist-item__text"/>
                    <span v-if="fileSize" v-html="fileSize" class="z-filelist-item__text"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {momentFilter} from '@/utils/filter'
export default {
    name: 'z-filelist-item',
    props: {
        size: {
            type: String,
            validator: prop => ['l', 'xl'].includes(prop),
            default: 'xl'
        },
        icon: {
            type: String,
            validator: prop => ['doc', 'docx', 'gif', 'jpeg', 'jpg', 'mp3', 'mp4', 'no-file', 'pdf', 'png', 'ppt', 'rar', 'tif', 'tiff', 'txt', 'webm', 'xls', 'xlsx', 'zip'].includes(prop),
            default: 'no-file'
        },
        lang: {
            type: String
        },
        fileType: {
            type: String
        },
        fileSize: {
            type: String,
            default: ''
        },
        iconSize: {
            type: String,
            validator: prop => ['big', 'small'].includes(prop),
            default: 'big'
        },
        date: {
            type: [String, Number],
            default: ''
        },
        iconTop: {
            type: Boolean
        },
        centered: {
            type: Boolean,
            default: false
        },
        theme: {
            type: String,
            default: 'dark'
        }
    },
    data() {
        return {
            iconSizeBig: 72,
            iconSizeSmall: 48
        }
    },
    computed: {
        formattedDate () {
            return typeof (this.date) === 'number' ? `${momentFilter(this.date, 'DD MMMM YYYY')} ${this.$root.app.page.lang === 'ru' ? 'года' : 'year'}` : this.date
        },
        buildClass () {
            return [
                {'z-filelist-item--with-icon': this.icon || this.$slots['icon']},
                {'z-filelist-item--icon-top': this.iconTop},
                {'z-filelist-item--centered': this.centered},
                {'z-filelist-item--theme-dark': this.theme === 'dark'},
                `z-filelist-item--${this.size}`
            ]
        }
    }
}
</script>
