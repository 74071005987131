<template>
    <div class="bio" v-if="data && data.length">
        <div class="row" v-for="(arr, i) in groupedData" :key="i">
            <div
                class="col-default-4 col-v-tablet-6 col-mobile-12"
                v-for="(item, index) in arr"
                :key="`bio-${index}`"
            >
                <a :href="item.detailPageUrl" class="bio__card">
                    <div
                        class="bio__avatar"
                        :style="`background-image: url('${getAvatar(item)}')`">
                    </div>
                    <div>
                        <span class="bio__name" v-html="item.name"></span>
                    </div>
                    <span class="bio__post" v-html="item.position.value"></span>
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useGetter } from '@/composable/useGetter'
import {computed} from 'vue'

const props = defineProps({
    source: {
        type: String,
        default: 'board-of-directors'
    }
})

const { app } = useGetter(['app'])
const data = app.value.components[props.source]?.items || []
const lang = app.value.page?.lang || 'en'

let positionsOrder
if (lang === 'ru') {
    positionsOrder = ['независимый неисполнительный директор', 'неисполнительный директор', 'председатель совета директоров']
} else {
    positionsOrder = ['independent non-executive director', 'non-executive director', 'chairman of the board of directors']
}

const groupedData = computed(() => {
    if (props.source === 'management') {
        return [data]
    }
    return data.slice().reduce((result, item) => {
        if (item.position.value.toLowerCase() === positionsOrder[0]) {
            result[2].push(item)
        } else if (item.position.value.toLowerCase() === positionsOrder[1]) {
            result[1].push(item)
        } else if (item.position.value.toLowerCase() === positionsOrder[2]) {
            result[0].push(item)
        } else {
            result[3].push(item)
        }
        return result
    }, [[], [], [], []])
})

const getAvatar = (item) => {
    if (item.previewPicture.link) {
        return item.previewPicture.link
    }

    return '/images/placeholder.png'
}
</script>

<style lang="scss">
.bio {
    &__avatar {
        width: 100%;
        padding-top: 100%;
        @include margin-level(bottom, 2XS);
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        transition: box-shadow $transition;
    }

    &__name {
        font-weight: 500;
        line-height: 1.2;
        @include typo-level(XL);
        color: $token-colors-link-dark-default;
        text-align: center;
        @include margin-level(bottom, 3XS);
        transition: all $transition;
        box-shadow: inset 0 -0.3em 0 0px #D9E2F5;
    }

    &__post {
        @include typo-level(M);
        color: $token-colors-link-default;
        text-align: center;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include padding-level (left, XS);
        @include padding-level (right, XS);
        text-decoration: none;
        text-align: center;

        &:hover {
            cursor: pointer;

            .bio__name {
                color: $token-colors-link-dark-hover;
                text-decoration: none;
                box-shadow: inset 0 -0.5em 0 0px #D9E2F5;
            }

            .bio__avatar {
                box-shadow: 0px 8px 16px #D9E2F5;
            }
        }

        &:active {
            .bio__name {
                color: $token-colors-link-dark-active;
                box-shadow: inset 0 -0.5em 0 0px #D9E2F5;
            }
        }
    }

    .row {

        &:first-of-type {
            @include margin-level (top, XL, true);
        }

        > [class*=col-] {
            @include margin-level (top, 3XL);
        }
    }
}
</style>
