import { app } from './config/app'
import { Layout, HeaderLayout, FooterLayout } from './views/Layout'
import Files from './views/Files'
import { Bio, BioDetail } from './views/Bio'
import { News, NewsDetail } from './views/News'
import Committees from './views/Committees'
import Test from './views/Test/Test.vue'
import DetailPage from './views/DetailPage'
import NotFound from './views/NotFound'
import SiteMap from './views/SiteMap'
import MainPage from './views/MainPage'
import History from './views/History'
import SearchPage from '@/views/SearchPage'
import { SecFilings, SecFilingsDetail } from '@/views/SecFilings'
import { RegNews, RegNewsDetail } from '@/views/RegNews'
import RegFilings from './views/RegFilings'
import Media from './views/Media'
import ReportsAndResults from '@/views/ReportsAndResults'
import investorTools from '@/views/InvestorTools'
import SubscribePage from '@/views/SubscribePage'
import StockTicker from '@/views/StockTicker'
import { Blog, BlogDetail } from './views/Blog'


app.component('header-layout', HeaderLayout)
app.component('footer-layout', FooterLayout)
app.component('layout', Layout)
app.component('files', Files)
app.component('bio', Bio)
app.component('bio-detail', BioDetail)
app.component('news', News)
app.component('news-detail', NewsDetail)
app.component('committees', Committees)
app.component('detail-page', DetailPage)
app.component('test', Test)
app.component('not-found', NotFound)
app.component('site-map', SiteMap)
app.component('main-page', MainPage)
app.component('history', History)
app.component('search-page', SearchPage)
app.component('reports-and-results', ReportsAndResults)
app.component('sec-filings', SecFilings)
app.component('sec-filings-detail', SecFilingsDetail)
app.component('reg-news', RegNews)
app.component('reg-news-detail', RegNewsDetail)
app.component('reg-filings', RegFilings)
app.component('media', Media)
app.component('investor-tools', investorTools)
app.component('subscribe-page', SubscribePage)
app.component('stock-ticker', StockTicker)
app.component('blog', Blog)
app.component('blog-detail', BlogDetail)
