<div
    :class="classObject"
>
    <div
    	class="z-chart__el"
    	ref="chart"
    ></div>
    <div
        class="z-chart__legend"
        ref="legend"
    ></div>
</div>