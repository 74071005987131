<template>
    <div class="z-date" :class="`z-date--${currentTheme}`">
        <div class="z-date__card" v-if="from">
            <span class="z-date__day" v-if="from.day">{{from.day}}</span>
            <div class="z-date__wrapper" v-if="from.month">
                <span class="z-date__other" v-if="from.month">{{from.month}}</span>
                <span class="z-date__other" v-if="from.month">{{from.year}}</span>
            </div>
        </div>
        <z-icon
            class="z-date__separator"
            :name="currentIcon"
            v-if="from !== null && to !== null"
        />
        <div class="z-date__card" v-if="to">
            <span class="z-date__day" v-if="to.day">{{ to.day }}</span>
            <div class="z-date__wrapper">
                <span class="z-date__other">{{ to.month }}</span>
                <span class="z-date__other">{{ to.year }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import {useCurrentIcon} from './composable/useCurrentIcon'
import {useFormattedDate} from './composable/useFormattedDate'
import {useGetter} from '@/composable/useGetter'

const props = defineProps({
    separator: {
        type: String,
        default: 'dash',
        validator: prop => ['dash', 'dot', 'square'].includes(prop)
    },
    from: {
        type: [String, Number],
        required: true
    },
    to: {
        type: [String, Number]
    },
    theme: {
        type: String,
        default: 'primary',
        validator: prop => ['primary', 'light', 'simple'].includes(prop)
    },
    mobileTheme: {
        type: String,
        validator: prop => ['primary', 'light', 'simple'].includes(prop)
    }
})

const { device } = useGetter(['device'])
const currentTheme = computed(() => {
    return props.mobileTheme && device.value === 'mobile' ? props.mobileTheme : props.theme
})

const {currentIcon} = useCurrentIcon(props.icon)

const { from, to } = useFormattedDate(props.from, props.to)
</script>

<style lang="scss">
$fontSizeDay: 50px;
$fontSizeDayTablet: 42px;
$fontSizeDayMobile: 38px;
$weigthDay: 400;
$separatorSpacer: 16px;

// month and year
$fontSizeOther: 20px;
$fontSizeOtherTablet: 18px;
$fontSizeOtherMobile: 16px;
$weightOther: 400;
$lineHeightOther: 1.1;

// color
$fontColor: $token-colors-black-80;
$fontColorSecondary: $token-colors-white;

// colorFillIcon
$iconColor: $token-colors-black-80;
$iconColorSecondary: $token-colors-white;

// simple theme
$fontColorSimple: $token-colors-black-70;
$iconSize: 1em;

.z-date {
    $parent: &;
    display: flex;
    width: 100%;
    max-width: 177px;
    align-items: center;
    gap: $separatorSpacer;

    &__card {
        display: flex;
        align-items: center;
        gap: $token-spacers-3-xs;
    }

    &__day {
        font-weight: $weigthDay;
        font-size: $fontSizeDay;
        line-height: 1;
        color: $fontColor;

        @include breakpoint(tablet) {
            font-size: $fontSizeDayTablet;
        }

        @include breakpoint(mobile) {
            font-size: $fontSizeDayMobile;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__other {
        font-weight: $weightOther;
        font-size: $fontSizeOther;
        line-height: $lineHeightOther;
        color: $fontColor;

        @include breakpoint(tablet) {
            font-size: $fontSizeOtherTablet;
        }

        @include breakpoint(mobile) {
            font-size: $fontSizeOtherMobile;
        }
    }

    .z-icon {
        width: 32px;
        height: 32px;

        path,
        rect,
        circle {
            fill: $iconColor;
        }

        @include breakpoint(tablet) {
            width: 16px;
            height: 16px;
        }
    }

    &--light {
        .z-icon {
            g {
                rect,
                circle {
                    fill: $iconColorSecondary;
                }
            }
        }

        #{$parent}__day,
        #{$parent}__other {
            color: $fontColorSecondary;
        }
    }

    &--simple {
        gap: 0.35em;

        #{$parent}__card {
            gap: 0.35em;
        }

        #{$parent}__day,
        #{$parent}__other {
            font-size: inherit;
            color: $fontColorSimple;
        }

        #{$parent}__wrapper {
            display: inline-flex;
            flex-direction: row;
            gap: 0.35em;
        }

        #{$parent}__separator {
            width: $iconSize;
            height: $iconSize;
        }
    }
}
</style>
