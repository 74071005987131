import { ref } from 'vue'

export const useMenuShowState = (data) => {
    const navIndexOpened = ref(-1)

    const showChilds = (i, item, props) => {
        if (item.hasOwnProperty('items') && item.items.length) {
            navIndexOpened.value === i && props.isMainPage
                ? hideChilds()
                : (navIndexOpened.value = i)
        } else {
            hideChilds()
        }
    }

    const hideChilds = () => {
        navIndexOpened.value = -1
    }

    const show = () => {
        for (let index = 0; index < data.length; index++) {
            const element = data[index]
    
            if (checkActiveState(element)) {
                navIndexOpened.value = index
    
                break
            }
        }
    }

    const checkActiveState = (item) => {
        if (item.active === true) return true

        for (let i = 0; i < item.items?.length; i++) {
            if (item.items[i].active === true) return true
        }

        return false
    }

    return { showChilds, checkActiveState, navIndexOpened, hideChilds, show }
}