<template>
    <div :class="classObject" ref="container">

        <div
            :class="[
                'modal-image__img',
                { 'modal-image__img--wide' : wide }
            ]"
        >
            <img
                ref="img"
                :alt="data.name"
                v-show="imgLoaded"
                :src="data.image.full"
                :style="`max-height: ${maxHeight}px`"
            >
            <z-preloader v-if="!imgLoaded" />
        </div>
        <div class="modal-image__info" v-if="data.name || data.detail">
            <h4
                ref="name"
                v-html="data.name"
                class="modal-image__name"
                v-if="data.name"

            ></h4>
            <div
                ref="detail"
                v-html="data.detail"
                class="modal-image__detail"
            ></div>
        </div>
        <span v-if="download" class="modal-image__download">
            <z-link
                download
                blank
                :href="data.image.full"
                class="ar-card__icon u-inline"
                :content="$t('text.download')"
            >
                <z-icon
                    width="48"
                    height="48"
                    name="download"
                />
            </z-link>
            <p
                v-if="data.size"
                v-html="data.size"
            ></p>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
        offsetTop: {
            type: Number,
            default: 0
        },
        offsetBottom: {
            type: Number,
            default: 0
        },
        download: {
            type: Boolean,
            default: false
        },
        wide: Boolean,
        theme: String
    },
    data () {
        return {
            imgLoaded: false,
            maxHeight: 0
        }
    },
    mounted () {
        this.$nextTick(() => this.setMaxHeight())

        window.addEventListener('resize', this.setMaxHeight)

        this.onLoadImage()
    },
    updated () {
        this.$nextTick(() => this.setMaxHeight())
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.setMaxHeight)
    },
    computed: {
        parentOffset () {
            return this.offsetTop + this.offsetBottom
        },
        classObject () {
            let arrClass = ['modal-image']

            if (this.theme) {
                arrClass.push(`modal-image--theme-${this.theme}`)
            }

            return arrClass
        }
    },
    methods: {
        setMaxHeight () {
            const container = this.$refs.container
            const detail = this.$refs.detail
            const name = this.$refs.name

            if (container) {
                const offsetTop = parseFloat(getComputedStyle(container).paddingTop)
                const offsetBottom = parseFloat(getComputedStyle(container).paddingBottom)
                const detailHeight = detail ? detail.offsetHeight : 0
                const nameHeight = name ? name.offsetHeight : 0
                const viewportHeight = window.innerHeight

                this.maxHeight = viewportHeight - (offsetTop + offsetBottom + detailHeight + nameHeight + this.parentOffset)
            }
        },
        onLoadImage () {
            const img = this.$refs.img

            this.imgLoaded = false

            img.addEventListener('load', () => {
                this.imgLoaded = true
            })
        },
        update () {
            this.$nextTick(() => this.setMaxHeight())
        }
    }
}
</script>

<style lang="scss">
    .modal-image {
        text-align: center;
        width: 1080px;
        max-width: 100%;

        &__name,
        &__detail {
            text-align: left;
        }

        &__name {
            font-size: 28px;
            line-height: 1.2;
            color: $token-colors-black;
            margin: 0 !important;
            padding-bottom: 24px;

        }

        &__info {
            @include padding-level(top, S);
            // @include padding-level(bottom, S);
            @include padding-level(left, S);
            @include padding-level(right, S);
        }

        &__detail {
            font-size: 13px;
            // @include typo-level(S);
            // @include margin-level(bottom, L);
            // line-height: 18px;
            // padding-top: 24px;
        }

        &__img {

            &--wide {
                margin-left: -40px;
                width: calc(100% + 80px);
            }

            img {
                max-width: 100%;
            }
        }

        &__download {
            position: absolute;
            bottom: 48px;
            top: auto;
            left: 48px;
            color: #fff;
            // font-size: 16px;
            font-size: var(--fontSizeM);
            font-weight: 700;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            & p {
                margin-bottom: 0;
                margin-left: 24px;
                margin-top: 5px;
                // font-size: 24px;
                font-size: var(--fontSize2xl);

                @include breakpoint(v-tablet) {
                    // font-size: 20px;
                    // font-size: var(--fontSizeXl);
                    margin-left: 0px;
                }
            }

            @include breakpoint(v-tablet) {
                bottom: 24px;
                left: 24px;

                svg {
                    width: 35px !important;
                    height: 35px !important;
                }
            }
        }

        .z-preloader {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            width: 100%;
            min-width: 320px;
            height: 200px;
        }
    }
</style>
