<template>
    <nav
        class="navigation-mobile"
        aria-label="Main navigation"
        :class="{'navigation-mobile--index': isIndex}"
    >
        <a
            class="navigation-mobile__burger"
            href="javascript:void(0);"
            :class="{'is-active': burgerMenu}"
            @click="toggleDropdown()"
            title=""
        >
            <z-icon
                class="navigation-mobile__burger--open"
                :name="icons.burger"
            />
            <z-icon
                class="navigation-mobile__burger--close"
                :name="icons.close"
            />
        </a>
        <transition
            @before-enter="appearAnimationBefore"
            @enter="animationEnter"
            @leave="animationLeave"
        >
            <div class="navigation-mobile__dropdown" v-if="burgerMenu">
                <navigation-mobile-body :data="mobileDataMenu" :icons="icons"/>
                <slot name="footer"></slot>
                <!-- <navigation-mobile-footer :external-icon="icons.external" :static-links="staticLinks" /> -->
            </div>
        </transition>
    </nav>
</template>

<script>
import Velocity from 'velocity-animate'
import {mapActions, mapGetters} from 'vuex'
import NavigationMobileBody from './components/NavigationMobileBody.vue'
import NavigationMobileFooter from './components/NavigationMobileFooter.vue'

export default {
    name: 'navigation-mobile',
    components: {
        NavigationMobileFooter,
        NavigationMobileBody
    },
    props: {
        isIndex: Boolean,
        staticLinks: {
            type: Array,
            default: null
        },
        icons: {
            type: Object,
            default: () => ({
                burger: 'tools/burger',
                close: 'tools/close',
                external: 'tools/external-2',
                link: 'arrows/caret'
            })
        }
    },
    computed: {
        ...mapGetters(['burgerMenu']),
        mobileDataMenu () {
            return this.$root.app.components.navigation.top.concat(this.$root.app.components.navigation.footer)
        }
    },
    watch: {
        burgerMenu (next) {
            let header = document.querySelector('.header')

            header.classList.toggle('is-open', next && header)
            document.body.classList.toggle('is-disabled', next && header)
            document.body.classList.toggle('mobile-nav-opened', next && header)
        }
    },
    methods: {
        ...mapActions(['ToggleBurgerMenuAction']),
        toggleDropdown () {
            this.ToggleBurgerMenuAction()
        },
        appearAnimationBefore (el) {
            el.style.opacity = 0
        },
        animationEnter (el, done) {
            Velocity(el, { opacity: 1 }, { duration: 200 }, { complete: done })
        },
        animationLeave (el, done) {
            Velocity(el, { opacity: 0 }, { duration: 200 })
            Velocity(el, { display: 'none' }, { complete: done })
        }
    }
}
</script>

<style lang="scss">
@import "vars";

.navigation-mobile {
    $parent: &;
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: $token-spacers-xs;

    a {
        text-decoration: none;
        color: inherit;
    }

    &__burger {
        display: block;
        width: 24px;
        height: 24px;

        .z-icon {
            width: 100%;
            height: 100%;

            path {
                fill: $token-colors-link-default;
            }
        }

        &--open {
            display: block;

            .is-active & {
                display: none;
            }

            #{$parent}--index & {

                path {
                    fill: $token-colors-white;
                }
            }
        }

        &--close {
            display: none;

            .is-active & {
                display: block;
            }
        }
    }

    &__dropdown {
        position: fixed;
        top: 101px; // header height + border
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        background-color: $token-colors-white;
    }
}

.navigation-mobile-link {
    max-width: 100%;
    font-weight: 500;
    @include typo-level(2XL);
    padding: 12px 0;
    color: $nav-link-color;
    cursor: pointer;

    .z-icon {
        width: 24px;
        height: 24px;
        path {
            fill: $token-colors-black-40;
        }
    }

    &.is-active {
        color: $nav-link-color-active;
        .z-icon {
            path {
                fill: $nav-link-color-active;
            }
        }
    }
}
</style>
