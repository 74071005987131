import {clearEmptyParams, queryString} from '@/utils/filterUtils'

export const useSearchHandlers = (fetcher, params, isMore) => {
    const updateQueryParams = () => {
        queryString(clearEmptyParams({
            q: params.value.q,
            currentPage: params.value.page
        }))
    }

    const filterHandler = () => {
        params.value.page = 1
        updateQueryParams()
        fetcher()
    }

    const paginationHandler = (payload) => {
        if (payload.type === 'load-more') {
            isMore.value = true
        }
        params.value.page = payload.page
        updateQueryParams()
        fetcher()
    }

    return {filterHandler, paginationHandler}
}
