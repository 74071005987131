<template>
    <header class="header" :class="{'header--fixed': isFixed}">
        <div class="container">
            <div class="header__container">
                <div class="header__logo">
                    <z-logo site-dir="/" :is-index="isMainPage"/>
                </div>
                <stock-ticker class="hide-me--v-tablet" />
                <div class="header__wrapper">
                    <div class="header__top-menu">
                        <navigation-top class="hide-me--v-tablet" />
                        <site-selector />
                        <a class="header__search" :href="`/${lang}/search/`">
                            <z-icon
                                name="tools/magnifier"
                                width="21"
                                height="21"
                            />
                        </a>
                        <navigation-mobile class="hide-me--default show-me--v-tablet"/>
                    </div>
                </div>
            </div>
            <div class="header__menu-wrapper">
                <div class="header__logo header__logo--fixed">
                    <z-logo site-dir="/" :gap="4" :is-index="isMainPage"/>
                </div>
                <navigation-main class="hide-me--v-tablet" ref="menu" :is-main-page="isMainPage"/>
                <div class="u-flex--row">
                    <a class="header__search header__search--fixed" :href="`/${lang}/search/`">
                        <z-icon
                            name="tools/magnifier"
                            width="21"
                            height="21"
                        />
                    </a>
                    <navigation-mobile />
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, computed } from 'vue'
import NavigationMain from '@/components/NavigationMain'
import NavigationMobile from '@/components/NavigationMobile'
import { useGetter } from '@/composable/useGetter'

const { lang } = useGetter(['lang'])
const isFixed = ref(false)
const menu = ref(null)
const headerHeight = ref(null)

const scrollHandler = () => {
    const scrollPos = window.pageYOffset || document.documentElement.scrollTop
    const header = document.querySelector('.header')
    const stickyLine = header.scrollHeight
    const main = document.querySelector('.main')

    if(scrollPos > stickyLine){
        main.style.paddingTop = headerHeight.value
        isFixed.value = true
        menu.value.hideChilds()
    }else if(isFixed.value){
        menu.value.show()
        isFixed.value = false
        main.style.paddingTop = '0'
    }
}

const isMainPage = computed(() => {
    return window.location.pathname === '/en/' || window.location.pathname === '/ru/'
})

onMounted(() => {
    window.onload = function() {
        headerHeight.value = document.querySelector('.header').offsetHeight + 'px'
        window.addEventListener('scroll', scrollHandler)
    }
})

onBeforeUnmount(() => {
    window.removeEventListener(scrollHandler)
})
</script>

<style lang="scss">
.header {
    $parent: &;
    display: flex;
    align-items: center;
    position: relative;
    background-color: $token-colors-white;
    z-index: 1;

    &:before {
        content: "";
        position: absolute;
        bottom: -20px;
        left: 0;
        display: block;
        width: 100%;
        height: 20px;
        background-image: url(/images/shadow.png);
        background-position: 50%;
        background-repeat: no-repeat;
        z-index: -1;
    }

    &__container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: $token-spacers-s;
        padding-bottom: calc($token-spacers-m - 13px); //отступ элемента меню
        height: 100px;

        @include breakpoint(v-tablet) {
            padding: $token-spacers-xs 0;
        }

        @include breakpoint(mobile) {
            padding: $token-spacers-2-xs 0;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__logo {
        width: 153px;
        height: auto;

        @include breakpoint(v-tablet) {
            width: 129px;
        }

        @include breakpoint(mobile) {
            width: 113px;
        }

        &--fixed {
            display: none;
        }
    }

    &__top-menu {
        display: flex;
        justify-content: flex-end;
    }

    &__search {
        display: inline-block;
        margin-left: $token-spacers-xs;

        &:hover {
            .z-icon {
                path {
                    fill: $token-colors-link-hover;
                }
            }
        }

        &--fixed {
            display: none;
        }

        .z-icon {
            path {
                fill: $token-colors-link-default;
                transition: fill $transition;
            }

            @include breakpoint(v-tablet) {
                width: 24px;
                height: 24px;
            }
        }
    }

    &__top-link {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: $token-colors-black;
        text-decoration: none;

        &:not(:last-child) {
            margin-right: $token-spacers-s;
        }
    }

    &__menu-wrapper {
        .navigation-mobile {
            display: none;


        }
    }

    &__injector {
        // width: 390px;
        // margin: 0 auto;
        // display: grid;
        // grid-template-columns: 60px 1fr;
        // grid-gap: 16px;
        // align-items: center;
        // // transition: box-shadow $transition;
        // // padding: 10px;
        // // border-radius: 10px;
        // text-decoration: none;

        // // &:hover {
        // //     box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
        // // }

        // @include breakpoint(v-tablet) {
        //     display: none;
        // }
    }

    &--fixed {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        opacity: 0;
        animation: navigation 400ms ease-in-out;
        animation-fill-mode: forwards;
        z-index: 10;

        #{$parent}__top-menu {
            display: none;
        }

        #{$parent}__logo {
            display: none;

            &--fixed {
                display: block;

                .z-logo {
                    height: 63px;
                    width: auto;
                }
            }
        }

        #{$parent}__search {
            &--fixed {
                display: block;
            }
        }

        .main-menu__dropdown {
            position: absolute;
            width: 100%;
            top: 100%;
            left: 0;
        }

        .main-menu__list:not(.main-menu__list--lvl-2) {
            .main-menu__list-item {
                padding: 38px 0 29px 0;
            }
        }

        #{$parent}__menu-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include breakpoint (v-tablet) {
                padding: $token-spacers-2-xs 0;
                height: 96px;
            }

            @include breakpoint (mobile) {
                padding: $token-spacers-3-xs 0;
                height: 80px;
            }

            .main-menu {
                margin-left: auto;
                padding-left: $token-spacers-m;
            }

            .navigation-mobile {
                @include breakpoint (v-tablet) {
                    display: block;
                }

                &__dropdown {
                    @include breakpoint(v-tablet) {
                        top: 96px;
                    }

                    @include breakpoint(mobile) {
                        top: 80px;
                    }
                }
            }
        }

        #{$parent}__container {
            display: none;
        }

        .main-menu__dropdown-container {
            padding-right: $token-grids-desktop-offset;
            padding-left: $token-grids-desktop-offset;

            @include breakpoint(laptop) {
                padding-right: $token-grids-laptop-offset;
                padding-left: $token-grids-laptop-offset;
            }

            @include breakpoint(tablet) {
                padding-right: $token-grids-tablet-offset;
                padding-left: $token-grids-tablet-offset;
            }

            @include breakpoint(v-tablet) {
                padding-right: $token-grids-v-tablet-offset;
                padding-left: $token-grids-v-tablet-offset;
            }

            @include breakpoint(mobile) {
                padding-right: $token-grids-mobile-offset;
                padding-left: $token-grids-mobile-offset;
            }
        }

        #{$parent}__logo {
            width: 109px;

            @include breakpoint(tablet) {
                width: 113px;
            }

            @include breakpoint(v-tablet) {
                width: 109px;
            }

            @include breakpoint(mobile) {
                width: 88px;
            }
        }
        @keyframes navigation {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }

    .site-selector {
        margin-left: $token-spacers-xs;
    }
}
</style>
