<template>
    <detail-page
        class="blog-detail"
        v-if="data"
        :date="data.activeFrom"
        dateFormatPattern="DD.MM.YYYY"
        displayDateFormatPattern="DD.MM.YYYY"
        :detail="data.detailText"
        :nav="nav"
        :name="data.name">
        <template v-slot:footer>
            <p
                v-if="data.link.value"
                class="text-size-l u-bold u-top-margin--xl">
                <span v-html="text.readMore.more"></span>
                <z-link
                    :href="data.link.value"
                    target="_blank"
                    external>
                    <span v-html="text.readMore.read"></span>
                </z-link>
            </p>

            <div
                v-if="data.authorFio.value"
                class="blog-detail__author blog-detail__divider">
                <p class="blog-detail__title">{{ text.author }}</p>
                <div class="blog-detail__author-container">
                    <img
                        v-if="data.authorPhoto.link"
                        :src="data.authorPhoto.link"
                        alt=""
                        class="blog-detail__author-photo" />
                    <div class="blog-detail__author-info">
                        <p
                            class="text-size-s u-medium u-bottom-margin--3xs u-line-height-1_3"
                            v-html="data.authorFio.value"></p>
                        <p
                            class="text-size-xs u-line-height-1_4 black-60-text"
                            v-if="data.authorPosition.value"
                            v-html="data.authorPosition.value"></p>
                    </div>
                </div>
            </div>
            <div
                v-if="data.files.length"
                class="blog-detail__files blog-detail__divider">
                <p class="blog-detail__title u-bottom-margin--s-important">{{ text.files }}</p>
                <div class="blog-detail__files-container">
                    <z-filelist
                        v-for="(file, idx) in data.files"
                        :key="idx"
                        class="u-top-margin--xs">
                        <z-filelist-item
                            size="l"
                            icon-size="big"
                            :icon="file.type"
                            :file-size="file.size">
                            <z-link
                                size="s"
                                :underlined="false"
                                :href="file.link"
                                target="_blank">
                                <span v-html="getFileName(file.name)"></span>
                            </z-link>
                        </z-filelist-item>
                    </z-filelist>
                </div>
            </div>

            <div class="blog-detail__links blog-detail__divider">
                <p class="blog-detail__title">{{ text.more }}</p>
                <div class="blog-detail__links-container">
                    <z-link
                        v-if="data.linkedPressRelease[0]"
                        :href="data.linkedPressRelease[0].detailPageUrl">
                        <span v-html="text.pressRelease"></span>
                    </z-link>
                    <z-link
                        href="https://t.me/+aBFNchGgTFJlZGUy"
                        target="_blank">
                        <span v-html="text.telegram"></span>
                    </z-link>
                    <z-link
                        href="https://www.tinkoff.ru/invest/social/profile/Cian_IR/"
                        target="_blank">
                        <span v-html="text.pulse"></span>
                    </z-link>
                </div>
            </div>
        </template>
    </detail-page>
</template>

<script setup>
import { localize } from '@/utils/i18n'
import { useGetter } from '@/composable/useGetter'

const { app } = useGetter(['app'])
const data = app.value.components?.['blog-detail'].item
const nav = app.value.components?.['blog-detail'].nav
const text = {
    readMore: {
        more: localize({
            ru: 'Подробнее об&nbsp;исследовании ',
            en: 'Read more about the study '
        }),
        read: localize({
            ru: 'читайте по&nbsp;ссылке.',
            en: 'at&nbsp;the link.'
        })
    },
    author: localize({
        ru: 'Автор',
        en: 'Author'
    }),
    files: localize({
        ru: 'Прикреплённые файлы',
        en: 'Attached files'
    }),
    file: localize({
        ru: 'Файл',
        en: 'File'
    }),
    more: localize({
        ru: 'Смотрите также:',
        en: 'Read more:'
    }),
    pressRelease: localize({
        ru: 'Полный текст пресс-релиза',
        en: 'Full text of&nbsp;the press release'
    }),
    telegram: localize({
        ru: 'Telegram-канал для инвесторов и&nbsp;аналитиков',
        en: 'Telegram channel for investors and analysts'
    }),
    pulse: localize({
        ru: 'Официальный канал в&nbsp;Пульсе',
        en: 'Official channel in&nbsp;Pulse'
    })
}
const getFileName = (name) => {
    if (!name) {
        return text.file
    }
    return name.substring(0, name.lastIndexOf('.'))
}
</script>
<style lang="scss">
.blog-detail {
    
    &__divider {
        @include padding-level(top, L);
        border-top: 1px solid $token-colors-black-5;
        @include margin-level(top, M);
    }

    &__title {
        @include typo-level(XL);
        @include margin-level(bottom, XS);
        font-weight: 600;
        line-height: 1.2;
    }

    &__author {
        @include padding-level(top, M);
        @include margin-level(top, XL);
    }

    &__author-container {
        display: flex;
        align-items: center;
    }

    &__author-photo {
        width: 80px;
        height: 80px;
        border-radius: 80px;
        overflow: hidden;
        margin-right: 24px;
        object-fit: cover;
        flex-shrink: 0;
    }

    &__author-info {
        max-width: 340px;

        @include breakpoint(v-tablet) {
            max-width: 100%;
        }
    }

    &__links-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: fit-content;

        @include breakpoint(v-tablet) {
            gap: 12px;
        }
    }

    &__links {
        @include margin-level(bottom, XL);
    }
}

.blog-detail .html-content table:has(tbody) {
    overflow-x: auto;
    display: block;

    &::-webkit-scrollbar {
        height: 0.5em;
        border-radius: 0.2em;
    }

    &::-webkit-scrollbar-thumb {
        background: $token-colors-black-30;
    }
}
</style>
