<template>
    <component
        :href="link"
        :class="classObject"
        @click="$emit('click')"
        :is="link ? 'a' : 'div'"
        :target="buildTarget(link)"
    >
        <div class="z-card__content">
            <slot />
        </div>
    </component>
</template>

<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
    link: String,
    fullHeight: Boolean,
    bgColor: {
        type: String,
        default: 'bg-3',
        validator: prop => ['bg-3', 'bg-5', 'bg-10', 'bg-11', 'bg-15', 'bg-20'].includes(prop)
    },
    external: Boolean
})

const buildTarget = (href) => {
    if (props.external) {
        return props.external
    }
    return /^http|^\/\//.test(href) ? '_blank' : false

}

const classObject = computed(() => {
    return [
        'z-card',
        {
            'z-card--link': props.link,
            'z-card--full-height': props.fullHeight,
            [`z-card--with-bg-${props.bgColor}`]: props.bgColor
        }
    ]
})
</script>

<style lang="scss">
.z-card {
    border-radius: 10px;

    &__content {
        padding: 50px 66px;

        @include breakpoint(mobile) {
            padding: $token-spacers-xs $token-spacers-2-xs;
        }
    }

    &--link {
        text-decoration: none;
        display: block;
        background: #F9F9FE !important;
        color: inherit;
        transition: all $transition;

        &:hover {
            box-shadow: -10px 10px 20px rgba(0, 101, 183, 0.1);
        }

        &:active {
            box-shadow: 0 4px 48px rgba(0, 0, 0, 0.1);
        }
    }

    &--full-height {
        height: 100%;
    }

    &--with-bg {
        &-bg-3 {
            background: $token-colors-bg-3;
        }

        &-bg-5 {
            background: $token-colors-bg-5;
        }

        &-bg-10 {
            background: $token-colors-bg-10;
        }

        &-bg-11 {
            background: $token-colors-bg-11;
        }

        &-bg-15 {
            background: $token-colors-bg-15;
        }

        &-bg-15 {
            background: $token-colors-bg-20;
        }
    }
}
</style>
