<template>
    <a
        class="stock-ticker"
        :href="`/${lang}/shareholders/investor-tools/#tabs-1`">
        <iframe
            class="stock-ticker__frame"
            :src="`https://cian-${lang}.injector.3ebra.net/cian/${lang}/`"
            frameborder="0" 
            scrolling="no" />
    </a>
</template>

<script setup>
import { useGetter } from '@/composable/useGetter'

const { lang } = useGetter(['lang'])
</script>

<style lang="scss" scoped>
.stock-ticker {
    display: flex;
    text-decoration: none;
    transition: box-shadow $transition;
    padding: 10px;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    max-width: 390px;

    @include breakpoint (v-tablet) {
        max-height: 70px;
        max-width: 289px;
        margin-left: -10px;
        width: calc(100% + 10px);  
    }

    &:hover {
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    }

    &__frame {
        pointer-events: none;
        height: 100%;
        width: 100%;
    }
}
</style>
