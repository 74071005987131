import { localize } from '@/utils/i18n'

export const text = {
    inputPlaceholder: localize({
        ru: 'Введите свой email',
        en: 'Enter your email'
    }),
    unsubscribe: localize({
        ru: 'Отписаться',
        en: 'Unsubscribe'
    }),
    allRubrics: localize({
        ru: 'Все рубрики',
        en: 'All rubrics'
    }),

    unsubscribeTitle: localize({
        ru: 'Вы&nbsp;отписались от&nbsp;рассылки новостей Циан',
        en: 'You unsubscribed to Cian updates'
    }),
    unsubscribeDescription: localize({
        ru: 'Нам очень жаль расставаться с&nbsp;вами. Надеемся, что вы&nbsp;скоро соскучитесь и&nbsp;вернетесь.',
        en: 'We&nbsp;are very sorry to&nbsp;part with you. We&nbsp;hope you get bored and come back soon.'
    }),

    editSuccessTitle: localize({
        ru: 'Вы&nbsp;успешно изменили подписку',
        en: 'You have successfully changed your subscription'
    }),

    confirmTitle: localize({
        ru: 'Вы&nbsp;успешно подписались на&nbsp;рассылку новостей от Циан',
        en: 'You have successfully subscribed to&nbsp;Cian updates'
    }),
    manageSubscriptionLink: localize({
        ru: 'Управление подпиской',
        en: 'Manage Subscription page'
    }),
    confirmDescription: localize({
        ru: 'Вы можете менять настройки подписки на странице ',
        en: 'You can change the subscription settings on&nbsp;the '
    }),
    subscribe: localize({
        ru: 'Отправить',
        en: 'Subscribe'
    }),
    change: localize({
        ru: 'Изменить',
        en: 'Change'
    }),
    editTitle: localize({
        ru: 'Управление подпиской',
        en: 'Manage subscription'
    }),
    subscribeTitle: localize({
        ru: 'Подписка на&nbsp;новости Циан',
        en: 'Subscribe to Cian updates'
    }),
    defaultError: localize({
        ru: 'Что-то пошло не так, попробуйте позже',
        en: 'Something went wrong, please try again later'
    }),
    langMap: {
        ru: localize({
            ru: 'На русском языке',
            en: 'In Russian'
        }),
        en: localize({
            ru: 'На английском языке',
            en: 'In English'
        }),
        cn: localize({
            ru: 'На китайском языке',
            en: 'in Chinese'
        })
    },
    allMap: {
        ru: 'Все рубрики',
        en: 'All rubrics'
    },
    reconfirmLink: localize({
        ru: 'отправить подтверждение повторно',
        en: 'resend confirmation'
    }),
    chooseLanguage: localize({
        ru: 'Выберете язык рассылки:',
        en: 'Choose language:'
    }),
    chooseLanguageRu: localize({
        ru: 'Русский',
        en: 'Russian'
    }),
    chooseLanguageEn: localize({
        ru: 'Английский',
        en: 'English'
    }),
    backLink: localize({
        ru: 'Пресс-релизы',
        en: 'Press releases'
    }),
    agreeErrorText: localize({
        ru: 'Без вашего согласия мы не сможем сохранить ваш email',
        en: 'We&nbsp;cannot save your email without your consent'
    }),
    agreeText: localize({
        ru: 'Настоящим я&nbsp;подтверждаю, что хочу подписаться на&nbsp;рассылку и&nbsp;получать новости и&nbsp;информацию о&nbsp;Циан.',
        en: 'I&nbsp;confirm my&nbsp;willingness to&nbsp;subscribe to&nbsp;and receive CIAN news and updates.'
    })
}
