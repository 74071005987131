<template>
    <div
        :class="[
            'slide-content',
            { 'slide-content--full-height': fullHeight },
        ]"
        :style="`background-image: url(${image})`"
    >
        <div class="slide-content__wrapper">
            <div
                class="slide-content__overlay"
                :style="`opacity: ${opacity}`"
            ></div>
            <div class="slide-content__title" v-if="title">
                <span v-html="title"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'slide-content',
    props: {
        image: {
            type: String,
            required: true
        },
        opacity: {
            type: String,
            default: '0'
        },
        fullHeight: {
            type: Boolean,
            default: true
        },
        title: String
    },
    data () {
        return {}
    }
}
</script>

<style lang="scss">
.slide-content {
    // min-height: 586px;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;

    &:before {
        content: '';
        display: block;
        position: relative;
        width: 0;
        height: auto;
        padding-top: 58%;

        @include breakpoint (mobile) {
            padding-top: 69%;
        }
    }

    &__overlay {
        &:before {
            content: '';
            position: absolute;
            background-color: $token-colors-black;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    &__title {
        background-color: rgba($token-colors-primary, .8);
        color: $token-colors-white;
        padding: $token-spacers-xs;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: 623px;
        border-radius: 4px;

        @include breakpoint (v-tablet) {
            padding: $token-spacers-2-xs;
            font-size: 14px;
        }
    }

    &--full-height {
        height: 100%;
    }
}
</style>
