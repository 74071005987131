<template>
    <div class="reg-filings">
        <div class="reg-filings__tabs">
            <z-tabs :initial-id="currentTab">
                <template #label>
                    <template
                        v-for="(tab, index) in tabs"
                        :key="`tab-${index}`">
                        <z-tab-label :id="tab.id">{{ tab.text }}</z-tab-label>
                    </template>
                </template>
                <template #content>
                    <template
                        v-for="(tab, index) in tabs"
                        :key="`tab-content-${index}`">
                        <z-tab-content :id="tab.id">
                            <reports-container :requestKey="tab.key"></reports-container>
                        </z-tab-content>
                    </template>
                </template>
            </z-tabs>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onBeforeMount } from 'vue'

import ReportsContainer from './components/ReportsContainer.vue'

const currentTab = ref('')

const tabs = computed(() => [
    { id: 'quarter', text: 'Ежеквартальные отчеты', key: '7' },
    { id: 'annual', text: 'Годовые отчеты', key: '4' },
    { id: 'emission', text: 'Эмиссионные документы', key: '14' },
    { id: 'other', text: 'Прочие документы', key: '8' }
])

onBeforeMount(() => {
    currentTab.value = tabs.value[0]?.id
})
</script>

<style lang="scss">
.reg-filings {
    height: 100%;
    width: 100%;
}
</style>
