<template>
    <div
        :class="[
            'z-date-picker',
            {
                'z-date-picker--filter': isFilter,
                'is-disabled': disabled,
                'is-filled': isFilled,
                'is-errored': isValid === false,
                'is-valid': isValid && !isFilter
            }
        ]"
    >
        <date-picker
            ref="picker"
            :lang="locale"
            v-model:value="date"
            :range="range"
            :type="type"
            @change="change"
            :placeholder="placeholder"
            separator=" — "
            :title-format="format"
            :clearable="isClearable"
            :value-type="valueType"
            :disabled-date="disabledDate"
            :popup-class="`z-date-picker-popup ${range ? 'z-date-picker-popup--range' : '' }`"
        >
        </date-picker>
        <span
            :class="[
                'z-date-picker__error',
                errorClass
            ]"
            v-html="error"
            v-if="error && !isValid"
        ></span>
    </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next'
const ruLangDatePicker = require('vue-datepicker-next/locale/ru')
const enLangDatePicker = require('vue-datepicker-next/locale/en')

export default {
    name: 'z-date-picker',
    components: {
        DatePicker
    },
    emits: [
        'update:modelValue',
        'input',
        'change'
    ],
    props: {
        modelValue: [String, Number, Array],
        range: Boolean,
        disabled: Boolean,
        clearable: {
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            default: false
        },
        isFilter: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'date',
            validator: prop => ['date', 'datetime', 'year', 'month', 'time', 'week'].includes(prop)
        },
        valueType: {
            type: String,
            default: 'X'
        },
        placeholder: {
            type: String,
            default: ''
        },
        format: {
            type: String,
            default: 'DD.MM.YYYY'
        },
        lang: {
            type: String,
            default: 'en'
        },
        disabledDate: {
            type: Function,
            default: () => {}
        },
        errorClass: {
            type: String,
            default: ''
        },
        showValidState: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            isValid: null,
            // date: this.value,
            localValue: '',
            error: '',
            text: {
                error: {
                    ru: 'Поле обязательно для заполнения',
                    en: 'Required field',
                    cn: '填项目'
                }
            },
            locale: this.$root.app.page.lang === 'en' ? enLangDatePicker : ruLangDatePicker
        }
    },
    computed: {
        isFilled () {
            if (Array.isArray(this.date)) {
                return Boolean(this.date[0] && this.date[1])
            }

            return Boolean(this.date)
        },
        isClearable () {
            if (this.isFilter) return true
            return this.clearable
        },
        date: {
            get() {
                if (this.modelValue !== undefined) return this.modelValue // для работы без v-model
                return this.localValue
            },
            set(value) {
                this.localValue = value
                this.$emit('update:modelValue', value)
            }
        }
    },
    methods: {
        change (data) {
            let result = data
            if (Array.isArray(data)) {
                const newValue = String(+data[1] + ((23 * 60 + 59) * 60 + 59))
                data.splice(1, 1, newValue)
            }
            if (Array.isArray(data) && !(data[0] && data[1])) {
                result = undefined
            }

            this.$emit('input', result)
            this.$emit('change', result)
            this.validate()
        },
        validate () {
            if (this.required && !this.isFilled) {
                this.isValid = false
                this.error = this.$root.localize(this.text.error)
                return
            }

            this.isValid = true
            this.error = ''
        }
    }
}
</script>

<style lang="scss">

.z-date-picker {
    font-size: var(--textSize);

    &__error {
        font-size: var(--errorTextSize);
        color: var(--errorTextColor);
        position: relative;
        left: 16px;
    }

    .mx-datepicker {
        width: 100%;
    }

    .mx-icon-calendar {
        display: block !important;
        transition: background 0.2s ease-in;
        width: var(--iconSize);
        height: var(--iconSize);
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
        background-image: svg-load('calendar/calendar-3.svg', fill=$customIconColor);
        right: var(--iconXSpacer);
        margin-top: 0;

        svg {
            display: none;
        }
    }

    // filled state
    &.is-filled {
        .mx-input {
            border-color: var(--formBorderColorFilled);
            border-top-color: var(--formBorderTopColorFilled);
            border-left-color: var(--formBorderLeftColorFilled);
            border-right-color: var(--formBorderRightColorFilled);
        }
    }

    .mx-input {
        border-width: var(--formBorderWidth);
        border-style: solid;
        border-color: var(--formBorderColorDefault);
        border-top-color: var(--formBorderTopColorDefault);
        border-left-color: var(--formBorderLeftColorDefault);
        border-right-color: var(--formBorderRightColorDefault);
        border-radius: var(--borderRadius);
        box-shadow: none;
        padding: var(--fieldPaddingM);
        padding-right: 70px;
        box-sizing: border-box;
        font-size: 1em;
        font-weight: 400;
        height: auto;
        transition: border 0.2s ease-in;
        line-height: 1.5;
        text-overflow: ellipsis;
        height: var(--fieldHeightM);
        color: var(--formTextColorDefault);

        &:hover {
            border-color: var(--formBorderColorHover);
            border-top-color: var(--formBorderTopColorHover);
            border-left-color: var(--formBorderLeftColorHover);
            border-right-color: var(--formBorderRightColorHover);
        }

        &:focus {
            border-color: var(--formBorderColorFocus);
            border-top-color: var(--formBorderTopColorFocus);
            border-left-color: var(--formBorderLeftColorFocus);
            border-right-color: var(--formBorderRightColorFocus);

            // + .mx-icon-clear {
            //     color: var(--formBorderColorFocus);
            // }
        }
    }

    .mx-icon-clear {
        display: block;
        right: calc(var(--iconSize) + var(--iconXSpacer) + 4px);
        font-size: var(--iconSize);
        transition: color 0.2s ease-in;
        margin-top: 0;
        color: var(--formBorderColorDefault);

        &:hover {
            color: var(--formBorderColorHover);
        }
    }

    // valid state
    &.is-valid {
        .mx-input {
            border-color: var(--formBorderColorValid);
            border-top-color: var(--formBorderTopColorValid);
            border-left-color: var(--formBorderLeftColorValid);
            border-right-color: var(--formBorderRightColorValid);
            color: var(--formTextColorDefault);
        }
    }

    // errored state
    &.is-errored {
        .mx-input {
            border-color: var(--formBorderColorError);
            border-top-color: var(--formBorderTopColorError);
            border-left-color: var(--formBorderLeftColorError);
            border-right-color: var(--formBorderRightColorError);
        }
    }

    &--filter {
        // filled state
        &.is-filled {
            .mx-input {
                border-color: var(--formBorderColorFilledAccent);
                border-top-color: var(--formBorderTopColorFilledAccent);
                border-left-color: var(--formBorderLeftColorFilledAccent);
                border-right-color: var(--formBorderRightColorFilledAccent);
            }

            .mx-input {
                border-color: var(--formBorderColorFilledAccent);
                border-top-color: var(--formBorderTopColorFilledAccent);
                border-left-color: var(--formBorderLeftColorFilledAccent);
                border-right-color: var(--formBorderRightColorFilledAccent);
            }

            .mx-icon-calendar {
                background-image: svg-load('calendar/calendar-3.svg', fill=$customIconColorFilledAccent);
            }
        }
    }
}

.z-date-picker-popup {
    font-size: var(--textSize);

    .mx-btn-text {
        font-weight: inherit;
        color: inherit;
        font-size: inherit;
        text-transform: capitalize;
        font-size: 22px;
        font-weight: 400;
        background: transparent;
        color: var(--formPlaceholderColorFilledAccent);

        @include breakpoint(mobile) {
            font-size: 18px;
        }
    }

    .mx-calendar {
        padding: 24px 32px;
        width: 300px;
        @include breakpoint(mobile) {
            padding: 16px;

            &:nth-of-type(2) {
                display: none;
            }
        }

        &-decade-separator {
            &:after {
                content: " — ";
                display: inline-block;
                transform: translateY(-0.1em);
            }
        }

        &-content {
            .cell.in-range,
            .cell.hover-in-range {
                background-color: transparent;
            }

            .cell:hover {
                background: transparent;
            }
        }
    }

    .mx-calendar-header {
        padding: 0;
        margin-bottom: 24px;
        height: auto;
        line-height: 1;

        @include breakpoint(mobile) {
            margin-bottom: 16px;
        }
    }

    .mx-calendar-header-label {
        color: #000;
        font-size: 22px;
    }

    .mx-calendar-content {
        height: auto;
        overflow: hidden;
    }

    .mx-icon-left:before,
    .mx-icon-right:before,
    .mx-icon-double-left:before,
    .mx-icon-double-right:before,
    .mx-icon-double-left:after,
    .mx-icon-double-right:after {
        width: 16px;
        height: 16px;
        color: #B2B2B2;
        border-width: 3px 0 0 3px;

        @include breakpoint(mobile) {
            width: 14px;
            height: 14px;
        }
    }

    .cell {
        color: var(--formTextColorDefault) !important;
        font-weight: 400;
        font-size: 13px;
        line-height: 1;
        padding: 2px 0;

        div {
            margin: 0;
            min-width: 32px;
            min-height: 32px;
            line-height: 32px;
            transition: background 0.2s ease-in;
            max-width: 100%;
            max-height: 100%;
            border-radius: 2px;
        }

        &:hover {
            background: transparent !important;

            div {
                box-shadow: inset 0 0 0 var(--formBorderWidth) var(--formBorderColorFilledAccent);
            }
        }

        &.active:not(.not-current-month) {
            background: transparent;

            div {
                background: var(--formBgColor50);
            }
        }

        &.today {
            div {
                background: var(--formBorderColorFilledAccent);
                color: $token-colors-white;
            }
        }

        &.in-range:not(.not-current-month),
        &.hover-in-range:not(.not-current-month) {
            div {
                border-radius: 0;
                color: $token-colors-black;
                background-color: var(--formBgColor10);
            }

            + .active {
                div {
                    background-color: var(--formBgColor1    0);
                    box-shadow: inset 0 0 0 var(--formBorderWidth) var(--formBorderColorFilledAccent);
                    color: $token-colors-black !important;
                }
            }
        }

        &.not-current-month {
            color: var(--formTextColorDisabled) !important;
            pointer-events: none;
        }
    }

    .mx-btn-current-year {
        color: $token-colors-link-default;

        &:hover {
            color: $token-colors-link-hover;
        }
    }

    .mx-btn-current-month {
        color: $token-colors-link-dark-default;

        &:hover {
            color: $token-colors-link-dark-hover;
        }
    }

    .mx-btn-current-month,
    .mx-btn-current-year {
        font-size: 22px;
        line-height: 1.2;
        transition: color .2s ease-in;

        &:hover {
            background: transparent;
        }
    }

    .mx-table {
        margin: 0;

        th {
            color: #000;
            font-weight: 400;
            font-size: 13px;
            text-align: center;
        }

        td {
            text-align: center;
        }
    }

    /* nav arrows */
    .mx-btn-icon-right,
    .mx-btn-icon-double-right,
    .mx-btn-icon-left,
    .mx-btn-icon-double-left {
        &:active,
        &:focus {
            background-color: transparent;

            .mx-icon-right,
            .mx-icon-double-right,
            .mx-icon-left,
            .mx-icon-double-left {
                &:before,
                &:after {
                    color: #B2B2B2;
                }
            }
        }

        &:hover {
            background: transparent;

            .mx-icon-right,
            .mx-icon-double-right,
            .mx-icon-left,
            .mx-icon-double-left {
                &:before,
                &:after {
                    color: var(--formBorderColorHover);
                }
            }
        }
    }
    /* /nav arrows */

    /* month, year */
    .mx-table-month {
        margin: 0;
        border-spacing: 50px 16px;
    }

    .mx-table-year {
        border-spacing: 70px 16px;
    }

    .mx-table-month,
    .mx-table-year {
        margin: 0;
        border-collapse: separate;
        width: calc(100% + 100px);
        margin-left: -50px;
        margin-top: -16px;

        .cell {
            font-size: 1em;

            div {
                font-size: 1.125em;
            }

            &.active {
                background: transparent;
            }

            &.in-range,
            &.hover-in-range {
                div {
                    border-radius: 0;
                    color: var(--formTextColorDefault);
                }

                + .active {
                    div {
                        color: var(--formTextColorDefault);
                        box-shadow: inset 0 0 0 var(--formBorderWidth) var(--formBorderColorHover);
                    }
                }
            }
        }

    }
    /* /month, year */

    &--range {
        .cell {
            &.active:not(.not-current-month) {
                background: transparent;

                div {
                    background: var(--formBgColor50);
                }
            }
        }
    }
}

</style>
