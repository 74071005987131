<template>
    <div class="reports-and-results" v-if="data">
        <z-tabs :initial-id="currentTab" v-if="tabs.length">
            <template #label>
                <template v-for="(tab, index) in tabs" :key="`tab-${index}`">
                    <z-tab-label :id="tab.name">{{tab.name}}</z-tab-label>
                </template>
            </template>
            <template #content>
                <z-tab-content v-for="(tab, index) in tabs" :key="`tab-content-${index}`" :id="tab.name">
                    <results-archive v-if="tab.name === 'Archive'" :data="tab.items" />
                    <results v-else :data="tab.items" />
                </z-tab-content>
            </template>
        </z-tabs>
    </div>
</template>

<script setup>
import { useGetter } from '@/composable/useGetter'
import { useComputedData } from './composable/useComputedData'
import { ref, onBeforeMount } from 'vue'
import Results from './components/Results.vue'
import ResultsArchive from './components/ResultsArchive.vue'

const props = defineProps({
    archiveCount: {
        type: Number,
        default: 5
    }
})
const currentTab = ref('')
const { app, device } = useGetter(['app', 'device'])
const data = app.value.components?.['reports-and-results']
const { tabs } = useComputedData(data, props, device)

onBeforeMount (() => {
    currentTab.value = tabs.value[0]?.name
})
</script>

<style lang="scss">
.reports-and-results {
    &__container {
        position: relative;
    }

    &__preloader {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($token-colors-white, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }

    &__header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        @include margin-level(bottom, M);

        @include breakpoint (v-tablet) {
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: flex-start;
        }
    }

    &__databook {
        margin-left: auto;
        @include padding-level(left, XL)
    }

    .z-tabs {
        .z-tabs__header {
            align-items: flex-end;
        }

        .z-tabs-label {
            height: auto;
        }
    }
}
</style>