<template>
    <div
        class="z-table z-table--scrollable"
        :class="{
            [`z-table--centered`]: contentCentered,
            [`z-table--with-merged-rows`]: rowsMerged
        }"
    >
        <div class="z-table__container">
            <table>
                <colgroup v-if="colgroup && colgroup.length && this.device !== 'mobile'">
                    <col
                        v-for="(item, index) in colgroup"
                        :key="index"
                        span="1"
                        :style="`width: ${item};`"
                    >
                </colgroup>
                <thead>
                    <slot name="head"/>
                </thead>
                <tbody>
                    <slot />
                </tbody>
            </table>
        </div>
        <div class="z-table__footer" v-if="hasAddSlotData">
            <slot name="add" />
        </div>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'z-table',
    mixins: [mixinDevice],
    props: {
        responsive: Boolean,
        scrollable: Boolean,
        native: Boolean, // for default auto width column
        contentCentered: Boolean,
        rowsMerged: Boolean,
        colgroup: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        hasAddSlotData () {
            return this.$slots.add
        }
    }

}
</script>

<style lang="scss" src="./index.scss"></style>
