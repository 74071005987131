import {computed} from 'vue'
import {useGetter} from '@/composable/useGetter'

export const useCurrentIcon = (icon) => {
    const { device } = useGetter(['device'])

    const currentIcon = computed(() => {
        switch (icon) {
        case ('dash'): {
            return checkDevice() ? 'list/dash' : 'list/dash_big'
        }
        case ('dot'): {
            return checkDevice() ? 'list/dot' : 'list/dot-big'
        }
        case ('square'): {
            return checkDevice() ? 'list/square' : 'list/square-big'
        }
        default: {
            return 'list/dash'
        }
        }
    })

    const checkDevice = () => {
        return ['tablet', 'mobile', 'v-tablet'].includes(device.value)
    }

    return {currentIcon}
}
