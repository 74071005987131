<template>
    <component
        :is="isTh ? 'th' : 'td'"
        class="z-table-col"
        :class="columnClass"

    >
        <template v-if="isTh">
            <span>
                <slot/>
            </span>
        </template>
        <template v-else>
            <span class="z-table-col__content">
                <slot/>
            </span>
        </template>
    </component>
</template>

<script>
import {mixinDevice} from '@/utils/mixin'

export default {
    name: 'z-table-col',
    mixins: [mixinDevice],
    props: {
        index: {
            type: [String, Number]
        },
        isTh: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        columnClass () {
            return {
                'z-table-col--head': this.isTh,
                'z-table-col--body': !this.isTh
            }
        }
    }
}
</script>
