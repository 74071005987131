<template>
    <div class="media" v-if="data">
        <div class="media__filters">
            <z-select :placeholder="theme" is-filter v-model="params.category" :options="categories" @change="filterHandler"/>
            <z-date-picker ref="datepicker" is-filter :placeholder="date" v-model="dateRange" range @change="dateHandler" :lang="lang"/>
        </div>
        <div class="media__content">
            <template v-if="items.length">
                <template v-for="(item, index) in items" :key="`event-${index}`">
                    <div v-if="item.source.description" :class="{'media__main-card': index === 0 && firstPage}">
                        <news-card tag="a"
                                   :href="item.source.description"
                                   :title="item.name"
                                   :tags="[item.category.value]"
                                   :image="getImage(item)"
                                   :date="item.activeFrom"
                                   :source="item.source.value"
                                   :date-filter="dateFilter"
                                   external
                        />
                    </div>
                </template>
                <z-preloader v-if="isLoading"/>
                <z-pagination v-if="adaptedNav" :data="adaptedNav" @change-page="paginationHandler"/>
            </template>
            <z-not-found v-else/>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import {useGetData} from '@/composable/useGetData'
import {useGetter} from '@/composable/useGetter'
import {useMediaHandlers} from '@/views/Media/composable/useMediaHandlers'
import {getMedia} from '@/api/media'
import {useMediaComputedData} from '@/views/Media/composable/useMediaComputedData'
import {localize} from '@/utils/i18n'

const dateRange = ref([])

const params = ref({
    category: '',
    from: '',
    to: '',
    page: 1
})

const dateFilter = localize({
    ru: 'DD MMMM YYYY',
    en: 'MMMM DD, YYYY'
})
const lang = localize({
    ru: 'ru',
    en: 'en'
})
const theme = localize({
    ru: 'Категория',
    en: 'Category'
})

const date = localize({
    ru: 'Дата',
    en: 'Date'
})
const { app } = useGetter(['app'])
const appData = app.value.components?.media
const isMore = ref(false)
const firstPage = ref(true)

const {isLoading, data, fetcher} = useGetData(getMedia, params, appData)

const {paginationHandler, filterHandler, dateHandler} = useMediaHandlers(params, fetcher, isMore, dateRange, firstPage)
const {categories, items, adaptedNav} = useMediaComputedData(data, isMore)

const getImage = (item) => {
    if (item.previewPicture.link) return item.previewPicture.link
    return `/images/media/${item.category.xmlId.toLowerCase()}.jpg`
}

onMounted(() => {
    const searchParams = new URL(document.location).searchParams
    searchParams.get('category') && (params.category = searchParams.get('category'))
    searchParams.get('from') && (params.from = searchParams.get('from'))
    searchParams.get('to') && (params.to = searchParams.get('to'))
    searchParams.get('to') && searchParams.get('from') && (dateRange.value = [searchParams.get('from'), searchParams.get('to')])
})
</script>

<style lang="scss">
.media {
    &__filters {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: $token-spacers-s;
        margin-bottom: $token-spacers-l;

        @include breakpoint(v-tablet) {
            grid-template-columns: 1fr;
        }
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 30px;
        grid-row-gap: $token-spacers-l;

        @include breakpoint(v-tablet) {
            grid-template-columns: 1fr 1fr;
        }

        @include breakpoint(mobile) {
            grid-template-columns: 1fr;
        }
    }

    .z-pagination {
        grid-column: 1 / -1;
        @include margin-level(top, XS);
    }

    .z-preloader {
        grid-column: 1 / -1;
    }

    .z-not-found {
        grid-column: 1 / -1;
    }

    &__card {
        margin: -50px -66px;
        display: flex;
        flex-direction: column;
        position: relative;

        @include breakpoint(mobile) {
            margin: -$token-spacers-xs -$token-spacers-2-xs;
        }

        .z-tag {
            position: absolute;
            top: 24px;
            left: 24px;
        }
    }

    &__main-card {
        grid-column: span 2;

        @include breakpoint(mobile) {
            grid-column: 1 / -1;
        }

        .news-card__image-wrapper {
            padding-top: 58.40%
        }

        .news-card__title {
            font-size: 20px;
        }
    }
}
</style>
