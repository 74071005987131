<template>
    <div class="z-input-file">
        <label
            :for="name"
            class="z-input-file__label"
            :class="{
                'is-disabled': disabled,
                'is-errored': !error,
            }"
        >
            <input
                :id="name"
                type="file"
                ref="file"
                :name="name"
                :disabled="disabled"
                @change="onChange($event.target)"
                :multiple="multiple"
            />
            <z-icon
                name="tools/paperclip"
                width="16"
                height="16"
                class="z-input-file__input-icon"
            />
            <span v-html="text.label"></span>
        </label>

        <div class="z-input-file__filelist" v-if="files.length">
            <div
                class="z-input-file__filelist-item"
                v-for="(file, index) in files"
                :key="index"
            >
                <z-filelist-item
                    size="l"
                    icon-size="small"
                    :icon="getFormatedType(file.name)"
                    centered
                    :file-size="getFormatedSize(file.size)"
                >
                    <span class="z-input-file__file-name" v-html="getFormatedName(file.name)"></span>
                </z-filelist-item>
                <button class="z-input-file__remove-btn">
                    <z-icon
                        name="tools/close-small"
                        width="16"
                        height="16"
                        @click="remove(file)"
                    />
                </button>
            </div>
        </div>
        <span
            :class="[
                'z-input-file__error',
                errorClass
            ]"
            v-html="error"
            v-if="error"
        ></span>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n.js'

export default {
    inheritAttrs: false,
    name: 'z-input-file',
    props: {
        name: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        accept: String,
        errorClass: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            files: [],
            uploaded: [],
            error: '',
            text: {
                label: localize({
                    ru: 'Прикрепить файлы',
                    en: 'Attach files'
                }),
                errors: {
                    required: localize({
                        ru: 'Поле обязательно для заполнения',
                        en: 'Required field',
                        cn: '填项目'
                    })
                }
            }
        }
    },
    methods: {
        onChange (target) {
            this.updateList(target.files)
            this.uploaded = target.files
            this.$nextTick(() => this.$emit('change', this.uploaded))
            this.validate()
        },
        clear () {
            this.files = []
            this.uploaded = []
            this.$refs.file.value = null
            this.$nextTick(() => this.$emit('change', this.uploaded))
            this.validate()
        },
        updateList (data) {
            this.files = Array.from(data).map(item => {
                const re = /(?:\.([^.]+))?$/

                return {
                    name: item.name,
                    size: item.size,
                    type: re.exec(item.name)[1]
                }
            })
        },
        remove (file) {
            let dt = new DataTransfer()
            Array.from(this.$refs.file.files).forEach(item => {
                if (file.name !== item.name) {
                    dt.items.add(item)
                }
            })

            if (dt.files.length === 0) {
                this.clear()
                return
            }

            this.$refs.file.files = dt.files
            this.uploaded = dt.files
            this.updateList(this.$refs.file.files)
            this.$nextTick(() => this.$emit('change', this.uploaded))
            this.validate()
        },
        getFormatedSize (size) {
            let result = size / 1024
            if (result >= 1024) {
                return `${(result / 1024).toFixed(1)} Mb`
            }

            return `${result.toFixed(1)} Kb`
        },
        getFormatedName (str) {
            const index = str.lastIndexOf('.')
            return str.slice(0, index)
        },
        getFormatedType (str) {
            const index = str.lastIndexOf('.')
            return str.slice(index + 1)
        },
        validate () {
            if (this.required && this.uploaded.length === 0) {
                this.error = this.text.errors.required
                return
            }

            this.error = ''
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
