import {computed, watch, ref} from 'vue'

export const useMediaComputedData = (data, isMore) => {
    const items = ref(data.value.items || [])
    const categories = computed(() => data.value.filter.category || [])

    watch(data, () => {
        if (!data.value) {
            items.value = []
            return
        }
        if (isMore.value) {
            isMore.value = false
            items.value = [...items.value, ...data.value.items]
        } else {
            items.value = data.value.items
        }
    })

    const nav = computed(() => data.value.nav || null)

    const adaptedNav = computed(() => {
        if (!nav.value) {
            return {
                currentPage: 0,
                totalItems: 0,
                totalPages: 0,
                pageRange: 1
            }
        }

        return {
            currentPage: nav.value.current,
            totalItems: nav.value.count,
            totalPages: nav.value.total,
            pageRange: 1
        }
    })

    return {categories, items, adaptedNav}
}
