import {ref} from 'vue'
import {debounce} from 'throttle-debounce'

export const useGetData = (api, params, appData) => {
    const isLoading = ref(false)
    const errorMessage = ref('')
    const data = ref(appData)

    const fetcher = debounce(300, async () => {
        isLoading.value = true
        try {
            data.value = await api(params.value)
        }
        catch {
            errorMessage.value = 'Произошла ошибка попробуйте еще раз'
            // data.value = null
        }
        finally {
            isLoading.value = false
        }
    })

    return {data, fetcher, isLoading, errorMessage}
}
