<template>
    <div :class="classObject" ref="tooltip">
        <template v-if="!$slots.trigger">
            <slot />
            <z-icon
                v-show="!iconHidden"
                width="12"
                height="12"
                name="tooltip"
                dir="down"
                class="z-tooltip__icon"
            />
        </template>
        <div :class="{'z-tooltip--is-opened': isOpened}" v-else>
            <slot name="trigger"></slot>
        </div>
    </div>
    <div ref="content" v-if="!!$slots.content || content">
        <slot name="content"></slot>
        <span v-html="content"></span>
    </div>
</template>

<script>
import tippy from 'tippy.js'

export default {
    name: 'z-tooltip',
    props: {
        arrow: {
            type: Boolean,
            default: true
        },
        placement: {
            type: String,
            default: 'right'
        },
        content: {
            type: String
        },
        trigger: {
            type: String,
            default: 'mouseenter'
        },
        interactive: {
            type: Boolean,
            default: false
        },
        iconHidden: Boolean,
        theme: {
            type: String,
            default: 'dark-mode',
            validator: prop => ['dark-mode', 'light-mode'].includes(prop)
        },
        appendTo: {
            type: [String, HTMLElement],
            default: () => document.body
        }
    },
    data () {
        return {
            isOpened: false
        }
    },
    computed: {
        classObject () {
            return [
                'z-tooltip',
                { 'z-tooltip--click': this.trigger === 'click' },
                {'z-tooltip--is-opened': this.isOpened}
            ]
        }
    },
    mounted () {
        const self = this
        tippy(this.$refs.tooltip, {
            content: this.$refs.content,
            placement: this.placement,
            arrow: this.arrow,
            trigger: this.trigger,
            allowHTML: true,
            interactive: this.interactive,
            theme: this.theme,
            appendTo: this.appendTo,
            onShow () {
                self.isOpened = true
            },
            onHide () {
                self.isOpened = false
            },
            popperOptions: { // решает проблему позиционирования тултипа, при нехватке места, указанного в пропсе placement
                modifiers: [
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: ['right', 'left', 'bottom', 'top'],
                        }
                    }
                ]
            }

        })
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
