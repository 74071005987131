export const useHandlers = (params, isMore, fetcher) => {
    const onPageChange = (data) => {
        isMore.value = data.type === 'load-more'
        params.value.page = data.page
        fetcher()
    }
    
    const onUpdateParams = data => {
        params.value = { ...params.value, ...data }
    }

    const onFilterChange = (data) => {
        params.value = { ...params.value, ...data }
        fetcher()
    }

    return { onPageChange, onUpdateParams, onFilterChange }
}