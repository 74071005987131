<template>
    <div class="mainpage">
        <section class="mainpage__section mainpage__hero container">
            <div class="mainpage__section-wrapper">
                <div class="row">
                    <div class="col-default-8 col-v-tablet-10 col-mobile-12">
                        <z-caption
                            tag="h2"
                            size="4xl"
                            class="mainpage__hero-title"
                            v-html="text.hero.title">
                        </z-caption>
                        <z-caption
                            tag="p"
                            size="xl"
                            class="mainpage__hero-description"
                            v-html="text.hero.caption">
                        </z-caption>
                        <!-- <z-filelist class="mainpage__hero-file">
                            <z-filelist-item size="l" icon-size="big" icon="pdf" :file-size="file.size">
                                <z-link
                                    size="s"
                                    :underlined="false"
                                    :href="file.link"
                                    v-html="text.hero.fileLink"
                                    target="_blank"
                                ></z-link>
                            </z-filelist-item>
                        </z-filelist> -->
                        <div class="mainpage__hero-buttons">
                            <z-button
                                tag="a"
                                :href="`/${lang}/company/at-a-glance/`"
                                v-html="text.hero.button"
                            ></z-button>
                            <z-button
                                tag="a"
                                href="https://t.me/+aBFNchGgTFJlZGUy"
                                v-html="text.hero.telegram"
                                target="_blank"
                            ></z-button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="mainpage__section mainpage__section-bg container">
            <div class="mainpage__section-wrapper">
                <div class="row">
                    <div class="col-default-3 col-v-tablet-6 col-mobile-12">
                        <z-caption
                            tag="h3"
                            size="3xl"
                            :href="`/${lang}/financials/reports-and-presentations/`"
                            class="u-collapse--all"
                        >
                            <span v-html="text.reports"></span>
                        </z-caption>
                        <span class="text-size-2xl u-block u-top-margin--3xs u-bottom-margin--m-important" v-html="text.reportsDate"></span>
                        <div class="reports-list">
                            <div class="reports-list__item">
                                <z-icon name="tools/press-release-big" class="reports-list__icon"></z-icon>
                                <div class="reports-list__info">
                                    <z-link
                                        class="reports-list__link"
                                        :underlined="false"
                                        :href="lang !== 'ru' ? '/upload/iblock/69c/x15l1ia7ke9f7ijaasrsmwgufgxcwjkr/Cian_Q2_2024_financial_results.pdf' : '/upload/iblock/6ad/wq79cl8taseez3d141v4zzqce4dgbyok/Cian_Q2_2024_financial_results_RUS.pdf'"
                                        target="_blank"
                                        v-html="text.pressRelease"
                                    >
                                    </z-link>
                                    <div class="reports-list__filesize">680 Kb</div>
                                </div>
                            </div>
                            <!--<div class="reports-list__item">
                                <z-icon name="tools/report-big" class="reports-list__icon"></z-icon>
                                <div class="reports-list__info">
                                    <z-link class="reports-list__link" :underlined="false" target="_blank" href="https://ir.ciangroup.ru/en/financials/sec-filings/0001104659-23-120048/" v-html="text.secForm"></z-link>
                                    <div class="reports-list__filesize">189 Kb</div>
                                </div>
                            </div>-->
                        </div>
                    </div>
                    <div class="col-default-3 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                        <z-caption
                            tag="h3"
                            size="3xl"
                            :href="`/${lang}/press-center/press-releases/`"
                            class="u-bottom-margin--m-important"
                        >
                            <span v-html="text.press"></span>
                        </z-caption>
                        <latest-news></latest-news>
                    </div>
                    <!-- <div class="col-default-3 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                        <z-caption
                            tag="h3"
                            size="3xl"
                            :href="`/${lang}/company/our-business/`"
                            class="u-bottom-margin--m-important"
                        >
                            <span v-html="text.business.title"></span>
                        </z-caption>
                        <p v-html="text.business.description"></p>
                    </div> -->
                </div>
            </div>
        </section>
        <section class="mainpage__section mainpage__section--key-figures">
            <div class="container">
                <div class="mainpage__section-wrapper">
                    <z-caption
                        tag="h3"
                        size="3xl"
                        :href="`/${lang}/company/at-a-glance/`"
                        class="u-collapse--all"
                    >
                        <span v-html="text.keyFigures"></span>
                    </z-caption>
                    <span class="text-size-2xl u-block u-top-margin--3xs u-bottom-margin--m-important" v-html="text.keyFiguresDate"></span>
                    <slot name="key-figures"></slot>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
import { localize } from '@/utils/i18n'
import { useGetter } from '@/composable/useGetter'
import LatestNews from './components/LatestNews.vue'

const { lang } = useGetter(['lang'])

const text = {
    hero: {
        title: localize({
            ru: 'Циан&nbsp;&mdash; ведущая онлайн-платформа объявлений о&nbsp;недвижимости в&nbsp;России',
            en: 'Cian&nbsp;&mdash; a&nbsp;leading online real estate classifieds platform in&nbsp;Russia'
        }),
        telegram: localize({
            ru: 'Telegram-канал',
            en: 'Telegram channel'
        }),
        caption: localize({
            ru: 'Мы&nbsp;поддерживаем людей в&nbsp;поисках нового жилья с&nbsp;помощью знаний и&nbsp;технологий в&nbsp;области недвижимости',
            en: 'We&nbsp;support people on&nbsp;their journey to&nbsp;a&nbsp;new home through real estate insights and technology'
        }),
        button: localize({
            ru: 'Профиль компании',
            en: 'At a glance'
        }),
        fileLink: localize({
            ru: '3Q 2021 Update Presentation',
            en: '3Q 2021 Update Presentation'
        })
    },
    reports: localize({
        ru: 'Квартальные отчеты',
        en: 'Quarterly Reports'
    }),
    reportsDate: localize({
        ru: '2 кв. 2024 г.',
        en: 'Q2 2024'
    }),
    press: localize({
        ru: 'Пресс-центр',
        en: 'Press Center'
    }),
    business: {
        title: localize({
            ru: 'test',
            en: 'Our business'
        }),
        description: localize({
            ru: 'test',
            en: 'We&nbsp;are a&nbsp;technology-driven company and are committed to&nbsp;delivering the most efficient and stress-free experience through the use of&nbsp;cutting-edge technology.'
        })
    },
    keyFigures: localize({
        ru: 'Ключевые показатели',
        en: 'Key figures'
    }),
    keyFiguresDate: localize({
        ru: '2023 г.',
        en: '2023'
    }),
    pressRelease: localize({
        ru: 'Пресс-релиз',
        en: 'Press release'
    }),
    secForm: localize({
        ru: 'Форма SEC (en)',
        en: 'SEC form'
    })
}

// const file = {
//     link: localize({
//         ru: 'test',
//         en: '/files/en/O2-3Q-Update-Presentation.pdf'
//     }),
//     size: localize({
//         ru: 'test',
//         en: '2.5 Mb'
//     })
// }

</script>

<style lang="scss">
.mainpage {
    &__section {
        padding-top: $token-spacers-xl;
        font-size: 16px;

        &:last-child {
            .mainpage__section-wrapper {
                border-bottom: none;
            }
        }
    }

    &__section-wrapper {
        border-bottom: 1px solid $token-colors-black-5;

        .row {
            margin-top: -$token-spacers-s;

            > [class*=col-] {
                margin-top: $token-spacers-s
            }
        }
    }

    &__section-bg {
        background-image: url('/images/mainpage/background-2.svg');
        background-repeat: no-repeat;
        background-position: 92.5% 100%;

        .mainpage__section-wrapper {
            padding-bottom: 112px;

            @include breakpoint (v-tablet) {
                padding-bottom: 140px;
            }
        }

        @include breakpoint (tablet) {
            background-position: 100.5% 100%;
        }
    }

    &__section--key-figures {
        background:
            linear-gradient(180deg, rgba(255, 255, 255, 0) 0%,#FFFFFF 100%),
            linear-gradient(90deg, #FFFFFF 0.79%, rgba(255, 255, 255, 0.4) 51.62%),
            linear-gradient(0deg, #FFFFFF 0%, #E7F1FD 100%);
    }

    &__hero {
        background-size: contain;
        background-image: url('/images/mainpage/background.svg');
        background-repeat: no-repeat;
        background-position: 99% 100%;
        background-size: 54% auto;
        padding-top: 0;

        .mainpage__section-wrapper {
            padding-bottom: 95px;

            @include breakpoint (v-tablet) {
                padding-bottom: 190px;
            }
        }

        @include breakpoint (tablet) {
            background-position: 122% 100%;
            background-size: 68% auto;
        }

        @include breakpoint (v-tablet) {
            background-position: -220% 100%;
            background-size: 108% auto;
        }

        @include breakpoint (mobile) {
            background-position: 20% 100%;
            background-size: 135% auto;
        }

        .z-button {
            width: auto;
        }
    }

    &__hero-title {
        @include margin-level (bottom, XS);
    }

    &__hero-buttons {
        display: flex;

        .z-button:first-child {
            margin-right: 12px;
        }

        .z-button {
            margin-bottom: $token-spacers-2-xs;
        }

        @include breakpoint (mobile) {
            flex-direction: column;

            .z-button:first-child {
                margin-right: 0;
            }
        }
    }

    &__hero-description {
        max-width: 400px;

        @include breakpoint (tablet) {
            max-width: 100%;
        }
    }

    &__hero-file {
        margin-top: 50px;
        @include margin-level (bottom, L);
    }

    .reports-list {
        &__item {
            display: flex;
            @include margin-level (bottom, XS);

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__icon {
            width: 40px;
            height: 40px;
            margin-right: $token-spacers-2-xs;
        }

        &__link {
            color: $token-colors-link-default;
            transition: color $transition;
            font-size: 16px;
            margin-bottom: 4px;

            &:hover {
                color: $token-colors-link-hover;
            }

            &:active {
                color: $token-colors-link-active;
            }
        }

        &__filesize {
            color: $token-colors-black-40;
            font-size: 12px;
        }

        &__info {
            display: flex;
            flex-direction: column;
        }
    }
}
</style>
