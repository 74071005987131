<template>
    <div class="latest-news">
        <div class="latest-news__list">
            <div class="latest-news__item" v-for="(item, index) in data" :key="index">
                <div class="latest-news__date">
                    {{ formatDate(item.activeFrom) }}
                </div>
                <z-link theme="boldline" :href="item.detailPageUrl">
                    <span v-html="item.name"></span>
                </z-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useGetter } from '@/composable/useGetter'
import { momentFilter } from '@/utils/filter'

const { app } = useGetter(['app'])

const data = app.value.components.main.news

const formatDate = (date) => {
    return momentFilter(date, 'LL')
}
</script>

<style lang="scss">
.latest-news {
    &__item {
        & + & {
            @include margin-level(top, L);
        }
    }

    &__date {
        color: $token-colors-black-60;
        font-size: 13px;
        @include margin-level(bottom, 2XS);
    }
}
</style>