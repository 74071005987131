<template>
    <div
        :class="[
            'z-textarea',
            {
                'is-focused': isFocused,
                'is-errored': isValid === false,
                'is-valid': isValid,
                'is-filled': textareaValue,
                'is-disabled': disabled,
                'is-resizable': resize,
                'is-required': required
            }
        ]">
        <label class="z-textarea__label">
            <textarea
                class="z-textarea__textarea"
                name="name"
                v-bind="$attrs"
                @blur="onBlur"
                @focus="onFocus"
                @input="onInput($event)"
                @change="onChange($event)"
                v-model="textareaValue"
                :disabled="disabled"
                cols="30"
                rows="4"
            ></textarea>
            <span
                v-show="placeholder"
                v-html="placeholder"
                class="z-textarea__placeholder"
            ></span>
        </label>
        <span
            :class="[
                'z-textarea__error',
                errorClass
            ]"
            v-html="error"
            v-if="error && !isValid"
        ></span>
    </div>
</template>

<script>
export default {
    name: 'z-textarea',
    props: {
        placeholder: String,
        modelValue: [Number, String],
        name: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        resize: {
            type: Boolean,
            default: false
        },
        errorClass: {
            type: String,
            default: ''
        },
        validateOnBlur: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            localValue: null,
            isFocused: false,
            isValid: null,
            error: '',
            text: {
                error: {
                    ru: 'Поле обязательно для заполнения',
                    en: 'Required field'
                }
            }
        }
    },
    computed: {
        textareaValue: {
            get () {
                if (this.modelValue !== undefined) return this.modelValue // для работы без v-model
                return this.localValue
            },
            set (value) {
                this.$emit('input', value)
                this.$emit('update:modelValue', value)
                this.localValue = value
            }
        }
    },
    methods: {
        onBlur () {
            this.isFocused = false
            if (this.validateOnBlur) this.validate()
        },
        onFocus () {
            this.isFocused = true
        },
        onInput (e) {
            this.$emit('input', e.target.value)
            this.$emit('update:modelValue', e.target.value)
            if (this.isValid !== null) this.$nextTick(this.validate)
        },
        onChange (e) {
            this.$emit('change', e.target.value)
            this.$emit('update:modelValue', e.target.value)
        },
        validate () {
            if (this.required && !this.textareaValue) {
                this.isValid = false
                this.error = this.$root.localize(this.text.error)
                return
            }

            this.textareaValue ? this.isValid = true : this.isValid = null
            this.error = ''
        }
    }
}

</script>

<style lang="scss">
.z-textarea {
    $parent: &;
    font-size: var(--textSize);
    width: 100%;
    font-weight: 400;

    &__placeholder {
        padding: var(--placeholderPadding);
        display: block;
        max-width: 100%;
        position: absolute;
        top: 0.75em;
        line-height: 1;
        left: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--formPlaceholderColorDefault);
        background-color: transparent;
        pointer-events: none;
        overflow: hidden;
        transform: scale(1);
        transition: all .3s ease-in-out;
        font-size: 1em;
        line-height: 1.5;
        margin-top: 0;
    }

    &__label {
        position: relative;
        display: block;
    }

    &__error {
        font-size: var(--errorTextSize);
        color: var(--errorTextColor);
        position: relative;
        left: 20px;
    }

    &__textarea {
        resize: none;
        border-width: var(--formBorderWidth);
        border-style: solid;
        border-color: var(--formBorderColorDefault);
        border-radius: var(--borderRadiusCheckbox);
        width: 100%;
        color: var(--formTextColorDefault);
        box-sizing: border-box;
        padding: var(--fieldPaddingM);
        transition: border-color 0.2s ease-in;
    }

    &.is-resizable {
        #{$parent}__textarea {
            resize: vertical;
        }
    }

    // errored state
    &.is-errored {
        // #{$parent}__placeholder {
        //     color: var(--formPlaceholderColorError);
        // }

        #{$parent}__textarea {
            border-color: var(--formBorderColorError);
        }
    }

    // hover state
    &:hover {
        #{$parent}__textarea {
            border-color: var(--formBorderColorHover);
        }
    }

    // filled state
    &.is-filled {
        #{$parent}__placeholder {
            width: auto;
            transform: translateY(-16px);
            font-size: var(--filledPlaceholderTextSize);
            line-height: 1.2;
            // color: var(--formPlaceholderColorFilled);
            background-color: #fff;
        }

        #{$parent}__textarea {
            border-color: var(--formBorderColorFilled);
        }
    }

    // valid state
    &.is-valid {
        // #{$parent}__placeholder {
        //     color: var(--formPlaceholderColorValid);
        // }

        #{$parent}__textarea {
            border-color: var(--formBorderColorValid);
        }
    }

    // focused state
    &.is-focused {
        #{$parent}__placeholder {
            width: auto;
            transform: translateY(-16px);
            font-size: var(--filledPlaceholderTextSize);
            line-height: 1.2;
            // color: var(--formPlaceholderColorFocus);
            background-color: #fff;
        }

        #{$parent}__textarea {
            border-color: var(--formBorderColorFocus);
        }
    }

    &.is-required {
        .z-textarea__placeholder {
            &:after {
                content: '*';
                color: var(--errorTextColor);
                margin-left: 4px;
            }
        }

        .z-textarea__error {
            &:before {
                content: '*';
                color: var(--errorTextColor);
                margin-right: 4px;
            }
        }
    }
}

</style>
