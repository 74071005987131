<template>
    <div  class="site-selector">
        <z-link
            class="site-selector__link"
            v-html="otherLang.lang"
            :title="otherLang.title"
            :href="currentLink"
        ></z-link>
    </div>
</template>

<script setup>
import { useGetter } from '@/composable/useGetter'
import { computed } from 'vue'

const props = defineProps({
    isIndex: {
        type: Boolean
    },
    domain: {
        type: Boolean,
        default: false
    }
})

const { app } = useGetter(['app'])

const data = app.value.components['site-selector']

const otherLang = computed(() => {
    return data.find((el) => !el.active)
})

const currentLink = computed(() => {
    return props.domain ? `${otherLang.value.host} + ${otherLang.value.url}` : otherLang.value.url
})

</script>

<style lang="scss" src="./index.scss"></style>
