<template>
    <div class="subscribe-form">
        <template v-if="!isSended">
            <z-caption class="u-bottom-margin--s-important" tag="h2" size="3xl">
                <span v-html="formTitle"></span>
            </z-caption>
            <vue-raw
                class="text-size-l u-bottom-margin--s"
                v-if="formDescription"
                :raw="formDescription"
            />
            <form class="subscribe-form__form" @submit.prevent>
                <div>
                    <z-input
                        type="email"
                        :placeholder="text.inputPlaceholder"
                        v-model="params.email"
                        ref="refEmail"
                        required
                        :disabled="state === 'edit'"
                    />
                </div>
                <div
                    class="subscribe-form__errors"
                    v-if="errors && errors[0]"
                >
                    <div class="subscribe-form__errors-item">
                        <vue-raw
                            tag="span"
                            :raw="errorsMap[errors[0]] || text.defaultError"
                        />
                        <template v-if="errors[0] === 'email-14'">
                            <z-link theme="dark" tag="button" @click="onReconfirm">
                                <span v-html="text.reconfirmLink"></span>
                            </z-link>
                        </template>
                    </div>
                </div>
                <div class="u-top-margin--m" v-if="['subscribe', 'edit'].includes(state)">
                    <z-checkbox-group
                        :data="rubricsAdaptive"
                        v-model="params.rubrics"
                        :required="params.state !== 'unsubscribe'"
                        :select-all-text="text.allRubrics"
                        ref="refRubrics"
                    />
                </div>
                <div class="u-top-margin--l">
                    <z-checkbox
                        :data="{
                            text: policyText,
                            id: 'agree',
                            selected: false
                        }"
                        required
                        :customErrorText="text.agreeErrorText"
                        v-model="params.policy"
                        size="s"
                        ref="refPolicy"
                    />
                </div>
                <div class="u-top-margin--xs">
                    <z-checkbox
                        :data="{
                            text: text.agreeText,
                            id: 'agree',
                            selected: false
                        }"
                        required
                        :customErrorText="text.agreeErrorText"
                        v-model="params.agree"
                        size="s"
                        ref="refAgree"
                    />
                </div>
                <div class="subscribe-form__actions">
                    <z-button
                        :loading="isLoading"
                        type="button"
                        @click.prevent="onClickSubmitButton"
                    >
                        <span v-html="submitBtnText"></span>
                    </z-button>
                    <z-link
                        tag="button"
                        type="button"
                        size="m"
                        :underlined="false"
                        theme="dark"
                        @click.prevent="onUnsubscribe"
                        v-if="['requestEdit', 'edit', 'requestUnsubscribe'].includes(state)"
                    >
                        <span v-html="text.unsubscribe"></span>
                    </z-link>
                </div>
                <vue-raw
                    class="text-size-xs u-top-margin--m"
                    v-if="['subscribe'].includes(state)"
                    :raw="followTelegram"
                />

                <z-link
                    class="subscribe-form__link u-top-margin--xl"
                    :href="`/${lang}/press-center/press-releases/`"
                    icon="arrows/caret"
                    icon-position="right"
                    size="l"
                    dir="right"
                >
                    {{ text.backLink }}
                </z-link>
            </form>
        </template>

        <template v-else>
            <vue-raw class="text-size-2xl u-bold u-line-height-1_2" :raw="successMessage" />
            <vue-raw class="u-top-margin--s text-size-l" v-if="successDescription" :raw="successDescription" />
            <z-button
                class="u-top-margin--xl"
                :href="`/${lang}/press-center/press-releases/`"
                size="l"
                tag="a"
            >
                {{ text.backLink }}
            </z-button>
        </template>
    </div>
</template>

<script setup>
import { ref, nextTick, onBeforeMount, computed } from 'vue'
import { text } from '../locales'
import { sendSubscription } from '@/api/subscribe'
import { useComputedData } from '../composable/useComputedData'
import { getGetParams } from '@/utils/filterUtils'

const props = defineProps({
    rubrics: {
        type: Array,
        default: () => []
    },
    subscriber: {
        type: Object
    },
    state: {
        type: String,
        default: 'subscribe',
        validator: prop => [
            'subscribe',
            'edit',
            'requestEdit',
            'requestUnsubscribe'
        ].includes(prop)
    },
    policyLink: {
        type: String
    },
    lang: {
        type: String
    }
})

const params = ref({
    state: 'subscribe',
    email: '',
    rubrics: [],
    policy: false,
    agree: false,
})

const errors = ref([])
const isSended = ref(false)
const isLoading = ref(false)

const refEmail = ref('refEmail')
const refRubrics = ref('refRubrics')
const refAgree = ref('refAgree')
const refPolicy = ref('refPolicy')

const rubricsAdaptive = computed(() => {
    return props.rubrics?.map(item => {
        return {
            ...item,
            group: {
                id: item.lang,
                name: text.langMap[item.lang],
                'all-text': text.allMap[item.lang]
            }
        }
    })
})

const {
    successMessage,
    successDescription,
    policyText,
    submitBtnText,
    formTitle,
    formDescription,
    errorsMap,
    followTelegram
} = useComputedData(params, props)

const onSubmit = async () => {
    const isValid = await validateForm()
    if (isValid) {
        isLoading.value = true
        try {
            const response = await sendSubscription(params.value)
            if (response.status === 'success') {
                isSended.value = true
            } else {
                errors.value = response.errors
            }
        } catch {
            console.error('error send')
        } finally {
            isLoading.value = false
        }
    }
}

const validateForm = async () => {
    let isValid = true
    const requiredFields = [refEmail, refRubrics, refAgree, refPolicy]

    for (let index = 0; index < requiredFields.length; index++) {
        const item = requiredFields[index]
        if (typeof item.value === 'string') continue
        item.value?.validate()
        await nextTick(() => {
            if (item.value?.error !== '') isValid = false
        })
    }
    return isValid
}

const onUnsubscribe = () => {
    params.value.state = props.state === 'requestEdit' ? 'requestUnsubscribe' : 'unsubscribe'
    onSubmit()
}

const onClickSubmitButton = () => {
    params.value.state = props.state
    onSubmit()
}

const onReconfirm = () => {
    params.value.state = 'reconfirm'
    onSubmit()
}

onBeforeMount(() => {
    params.value.state = props.state

    if (props.state === 'edit') {
        params.value.email = props.subscriber?.email
        params.value.id = props.subscriber?.id
        params.value['confirm-code'] = props.subscriber?.['confirm-code']
    }

    if (props.state === 'requestEdit') {
        const { email } = getGetParams()
        params.value.email = email
    }
})
</script>

<style lang="scss">
.subscribe-form {
    --placeholderBg: transparent;

    &__errors {
        color: $token-colors-form-border-color-error;
        font-size: var(--errorTextSize);

        .z-link {
            color: $token-colors-form-border-color-error;
        }
    }

    &__errors-item {
        & + & {
            @include margin-level(top, 3XS);
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: $token-spacers-s;
        @include margin-level(top, S);
    }

    &__link {
        text-decoration: none;
        display: inline-block;
    }
}
</style>
