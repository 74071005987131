<template>
    <component :is="tag" :href="href" :class="cardClasses" :target="external ? '_blank' : '_self'">
        <div class="news-card__image-wrapper">
            <img
                class="news-card__image"
                v-if="image"
                :src="image"
                alt="card image."
            />
        </div>
        <div :class="['news-card__tags', {'news-card__tags--relative' : !image}]" v-if="tags">
            <z-tag
                v-for="(tag, index) in tags"
                :key="`tag-${index}`"
                :theme="tag.theme ? tag.theme : 'primary'"
            >
                {{ tag.text ? tag.text : tag }}
            </z-tag>
        </div>
        <div class="news-card__content">
            <div class="news-card__title-wrapper">
                <span class="news-card__title" v-if="title" v-html="title"/>
                <span class="news-card__description" v-if="previewText" v-html="previewText"/>
            </div>
            <div
                class="news-card__source"
                v-if="source"
                v-html="source"
            ></div>
            <div
                class="news-card__date"
                v-if="date"
                v-html="momentFilter(date, dateFilter)"
            ></div>
        </div>
    </component>
</template>

<script setup>
import {computed} from 'vue'
import {momentFilter} from '@/utils/filter'

const props = defineProps({
    tag: {
        type: String,
        default: 'div'
    },
    href: {
        type: String,
    },
    image: {
        type: String,
    },
    tags: {
        type: Array
    },
    title: {
        type: String,
    },
    previewText: {
        type: String,
    },
    source: {
        type: String,
    },
    date: {
        type: [String, Number]
    },
    size: {
        type: String,
        validator: prop => [
            'big',
            'small'
        ].includes(prop),
        default: 'small'
    },
    external: {
        type: Boolean,
        default: false
    },
    dateFilter: {
        type: String,
        default: 'MMMM DD, YYYY'
    }
})

const cardClasses = computed(() => {
    const classes = [
        'news-card',
        `news-card--size-${props.size}`,
    ]
    if (props.href) {
        classes.push('news-card--link')
    }
    return classes
})
</script>

<style lang="scss" src="./index.scss"></style>
