<template>
    <div class="committee-composition">
        <z-table>
            <template #head>
                <z-table-col index="0" is-th></z-table-col>
                <z-table-col
                    is-th
                    v-for="(item, index) in committees"
                    :key="index"
                    v-html="item"
                >
                </z-table-col>
            </template>
            <z-table-row v-for="(item, index) in table" :key="index">
                <z-table-col>
                    <component
                        :is="item.detailPageUrl ? 'z-link' : 'span'"
                        :href="item.detailPageUrl"
                        :theme="item.detailPageUrl ? 'boldline' : null"
                        :class="[
                            'committee-composition__name',
                            {'committee-composition__name--chairperson': item.director && item.director.value === 'Y' }
                        ]">
                        <span v-html="item.name"></span>
                    </component>
                </z-table-col>
                <z-table-col
                    class="committee-composition__role"
                    :data-role="text.legend[committee.role]"
                    v-for="(committee, id) in item.committees"
                    :key="id"
                >
                </z-table-col>
            </z-table-row>
            <template #add>
                <div
                    class="committee-composition__legend legend"
                    :class="`u-justify-content-${legendAlign}`"
                >
                    <div class="legend__item legend__item--chairperson">– {{ text.legend.chairperson }}</div>
                    <div class="legend__item"><span>{{ text.legend.chairman }}</span> – {{ text.legend.chairmanFull }}</div>
                    <div class="legend__item"><span> {{ text.legend.member }}</span> – {{ text.legend.memberFull }}</div>
                </div>
            </template>
        </z-table>
    </div>
</template>

<script setup>
import { useGetter } from '@/composable/useGetter'
import { localize } from '@/utils/i18n'
import { computed } from 'vue'

const { app } = useGetter(['app'])

defineProps({
    legendAlign: {
        type: String,
        default: 'justify',
        validator: prop => ['justify', 'left', 'right', 'center'].includes(prop)
    }
})

const text = {
    legend: {
        chairman: localize({
            ru: 'П',
            en: 'CC'
        }),
        member: localize({
            ru: 'У',
            en: 'CM'
        }),
        chairmanFull: localize({
            ru: 'Председатель комитета',
            en: 'Committee Chair'
        }),
        memberFull: localize({
            ru: 'Участник комитета',
            en: 'Committee Member'
        }),
        chairperson: localize({
            ru: 'Председатель Совета директоров',
            en: 'Chairperson of the Board'
        })
    }
}

const committees = computed(() => {
    return app.value.components.committees.items.map(item => item.name) || []
})

const table = computed(() => {
    return app.value.components.committees.table || []
})

</script>

<style lang="scss">
.committee-composition {
    &__name {
        display: inline-block !important;
        font-size: 18px;

        &--chairperson {
            padding-right: calc(24px + 8px);

            &:before {
                position: absolute;
                right: 0;
                top: -2px;
            }
        }
    }

    &__role {
        &:before {
            text-transform: uppercase;
            color: $token-colors-link-default;
            font-size: 18px;
            font-weight: 500;
            content: attr(data-role);
        }
    }
    
    .legend {
        display: flex;
        padding-left: $token-spacers-s;
        padding-right: $token-spacers-s;

        @include breakpoint (tablet) {
            padding-left: $token-spacers-xs;
            padding-right: $token-spacers-xs;
        }
        
        @include breakpoint (v-tablet) {
            padding-left: 20px;
            padding-right: 20px;
            flex-direction: column;
        }

        @include breakpoint (mobile) {
            padding-left: $token-spacers-2-xs;
            padding-right: $token-spacers-2-xs;
        }

        &__item {
            margin-right: $token-spacers-s;
            display: flex;
            color: $token-colors-black-50;

            @include breakpoint (v-tablet) {
                margin-right: 0;
                margin-bottom: $token-spacers-2-xs;
            }

            &:last-child {
                margin-right: 0;
            }

            &--chairperson {
                &:before {
                    order: 0;
                    margin-right: 4px;
                }
            }

            span {
                font-weight: 500;
                color: $token-colors-link-default;
                margin-right: 4px;
                display: inline-block;
            }
        }
    }

    .legend__item--chairperson,
    .committee-composition__name--chairperson {
        &:before {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.4135 18.3812L17.1419 21.3769C17.7463 21.7598 18.4967 21.1903 18.3173 20.4847L16.9512 15.1108C16.9127 14.9611 16.9173 14.8036 16.9643 14.6564C17.0114 14.5092 17.0991 14.3783 17.2172 14.2787L21.4573 10.7496C22.0144 10.2859 21.7269 9.36126 21.0111 9.31481L15.4738 8.95544C15.3247 8.94479 15.1816 8.89198 15.0613 8.80317C14.941 8.71437 14.8484 8.59321 14.7943 8.45382L12.7292 3.25323C12.673 3.10528 12.5732 2.9779 12.443 2.88802C12.3127 2.79814 12.1582 2.75 12 2.75C11.8418 2.75 11.6873 2.79814 11.557 2.88802C11.4268 2.9779 11.327 3.10528 11.2708 3.25323L9.20568 8.45382C9.15157 8.59321 9.05897 8.71437 8.93868 8.80317C8.81838 8.89198 8.67533 8.94479 8.52618 8.95544L2.98894 9.31481C2.27315 9.36126 1.9856 10.2859 2.54272 10.7496L6.78278 14.2787C6.90095 14.3783 6.9886 14.5092 7.03566 14.6564C7.08272 14.8036 7.08727 14.9611 7.0488 15.1108L5.78188 20.0945C5.56667 20.9412 6.46715 21.6246 7.19243 21.1651L11.5865 18.3812C11.71 18.3025 11.8535 18.2607 12 18.2607C12.1465 18.2607 12.29 18.3025 12.4135 18.3812Z' fill='%230158CE'/%3E%3C/svg%3E%0A");
            width: 24px;
            height: 24px;
            display: block;
            flex-shrink: 0;
            background-repeat: no-repeat;
        }
    }

    .z-table {
        table {
            margin-bottom: 0;
        }

        thead {
            box-shadow:0 1px 0 0 $token-colors-black-20;
        }

        &__footer {
            padding-top: $token-spacers-s;
            padding-bottom: $token-spacers-m;
        }
    }

    .z-table__container {
        box-shadow: none;
    }

    .z-table--scrollable .z-table__container {
        padding-bottom: 0.1em;
    }

    .z-table-row--body:hover {
        .z-table-col {
            box-shadow: none;
        }
    }

    .z-table-row:last-child {
        border-bottom: 1px solid $token-colors-black-20;
    }

    .z-table-col {
        border-bottom: none;
        width: 20%;

        &:not(:first-child) {
            text-align: center;
        }
    }
}
</style>