import {computed} from 'vue'
import {momentFilter} from '@/utils/filter'

export const useFormattedDate = (from, to) => {
    const formattedTo = computed(() => {
        if (to) {
            return {
                day: momentFilter(to, 'D'),
                month: momentFilter(to, 'D MMMM').split(' ')[1], //хак для склонения
                year: momentFilter(to, 'YYYY')
            }
        }
        return null
    })

    const formattedFrom = computed(() => {
        const fromObj = {
            day: momentFilter(from, 'D'),
            month: momentFilter(from, 'D MMMM').split(' ')[1], //хак для склонения
            year: momentFilter(from, 'YYYY')
        }
        if (formattedTo.value === null) {
            return fromObj
        }
        if (momentFilter(from, 'DD MMMM YYYY') === momentFilter(to, 'DD MMMM YYYY')) {
            return null
        }
        return  {
            day: fromObj.day,
            month: (fromObj.month !== formattedTo.value.month || fromObj.year !== formattedTo.value.year) ? fromObj.month : null,
            year: (fromObj.month !== formattedTo.value.month || fromObj.year !== formattedTo.value.year)  ? fromObj.year : null
        }
    })

    return {
        from: formattedFrom,
        to: formattedTo
    }
}
