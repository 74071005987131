import { computed } from 'vue'
import { localize } from '@/utils/i18n'

export const useComputedData = (data, props, device) => {
    const tabs = computed(() => {
        if (!data.items?.length) return []

        let tabs = data.items || []
        if (data.items.length > props.archiveCount) {
            tabs = tabs.slice(0, props.archiveCount)
            tabs.push({
                name: localize({
                    ru: 'Архив',
                    en: 'Archive'
                }),
                items: data.items.slice(props.archiveCount)
            })
        }

        return tabs
    })

    const isMobileDevice = computed(() => {
        return ['v-tablet', 'mobile'].includes(device.value)
    })

    return { tabs, isMobileDevice }
}