<template>
    <div class="not-found">
        <div class="not-found__content">
            <z-caption
                class="u-bottom-margin--xs-important"
                size="4xl"
                weight="500"
                v-html="text.title" />
            <p class="not-found__text"><vue-raw :raw="text.description" /></p>
        </div>
    </div>
</template>

<script setup>
import { localize } from '@/utils/i18n'
const text = {
    title: localize({
        ru: 'Страница не&nbsp;найдена',
        en: 'Not found'
    }),
    description: localize({
        ru: 'Попробуйте начать с&nbsp;<z-link href="/ru/">главной&nbsp;страницы</z-link><br> или воспользуйтесь <z-link href="/ru/search/">поиском</z-link>',
        en: 'Try to&nbsp;start from the&nbsp;<z-link href="/en/">main&nbsp;page</z-link><br> or&nbsp;use the <z-link href="/en/search/">search</z-link>'
    })
}
</script>

<style lang="scss">
.not-found {
    width: 100%;
    height: 100%;
    background: url('/images/not-found/flashlight.png') no-repeat 18vw 100%,
        linear-gradient(0deg, #ffffff 0%, #b7d4fb 100%) no-repeat center -30vh;
    mix-blend-mode: multiply;
    padding-left: $token-spacers-3-xl;
    padding-top: 32vh;
    min-height: 520px;

    &__text {
        font-weight: 400;
        font-size: 20px;
        line-height: 1.4;
        color: $token-colors-secondary;
    }

    &__content {
        @include breakpoint(mobile) {
            max-width: 300px;
        }
    }

    @include breakpoint(tablet) {
        background-position: 18vw 100%, center -20vh;
    }

    @include breakpoint(v-tablet) {
        padding: 33vh $token-spacers-s 0 $token-spacers-xs;
        background-position: 18vw 100%, center -10vh;
    }

    @include breakpoint(mobile) {
        padding: 47vh $token-spacers-xl 0 $token-spacers-xs;
        background-position: 0 100%, center -30vh;
    }

    @media (max-width: 420px) {
        background-position: -30vw 100%, center -30vh;
    }
}
</style>
