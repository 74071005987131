const validateEmail = email => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
}
const validateTel = tel => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^[0-9\(\)\/ +_@.-]*$/
    return regex.test(tel)
}
const validateUrl = url => {
    // eslint-disable-next-line no-useless-escape
    const regex = /(^https?:\/\/)?[а-яa-z0-9~_\-\.]+\.[а-яa-z]{2,9}(\/|:|\?[!-~]*)?$/i
    return regex.test(url)
}

const validateTextOnly = str => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^[a-zA-Zа-яА-Я\(\)\/ +_@.-]*$/
    return regex.test(str)
}

export {
    validateEmail,
    validateTel,
    validateUrl,
    validateTextOnly
}