import {localize} from '@/utils/i18n'

export const useSearchLocalizedText = () => {
    const NOT_FOUND = {
        title: localize({
            ru: 'Ничего не&nbsp;найдено',
            en: 'Results not found'
        }),
        text: localize({
            ru: 'По&nbsp;вашему запросу ничего не&nbsp;найдено, попробуйте изменить формулировку или выполнить поиск по&nbsp;ключевым словам или вернитесь на&nbsp;<a href="/ru/">главную страницу</a>.',
            en: 'Make sure your chosen words are spelled correctly. Try different keywords or&nbsp;more general keywords and try again or&nbsp;return to&nbsp;<a href="/en/">homepage</a>.'
        })
    }

    const PLACEHOLDER = {
        input: localize({
            ru: 'Введите запрос',
            en: 'Enter keywords'
        })
    }
    return {NOT_FOUND, PLACEHOLDER}
}
