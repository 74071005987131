<template>
    <div class="sec-filings-detail" v-if="data">
        <div class="sec-filings-detail__head">
            <div class="sec-filings-detail__date-wrapper">
                <span class="sec-filings-detail__key">Date:</span>
                <span class="sec-filings-detail__value">{{momentFilter(data.item.activeFrom, 'D MMMM YYYY')}}</span>
            </div>
            <sec-filings-files
                :ixbrl="data.item.ixbrl"
                :pdf="data.item.pdf"
                :html="data.item.html"
                :docx="data.item.docx"
                :xlsx="data.item.xlsx"
                :zip="data.item.zip"
                :code="data.item.code"/>
        </div>
        <div class="sec-filings-detail__body">
            <div class="row">
                <div class="col-default-10 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                    <div v-html="data.item.detailText" />
                </div>
            </div>
        </div>
        <div class="sec-filings-detail__footer">
            <z-page-nav :data="data.nav" button-text="Back to list"/>
        </div>
    </div>
</template>

<script setup>
import {useGetter} from '@/composable/useGetter'
import SecFilingsFiles from '@/views/SecFilings/components/SecFilingsFiles.vue'
import {momentFilter} from '@/utils/filter'

const {app} = useGetter(['app'])
const data = app.value.components['sec-filings-detail']
</script>

<style lang="scss">
.sec-filings-detail {
    &__head {
        display: flex;

        @include margin-level(bottom, M);

        @include breakpoint(v-tablet) {
            flex-wrap: wrap;
            gap: 24px;
        }
    }

    &__body {
        @include margin-level(bottom, M);
    }

    &__key {
        font-weight: 500;
        font-size: 14px;
        line-height: 1.2;

        @include margin-level(right, 2XS);
    }

    &__value {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-black-50;
    }

    &__date-wrapper {
        @include margin-level(right, XL);
    }
}
</style>
