<template>
    <div class="subscribe-page" v-if="data">
        <template v-if="['subscribe', 'edit', 'requestEdit', 'requestUnsubscribe', 'reconfirm'].includes(data.state)">
            <subscribe-form
                :rubrics="data.rubrics"
                :subscriber="data.subscriber"
                :state="data.state"
                :policy-link="policyLink"
                :lang="lang"
            />
        </template>
        <template v-if="data.state === 'unsubscribe'">
            <vue-raw class="text-size-2xl u-bold u-line-height-1_2" :raw="text.unsubscribeTitle" />
            <vue-raw class="u-top-margin--s text-size-l" :raw="text.unsubscribeDescription" />
        </template>
        <template v-if="data.state === 'confirm'">
            <vue-raw class="text-size-2xl u-bold u-line-height-1_2" :raw="text.confirmTitle" />
            <div class="u-top-margin--s text-size-l">
                <vue-raw tag="span" :raw="text.confirmDescription" />
                <z-link
                    :href="`./?id=${data.subscriber?.id}&confirm-code=${data.subscriber['confirm-code']}&email=${data.subscriber?.email}&state=edit`"
                    :underlined="false"
                >
                    <span v-html="text.manageSubscriptionLink"></span>
                </z-link>
            </div>
            <vue-raw
                class="text-size-l u-top-margin--m"
                :raw="followTelegram"
            />
        </template>
        <template v-if="data.state === 'error'">
            <span class="text-size-2xl u-bold u-line-height-1_2">
                <z-icon class="subscribe-page__icon" name="general/warning" />
                <vue-raw tag="span" :raw="text.defaultError" />
            </span>
        </template>
        <template v-if="['confirm', 'unsubscribe'].includes(data.state)">
            <z-button
                class="u-top-margin--xl"
                :href="`/${lang}/press-center/press-releases/`"
                size="l"
                tag="a"
            >
                {{ text.backLink }}
            </z-button>
        </template>
    </div>
</template>

<script setup>
import SubscribeForm from './components/SubscribeForm.vue'
import { useGetter } from '@/composable/useGetter'
import { useComputedData } from './composable/useComputedData'
import { text } from './locales'
import { ref } from 'vue'

defineProps({
    policyLink: {
        type: String
    }
})

const { app } = useGetter(['app'])
const data = ref(app.value.components?.subscription)
const lang = ref(app.value.page?.lang)

const {
    followTelegram
} = useComputedData()
</script>

<style lang="scss">
.subscribe-page {
    @include padding-level(top, XL);
    @include padding-level(bottom, XL);
    @include padding-level(left, XL);
    @include padding-level(right, XL);
    border-radius: 10px;
    background: $token-colors-bg-5;
    margin-left: auto;
    margin-right: auto;
    max-width: 896px;
    width: 100%;

    &__icon {
        margin-top: -0.2em;
        margin-right: $token-spacers-3-xs;
        width: 1.2em;
        height: 1.2em;
    }
}
</style>
