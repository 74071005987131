<template>
    <div class="files" v-if="fileList.length || accordionList.length">
        <template v-if="fileList.length">
            <z-filelist class="u-bottom-margin--m">
                <z-filelist-item
                    v-for="(item, index) in fileList"
                    :key="index"
                    :info="`${item.file.size}, ${item.file.type}`"
                    icon-size="small"
                    :icon="`${item.file.type}`"
                    :file-size="item.file.size"
                    size="l"
                    :itemprop="item.itemprop.value"
                    :date="formatDate(item.activeFrom)"
                >
                    <z-link :href="item.file.link" target="_blank" :itemprop="item.itemprop.value">
                        <span v-html="item.name"></span>
                    </z-link>
                </z-filelist-item>
            </z-filelist>
        </template>
        <template v-if="accordionList.length">
            <z-accordion multiple theme="white" initial="2022">
                <z-accordion-item
                    v-for="accordion in accordionList"
                    :key="accordion['xml-id']"
                    :id="accordion['xml-id']"
                >
                    <template v-slot:header>
                        <span v-html="accordion.name"></span>
                    </template>
                    <template v-slot:body>
                        <z-filelist>
                            <div class="row">
                                <z-filelist-item
                                    :class="{'col-default-6 col-v-tablet-12': columns}"
                                    v-for="(item, index) in accordion.items"
                                    :key="index"
                                    :icon="`${item.file.type}`"
                                    :file-size="item.file.size"
                                    size="l"
                                    icon-size="small"
                                    :itemprop="item.itemprop.value"
                                    :date="formatDate(item.activeFrom)"
                                >
                                    <z-link :href="item.file.link" target="_blank" :itemprop="item.itemprop.value">
                                        <span v-html="item.name"></span>
                                    </z-link>
                                </z-filelist-item>
                            </div>
                        </z-filelist>
                    </template>
                </z-accordion-item>
            </z-accordion>
        </template>
    </div>
</template>
    
<script>
import { momentFilter } from '@/utils/filter'

export default {
    name: 'files',
    props: {
        source: {
            type: String,
            default: 'files'
        },
        size: {
            type: String,
            validator: prop => ['l', 'xl'].includes(prop),
            default: 'xl'
        },
        showDate: Boolean,
        columns: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            data: this.$root.app.components[this.source]
        }
    },
    computed: {
        accordionList () {
            if (!this.data) return []

            const result = this.data.reduce(function (r, a) {
                if (!a.group['xml-id']) return r

                r[a.group['xml-id']] = r[a.group['xml-id']] || {}
                r[a.group['xml-id']].name = r[a.group['xml-id']].name || a.group.value
                r[a.group['xml-id']]['xml-id'] = r[a.group['xml-id']]['xml-id'] || a.group['xml-id']
                r[a.group['xml-id']].items = r[a.group['xml-id']].items || []
                r[a.group['xml-id']].items.push(a)
                return r
            }, Object.create(null))
        
            return Object.values(result)
        },
        fileList () {
            if (!this.data) return []

            return this.data.filter(item => !item.group['xml-id'])
        }
    },
    methods: {
        formatDate (date) {
            if (!date || !this.showDate) return '' 
            return typeof (date) === 'number' ? `${momentFilter(date, 'DD MMMM YYYY')}` : date
        }
    }
}
</script>
