<script>
import {compile, h} from 'vue'
export default {
    name: 'vue-raw',
    props: {
        raw: HTMLElement | String,
        tag: {
            type: String,
            default: 'div'
        }

    },
    render () {
        const tag = document.createElement(this.tag)
        tag.innerHTML = this.raw
        return h(compile(tag.outerHTML.replace(/\s{2,}/g, '')))
    }
}
</script>
