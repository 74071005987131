
import {useStore} from 'vuex'
import {computed} from 'vue'
export const useGetter = (scopes) => {
    const store = useStore()
    const keypair = scopes.map((key) => [key, computed(() => store.getters[key])])
    return Object.fromEntries(keypair)
}

// Для получения данных со стора. Образец использования: const { device } = useGetter(['device'])
