const qs = require('query-string')

function queryString (params) {
    let string = ''
    delete params.page
    let keys = Object.keys(params)
    let hash = window.location.hash
    let url = window.location.pathname

    if (keys.length > 0) {
        url += '?'
        string = qs.stringify(params, { arrayFormat: 'bracket' })
    }

    url += `${string}${hash}`

    if (history.pushState) {
        try {
            history.pushState({}, '', url)
            return
        } catch (err) {
            console.error('Error in queryString!')
        }
    } else {
        document.location.href = url
    }
}

function clearEmptyParams (data) {
    let params = Object.assign({}, data)

    Object.keys(params).forEach(function (key) {
        if (Array.isArray(params[key])) {
            if (!(params[key].filter(val => { return val !== null && val !== undefined && val !== '' && val !== false }).length)) delete params[key]
        } else if (!params[key]) {
            delete params[key]
        }
    })

    return params
}

function sanitizeValue (text) {
    return text
        // htmlspecialchars
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')
        // addslashes
        .replace(/\\/g, '\\\\')
        // eslint-disable-next-line
        .replace(/\u0008/g, '\\b')
        .replace(/\t/g, '\\t')
        .replace(/\n/g, '\\n')
        .replace(/\f/g, '\\f')
        .replace(/\r/g, '\\r')
        .replace(/'/g, '\\\'')
        .replace(/"/g, '\\"')
}

function getGetParams (url = window.location) {
    let params = {}

    new URL(url).searchParams.forEach((val, key) => {
        params[key] = sanitizeValue(val)
    })

    return params
}

export {
    queryString,
    clearEmptyParams,
    sanitizeValue,
    getGetParams
}