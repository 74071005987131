<template>
    <div class="navigation-mobile-footer" v-if="bottom && bottom.length">
        <div v-if="staticLinks && staticLinks.length || $slots.default" class="navigation-mobile-footer__static-container">
            <slot/>
            <div v-for="(item, index) in staticLinks" :key="`static-links-${index}`">
                <a class="navigation-mobile-footer__static-link" :href="item.link">
                    <z-icon :name="externalIcon"></z-icon>
                    <span>{{ item.title }}</span>
                </a>
            </div>
        </div>
        <div v-for="(item, index) in bottom" :key="`main-links-${index}`">
            <a class="navigation-mobile-footer__main-link"
               :class="{'navigation-mobile-footer__main-link--active': item.active}"
               v-html="item.name"
               :href="item.link"
            />
        </div>
    </div>
</template>

<script>
import {mockedData} from '../mockedData'

export default {
    name: 'navigation-mobile-footer',
    props: {
        staticLinks: {
            type: Array,
            default: null
        },
        externalIcon: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            bottom: this.$root.app.components.navigation.footer ? this.$root.app.components.navigation.footer : mockedData.bottom
        }
    }
}
</script>
<style lang="scss">
@import "../vars";

.navigation-mobile-footer {
    width: 100%;
    background-color: $token-colors-black-5;
    padding: $token-spacers-xs $token-spacers-2-xs;

    &__static-container {
        margin-bottom: 20px;
    }

    &__static-link {
        @include typo-level(2XS);
        font-weight: 400;
        line-height: 1.3;
        text-decoration: none;
        color: $nav-link-color;
        display: inline-flex;
        align-items: flex-start;
        padding-top: $token-spacers-3-xs;
        padding-bottom: $token-spacers-3-xs;

        &:active {
            color: $nav-link-color-active;

            .z-icon {
                path {
                    fill: $nav-link-color-active;
                }
            }
        }

        .z-icon {
            margin-right: 6px;
            margin-top: 0.1em;
        }
    }

    &__main-link {
        font-weight: 600;
        @include typo-level(XS);
        line-height: 1.3;
        color: $nav-link-color;
        padding-top: 12px;
        padding-bottom: 12px;
        display: block;

        &--active {
            color: $nav-link-color-active;
        }
    }
}
</style>
