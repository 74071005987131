<template>
    <div class="z-remind">
        <div class="z-remind__trigger"
             :class="{'z-remind__trigger--opened': isOpened}"
             ref="remind">
            <slot name="trigger"/>
        </div>
        <div class="z-remind__content" ref="content">
            <div class="z-remind__title">
                Добавить в календарь
            </div>
            <slot name="content"/>
            <z-link
                v-for="(item, index) in data"
                :underlined="false"
                theme="dark"
                :href="item.link"
                :key="index"
                :target="item.type === 'google' ? '_blank' : false"
                :download="item.type !== 'google' ? item.type : ''"
            >
                <z-icon
                    :name="`calendar/${item.type}`"
                    width="28"
                    height="28"
                />
                {{ item.text }}
            </z-link>
        </div>
    </div>
</template>

<script>
import tippy from 'tippy.js'

export default {
    name: 'z-remind',
    props: {
        data: [Array, Object],
        placement: {
            type: String,
            default: 'right-start'
        }
    },
    data () {
        return {
            isOpened: false
        }
    },
    mounted () {
        this.initTippy()
    },
    methods: {
        initTippy () {
            const self = this
            tippy(this.$refs.remind, {
                content: this.$refs.content,
                arrow: false,
                placement: this.placement,
                theme: 'remind',
                interactive: true,
                trigger: 'click focus',
                onShow () {
                    self.isOpened = true
                },
                onHide () {
                    self.isOpened = false
                }
            })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
