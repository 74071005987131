<template>
    <div class="reg-news">
        <z-tabs :initial-id="initialTab">
            <template #label>
                <template
                    v-for="(tab, index) in tabs"
                    :key="`tab-${index}`">
                    <z-tab-label :id="tab.id">{{ tab.text }}</z-tab-label>
                </template>
            </template>
            <template #content>
                <template
                    v-for="(tab, index) in tabs"
                    :key="`tab-content-${index}`">
                    <z-tab-content :id="tab.id">
                        <div class="sec-filings__content">
                            <z-table
                                v-if="filteredItems.length"
                                :colgroup="['20%', '70%', '10%']">
                                <template #head>
                                    <z-table-col
                                        index="0"
                                        is-th>
                                        Дата публикации
                                    </z-table-col>
                                    <z-table-col
                                        index="1"
                                        is-th>
                                        Событие
                                    </z-table-col>
                                    <z-table-col
                                        index="2"
                                        is-th>
                                        Файл
                                    </z-table-col>
                                </template>
                                <template
                                    v-for="(item, index) in filteredItems(tab.id)"
                                    :key="`sec-item-${index}`">
                                    <z-table-row>
                                        <z-table-col index="0">
                                            <z-caption size="s">
                                                {{ momentFilter(item.date, 'D MMMM YYYY') }}
                                            </z-caption>
                                        </z-table-col>
                                        <z-table-col index="1">
                                            <z-link
                                                class="u-medium"
                                                :href="item.link"
                                                theme="dark"
                                                :underlined="false">
                                                {{ item.name }}
                                            </z-link>
                                        </z-table-col>
                                        <z-table-col index="2">
                                            <z-link
                                                v-if="item.pdf"
                                                :underlined="false"
                                                target="_blank"
                                                :href="item.pdf">
                                                <z-icon name="tools/press-release" />
                                                pdf
                                            </z-link>
                                        </z-table-col>
                                    </z-table-row>
                                </template>
                            </z-table>
                        </div>
                    </z-tab-content>
                </template>
            </template>
        </z-tabs>
    </div>
</template>

<script setup>
import { useGetter } from '@/composable/useGetter'
import { useRegNewsComputedData } from './composable/useRegNewsComputedData'
import { momentFilter } from '@/utils/filter'

const { app } = useGetter(['app'])

const data = app.value.components['essential-facts']
const { tabs, filteredItems, initialTab } = useRegNewsComputedData(data)
</script>

<style lang="scss">
.reg-news {
    & .z-table-col {
        text-align: left;
    }
}
</style>
