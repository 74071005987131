<template>
    <div
        class="reg-news-detail"
        v-if="data">
        <div class="reg-news-detail__head">
            <z-link
                v-if="data.pdf"
                :underlined="false"
                target="_blank"
                :href="data.pdf">
                <z-icon name="tools/press-release" />
                pdf
            </z-link>
        </div>
        <div
            v-if="data.detail"
            class="reg-news-detail__body">
            <div class="row u-collapse--top">
                <div
                    class="col-default-10 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0 u-collapse--top">
                    <div v-html="data.detail" />
                </div>
            </div>
        </div>
        <div
            v-if="data['back-link']"
            class="reg-news-detail__footer">
            <z-button
                kind="bordered"
                tag="a"
                :href="data['back-link']">
                <span>Назад к&nbsp;списку</span>
            </z-button>
        </div>
    </div>
</template>

<script setup>
import { useGetter } from '@/composable/useGetter'

const { app } = useGetter(['app'])
const data = app.value.components['essential-facts-detail']
</script>

<style lang="scss">
.reg-news-detail {
    &__body {
        @include margin-level(bottom, M);
    }

    &__footer {
        display: flex;
        justify-content: center;
    }
}
</style>
