<template>
    <div :class="[
        'main-menu',
        {'main-menu--mainpage': isMainPage}
    ]">
        <nav>
            <ul class="main-menu__list">
                <li
                    :class="[
                        'main-menu__list-item',
                        {'is-opened': indexA === navIndexOpened},
                        {'is-active': checkActiveState(itemA)}
                    ]"
                    v-for="(itemA, indexA) in data"
                    :key="indexA"
                >
                    <div
                        class="main-menu__link"
                        v-html="itemA.name"
                        @click.prevent="onClick(indexA, itemA)"
                    >
                    </div>
                </li>
            </ul>
        </nav>
        <transition
            @before-enter="appearAnimationBefore"
            @enter="animationEnter"
            @leave="animationLeave"
        >
            <nav
                class="main-menu__dropdown"
                v-if="navIndexOpened !== -1"
            >
                <div class="main-menu__dropdown-container">
                    <template
                        v-for="(itemA, indexA) in data"
                        :key="indexA"
                    >
                        <div class="main-menu__list-wrapper" v-if="navIndexOpened === indexA">
                            <ul class="main-menu__list main-menu__list--lvl-2">
                                <li
                                    :class="[
                                        'main-menu__list-item',
                                        {'is-active': itemB.active}
                                    ]"
                                    v-for="(itemB, indexB) in itemA.items"
                                    :key="indexB"
                                >
                                    <a
                                        class="main-menu__link"
                                        :href="itemB.link"
                                        v-html="itemB.name"
                                    >
                                    </a>
                                </li>
                            </ul>
                            <button class="main-menu__button" @click="hideChilds" v-if="isMainPage">
                                <z-icon name="tools/close-small" width="24" height="24"></z-icon>
                            </button>
                        </div>
                    </template>
                </div>
            </nav>
        </transition>
    </div>
</template>

<script setup>
import { useGetter } from '@/composable/useGetter'
import { defineProps, onMounted } from 'vue'
import { useMenuAnimation } from './composable/useMenuAnimation'
import { useMenuShowState } from './composable/useMenuShowState'

const { app } = useGetter(['app'])
const data = app.value.components.navigation.top

const { appearAnimationBefore, animationEnter, animationLeave } = useMenuAnimation()
const { showChilds, checkActiveState, navIndexOpened, hideChilds, show } = useMenuShowState(data)

const props = defineProps({
    isMainPage: {
        type: Boolean,
        default: false
    }
})

// methods
const onClick = (i, item) => {
    showChilds(i, item, props)
}
//mounted
onMounted(() => {
    show()
})

defineExpose({
    hideChilds,
    show
})
</script>

<style lang="scss" src="./index.scss"></style>
