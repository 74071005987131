<template>
    <div class="z-page-nav" :class="{ 'z-page-nav--with-back-link': data['back-link'] }">
        <div class="z-page-nav__link-wrapper z-page-nav__link-wrapper--left">
            <z-link
                size="s"
                :href="data.prev ? data.prev.url : 'javascript:void(0)'"
                :class="{ 'is-disabled': !(data.prev && data.prev.url) }"
                :icon="arrow"
                icon-position="left"
                inline-flex
                :underlined="false"
                dir="left"
            >
                <span class="hide-me--mobile" v-html="prevText"/>
            </z-link>
        </div>
        <div class="z-page-nav__button-wrapper">
            <z-button
                v-if="data['back-link']"
                kind="bordered"
                tag="a"
                :href="data['back-link']"
            >
                <span v-html="buttonText"></span>
            </z-button>
        </div>
        <div class="z-page-nav__link-wrapper">
            <z-link
                size="s"
                :href="data.next ? data.next.url : 'javascript:void(0)'"
                :class="{ 'is-disabled': !(data.next && data.next.url) }"
                :icon="arrow"
                icon-position="right"
                :underlined="false"
                dir="right"
            >
                <span class="hide-me--mobile" v-html="nextText"/>
            </z-link>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'z-page-nav',
    props: {
        data: {
            required: true,
            type: Object
        },
        buttonText: {
            type: String,
            default: localize({
                ru: 'К списку',
                en: 'Back to list'
            })
        }
    },
    data () {
        return {
            arrow: 'arrows/caret',
            defaultText: {
                prev: localize({
                    ru: 'Предыдущий',
                    en: 'Preview'
                }),
                next: localize({
                    ru: 'Следующий',
                    en: 'Next'
                })
            }
        }
    },
    computed: {
        nextText () {
            return this.data.next?.name || this.defaultText.next
        },
        prevText () {
            return this.data.prev?.name || this.defaultText.prev
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
