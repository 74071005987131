<template>
    <div class="z-highlighted-block" :class="classObject">
        <slot></slot>
    </div>
</template>

<script setup>
import {computed, defineProps} from 'vue'

// props
const props = defineProps({
    bg: {
        type: Boolean,
        default: false
    },
    bold: {
        type: Boolean,
        default: true
    },
    lineSide: {
        type: String,
        default: 'left',
        validator: (prop) => ['left', 'top'].includes(prop)
    },
    line: {
        type: Boolean,
        default: true
    },
    lead: {
        type: Boolean,
        default: false
    },
})

// computed
const classObject = computed(() => {
    if (props.lead) { // в зависимости от проекта выбираем что относится к lead. в данном случае lead убирает bold, добавляет line
        return ({
            'z-highlighted-block--bg': props.bg,
            'z-highlighted-block--lead': props.lead,
            [`z-highlighted-block--with-line z-highlighted-block--with-line-${props.lineSide}`]: props.line && props.lineSide
        })
    }
    return ({
        'z-highlighted-block--bg': props.bg,
        'z-highlighted-block--bold': props.bold,
    })
})
</script>

<style lang="scss" src="./index.scss"></style>
