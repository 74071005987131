<template>
    <div class="z-copyright">
        <span
            v-if="textBefore"
            class="z-copyright__text"
        >
            {{ textBefore }}
        </span>
        <span>&copy;</span>
        <span class="z-copyright__years">
            <span
                v-if="year"
                class="z-copyright__year-start"
            >
                {{ this.year }}
            </span>
            {{hyphen}}
            <span
                v-if="yearCurrent"
                class="z-copyright__year-current"
            >
                {{ currentYear }}
            </span>
        </span>
        <span
            v-if="textAfter"
            class="z-copyright__text"
        >
            {{ textAfter }}
        </span>
    </div>
</template>

<script setup>
import {computed} from 'vue'
const props = defineProps({
    textAfter: {
        type: String
    },
    textBefore: {
        type: String
    },
    yearCurrent: {
        type: Boolean
    },
    year: {
        type: [String, Number]
    }
})

const currentYear = computed(() => {
    return new Date().getFullYear()
})

const hyphen = computed(() => {
    return props.year && props.yearCurrent ? '—' : ''
})
</script>

<style lang="scss" src="./index.scss"></style>
