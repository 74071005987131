<template>
    <div>
        <z-select
            is-filter
            :data="[
                {
                    'id': 1,
                    'selected': false,
                    'disabled': false,
                    'text': 'Развитие форматов',
                    'group': 'Группа 1'

                },
                {
                    'id': 2,
                    'selected': false,
                    'disabled': false,
                    'text': 'Конференции',
                    'group': 'Группа 1'

                },
                {
                    'id': 3,
                    'selected': false,
                    'disabled': false,
                    'text': '2Развитие форматов',
                    'group': 'Группа 1'

                },
                {
                    'id': 4,
                    'selected': false,
                    'disabled': false,
                    'text': '2Конференции',
                    'group': 'Группа 1'

                },
            ]"
            v-model="select"
            placeholder="Выберите"
        ></z-select>
    </div>
</template>

<script>
export default {
    name: 'test',
    data () {
        return {
            select: '',
            date: ''
        }
    },
    methods: {
        onChange (data) {
            console.log(data)
        }
    }
}
</script>

<style lang="scss">
    
</style>