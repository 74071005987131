<template>
    <div class="sec-filings">
        <div class="sec-filings__filters">
            <z-tabs :initial-id="initialId" is-filter @change-tab="filterHandler">
                <template #label>
                    <template v-for="(tab, index) in tabs" :key="`tab-${index}`">
                        <z-tab-label :id="tab.id">{{tab.text}}</z-tab-label>
                    </template>
                </template>
            </z-tabs>
        </div>
        <div class="sec-filings__content">
            <z-preloader v-if="isLoading"/>
            <z-table v-if="items.length && !isLoading" :colgroup="['5%', '65%', '20%', '10%']">
                <template #head>
                    <z-table-col index="0" is-th>Filling Type</z-table-col>
                    <z-table-col index="1" is-th>Description</z-table-col>
                    <z-table-col index="2" is-th>Date</z-table-col>
                    <z-table-col index="3" is-th>View</z-table-col>
                </template>
                <template v-for="(item, index) in items" :key="`sec-item-${index}`">
                    <z-table-row>
                        <z-table-col index="0">
                            <z-caption size="s">{{item.type.value}}</z-caption>
                        </z-table-col>
                        <z-table-col index="1">
                            <z-link class="u-medium" :href="item.detailPageUrl" theme="dark" :underlined="false">{{item.name}}</z-link>
                        </z-table-col>
                        <z-table-col index="2">
                            <z-caption size="s">{{momentFilter(item.activeFrom, 'MMMM D, YYYY')}}</z-caption>
                        </z-table-col>
                        <z-table-col index="3">
                            <sec-filings-files
                                :pdf="item.pdf"
                                :code="item.code"/>
                        </z-table-col>
                    </z-table-row>
                </template>
            </z-table>
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import {useGetter} from '@/composable/useGetter'
import {useGetData} from '@/composable/useGetData'
import {useSecFilingsComputedData} from '@/views/SecFilings/composable/useSecFilingsComputedData'
import {getSecFilings} from '@/api/secFilings'
import { momentFilter } from '@/utils/filter'
import SecFilingsFiles from '@/views/SecFilings/components/SecFilingsFiles.vue'
import {queryString, clearEmptyParams} from '@/utils/filterUtils'

const { app } = useGetter(['app'])
const appData = app.value.components['sec-filings']

const params = ref({
    year: ''
})

const {fetcher, data, isLoading} = useGetData(getSecFilings, params, appData)
const {items, initialId, tabs} = useSecFilingsComputedData(data)

const filterHandler = (payload) => {
    params.value.year = payload.split('-')[1]
    queryString(clearEmptyParams({
        year: params.value.year
    }))
    if (params.value.year !== initialId.value) {
        fetcher()
    }
}
</script>

<style lang="scss">
.sec-filings {
    .z-table-col {
        text-align: left;
    }
    &__filters {
        @include margin-level(bottom, L);
    }
    &__content {
        table {
            margin-bottom: 0;
        }
    }
}
</style>
