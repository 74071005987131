<template>
    <z-icon
        :name="name"
        :class="[
            'z-icon-block',
            `z-icon-block--size-${size}`
        ]"
    />
</template>

<script>
export default {
    name: 'zIconBlock',
    props: {
        size: {
            type: String,
            default: 'l',
            validator: prop => ['2xl', 'xl', 'l', 'm'].includes(prop)
        },
        name: String
    }
}
</script>

<style lang="scss">
.z-icon-block {
    &--size-2xl {
        width: 140px;
        height: 140px;

        @include breakpoint (tablet) {
            width: 100px;
            height: 100px;
        }

        @include breakpoint (v-tablet) {
            width: 80px;
            height: 80px;
        }
    }

    &--size-xl {
        width: 100px;
        height: 100px;

        @include breakpoint (tablet) {
            width: 80px;
            height: 80px;
        }

        @include breakpoint (v-tablet) {
            width: 48px;
            height: 80px;
        }
    }

    &--size-l {
        width: 80px;
        height: 80px;

        @include breakpoint (tablet) {
            width: 48px;
            height: 48px;
        }
    }

    &--size-m {
        width: 48px;
        height: 48px;
    }
}
</style>
