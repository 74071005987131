<template>
    <div class="sec-filings-files">
        <a v-if="pdf && pdf.link" class="sec-filings-files__item" target="_blank" :href="pdf.link">
            <z-icon :name="getIcon(pdf)"/>
            {{pdf.type.toLowerCase()}}
        </a>
        <a v-if="html && html.link" class="sec-filings-files__item" target="_blank" :href="html.link">
            <z-icon :name="getIcon(html)"/>
            {{html.type.toLowerCase()}}
        </a>
        <a v-if="docx && docx.link" class="sec-filings-files__item" target="_blank" :href="docx.link">
            <z-icon :name="getIcon(docx)"/>
            {{docx.type.toLowerCase()}}
        </a>
        <a v-if="xlsx && xlsx.link" class="sec-filings-files__item" target="_blank" :href="xlsx.link">
            <z-icon :name="getIcon(xlsx)"/>
            {{xlsx.type.toLowerCase()}}
        </a>
        <a v-if="zip && zip.link" class="sec-filings-files__item" target="_blank" :href="zip.link">
            <z-icon :name="getIcon(zip)"/>
            {{zip.type.toLowerCase()}}
        </a>
        <a v-if="ixbrl && ixbrl.value" :href="ixbrl.value" class="sec-filings-files__item" target="_blank">
            <z-icon name="tools/link"/>
            ixbrl
        </a>
    </div>
</template>

<script setup>
defineProps({
    ixbrl: {
        type: Object
    },
    pdf: {
        type: Object
    },
    html: {
        type: Object
    },
    docx: {
        type: Object
    },
    xlsx: {
        type: Object
    },
    zip: {
        type: Object
    },
    code: {
        type: String,
        required: true
    }
})

const getIcon = (file) => file.type.toLowerCase() === 'rar' ? 'tools/archive_box' : 'tools/press-release'
</script>

<style lang="scss">
.sec-filings-files {
    display: flex;
    gap: 24px;

    &__item {
        text-decoration: none;
        flex-shrink: 0;
    }
}
</style>
