import {computed} from 'vue'

export const useSecFilingsComputedData = (data) => {
    const tabs = computed(() => data.value?.filters?.tabs || [])
    const initialId = computed(() => {
        if (tabs.value.length) {
            const index = tabs.value.findIndex((item) => item.selected)
            const result = index < 0 ? tabs.value[0].id : tabs.value[index].id
            return result.toString()
        }
        return ''
    })

    const items = computed(() => data.value?.items || [])

    return {tabs, initialId, items}
}
