<template>
    <div v-if="data" class="bio-detail">
        <div class="bio-detail__info">
            <div class="bio-detail__avatar-wrapper">
                <div class="bio-detail__avatar" :style="`background-image: url('${getAvatar()}')`"/>
            </div>
            <div>
                <z-caption
                    class="u-bottom-margin--3xs-important"
                    tag="h2"
                    size="3xl"
                >
                    {{data.name}}
                </z-caption>
                <p class="bio-detail__position" v-if="data.position.value" v-html="data.position.value"></p>
                <div
                    class="bio-detail__text"
                    v-if="data.detailText"
                    v-html="data.detailText"
                ></div>
            </div>
        </div>
        <z-page-nav :data="nav" :button-text="text.button"/>
    </div>
</template>

<script setup>
import { localize } from '@/utils/i18n'
import { useGetter } from '@/composable/useGetter'

const props = defineProps({
    source: {
        type: String,
        default: 'bio-detail'
    }
})

const { app } = useGetter(['app'])
const data = app.value.components[props.source]?.item || null
const nav = app.value.components[props.source]?.nav

const text = {
    button: localize({
        ru: 'Вернуться к&nbsp;списку',
        en: 'Go to list'
    })
}

const getAvatar = () => {
    if (data.detailPicture?.link) {
        return data.detailPicture.link
    }

    return data.gender.xmlId === 'male' ? '/images/male.jpg' : '/images/male.jpg'
}

</script>

<style lang="scss">
.bio-detail {
    &__info {
        display: flex;
        margin-bottom: 240px;

        @include breakpoint(v-tablet) {
            flex-direction: column;
            margin-bottom: $token-spacers-l;
        }
    }

    &__avatar-wrapper {
        flex-shrink: 0;
        @include margin-level(right, L);

        @include breakpoint(v-tablet) {
            max-width: 200px;
            margin-right: 0;
            margin-bottom: $token-spacers-xs;
        }

        @include breakpoint (mobile) {
            max-width: 100%;
        }
    }

    &__avatar {
        padding-top: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50%;
        width: 290px;
        height: 290px;

        @include breakpoint(v-tablet) {
            width: auto;
            height: auto;
        }
    }

    &__position {
        display: block;
        font-weight: 400;
        @include typo-level(S);
        line-height: 1.4;
        color: $token-colors-link-default;

        @include margin-level(bottom, XS);
    }

    &__text {
        @include typo-level(S);
        line-height: 1.6;
        color: $token-colors-black-60;
    }
}
</style>
