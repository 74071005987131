<template>
    <div>
        <button class="coockie-trigger" @click="toggleOpenedState">
            <z-icon name="cookies" />
        </button>
        <transition
            @before-enter="appearAnimationBefore"
            @enter="appearAnimationEnter"
            @leave="appearAnimationLeave"
        >
            <div
                class="cookie-popup"
                v-if="visible"
            >
                <button class="cookie-popup__close-button">
                    <z-icon name="tools/close" width="24" height="24" icon-color="#0D3061" @click="hideCookiePopup"/>
                </button>
                <div class="cookie-popup__body">
                    <div class="container">
                        <z-caption icon="cookies" class="u-bottom-margin--xs-important cookie-popup__title" tag="h2" size="3xl" weight="500">
                            <span v-html="locales.text.title"/>
                        </z-caption>
                        <vue-raw class="cookie-popup__text" :raw="locales.text.description"/>
                        <template v-if="visibleSettings">
                            <div class="cookie-popup__settings">
                                <div v-for="item in settings" class="cookie-popup__card-setting" :key="item.id">
                                    <div class="cookie-popup__card-header">
                                        <z-caption class="cookie-popup__card-title" size="m" @click="showInfo(item.id)">
                                            {{item.title}}
                                        </z-caption>
                                        <z-switch :id="item.id" :check="item.checked" :disabled="item.locked" @change="switchHandler"/>
                                    </div>
                                    <p v-if="!currentDevice || settingInfo[item.id]" class="cookie-popup__card-description" v-html="item.description"/>
                                </div>
                            </div>
                        </template>
                        <div class="cookie-popup__buttons">
                            <div class="cookie-popup__button">
                                <z-button @click="submit">
                                    {{ locales.text.button.agree }}
                                </z-button>
                            </div>
                            <div class="cookie-popup__button" v-if="includeSettings">
                                <z-button kind="bordered" type="button" @click="showSettings">
                                    <span v-if="visibleSettings" v-html="locales.text.button.hide"/>
                                    <span v-else v-html="locales.text.button.more"/>
                                </z-button>
                            </div>
                            <div class="cookie-popup__button">
                                <z-button kind="bordered" type="button" @click="rejectAll">
                                    <span v-html="locales.text.button.reject"/>
                                </z-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue'
import locales from './locales'
import cookie from 'cookie_js'
import {useGetter} from '@/composable/useGetter'
import {useCookieAnimation} from './composable/useCookieAnimation'
import {localize} from '@/utils/i18n'

defineProps({
    includeSettings: {
        type: Boolean,
        default: true
    }
})

const DURATION = 200
const settingInfo = ref({
    mandatory: false,
    performance: false,
    advertising: false,
    functional: false,
    marketing: false
})
const visible = ref(false)
const visibleSettings = ref(false)

const {device} = useGetter(['device'])

const settings = ref([
    {
        title: localize({
            ru: 'Обязательные',
            en: 'Strictly Necessary Cookies'
        }),
        id: 'mandatory',
        locked: true,
        checked: true,
        description: localize({
            ru: 'Эти файлы необходимы для правильной работы сайта и&nbsp;функционала.',
            en: 'These files are necessary for the website to&nbsp;function properly.'
        })
    },
    {
        title: localize({
            ru: 'Функциональные',
            en: 'Functionality Cookies'
        }),
        id: 'functional',
        locked: false,
        checked: false,
        description: localize({
            ru: 'Эти файлы позволяют повысить удобство использования сайта, сохраняя информацию о&nbsp;настройках пользователя.',
            en: 'These files improve the usability of&nbsp;the site by&nbsp;storing information about the user&rsquo;s settings.'
        })
    },
    {
        title: localize({
            ru: 'Производительные/аналитические',
            en: 'Performance / Analytics Cookies'
        }),
        id: 'performance',
        locked: false,
        checked: false,
        description: localize({
            ru: 'Эти файлы собирают информацию о&nbsp;том, как вы&nbsp;используете сайт, и&nbsp;помогают нам улучшать его в&nbsp;соответствии с&nbsp;вашими потребностями.',
            en: 'These cookies collect information about how you use the site and help&nbsp;us improve it&nbsp;to&nbsp;suit your needs.'
        })
    },
    {
        title: localize({
            ru: 'Реклама и таргетинг',
            en: 'Advertising and Targeting Cookies'
        }),
        id: 'advertising',
        locked: false,
        checked: false,
        description: localize({
            ru: 'Эти файлы собирают информацию о&nbsp;том, как вы&nbsp;используете сайт, и&nbsp;помогают нам улучшать его в&nbsp;соответствии с&nbsp;вашими потребностями.',
            en: 'These cookies collect information about how you use the site and help&nbsp;us improve it&nbsp;to&nbsp;suit your needs.'
        })
    }
])

onMounted(() => {
    visible.value = !cookie.get('cookies_policy')

    settings.value = settings.value?.map(item => {
        if (item.id === 'mandatory') return item
        !cookie.get(item.id)
        return { ...item, checked: cookie.get(item.id) === 'true' }
    })
})

const {appearAnimationBefore, appearAnimationEnter, appearAnimationLeave} = useCookieAnimation(DURATION)

const currentDevice = computed(() => device.value === 'mobile')
const showInfo = (item) => {
    if (currentDevice) {
        settingInfo.value[item] = !settingInfo.value[item]
    } else {
        return ''
    }
}

const switchHandler = (data) => {
    switch (data.id) {
    case ('performance'): {
        const item = settings.value?.find(item => item.id === 'performance')
        item.checked = data.value
        break
    }
    case ('functional'): {
        const item = settings.value?.find(item => item.id === 'functional')
        item.checked = data.value
        break
    }
    case ('advertising'): {
        const item = settings.value?.find(item => item.id === 'advertising')
        item.checked = data.value
        break
    }
    default: {
        break
    }
    }
}
const hideCookiePopup = () => {
    visible.value = false
}
const showSettings = () => {
    visibleSettings.value = !visibleSettings.value
}

const rejectAll = () => {
    settings.value = settings.value?.map(item => {
        if (item.id === 'mandatory') return item
        return { ...item, checked: false }
    })

    submit()
}

const toggleOpenedState = () => {
    visible.value = !visible.value
    if (!visible.value) visibleSettings.value = !visibleSettings.value
}

const submit = () => {
    cookie.set({
        cookies_policy: true,
        performance: settings.value?.find(item => item.id === 'performance')?.checked,
        functional: settings.value?.find(item => item.id === 'functional')?.checked,
        advertising: settings.value?.find(item => item.id === 'advertising')?.checked,
    }, {
        domain: '.' + location.host,
        path: '/',
        expires: 365
    })
    hideCookiePopup()
}
</script>

<style lang="scss" src="./index.scss"></style>
